export const SET_APP_PATH_ACTION = 'SET_APP_PATH_ACTION'
export const SET_WINDOW_WIDTH = 'SET_WINDOW_WIDTH'

/**
 * Action for setting the application path.
 */
export function setAppPath (path) {
  return {
    type: SET_APP_PATH_ACTION,
    path
  }
}

/**
 * Action for setting the window width
 */
export function setWindowWidth (windowWidth) {
  return {
    type: SET_WINDOW_WIDTH,
    windowWidth
  }
}
