import { busy, notBusy } from '../busy/actions.js'
import { handleGetErrors } from '../error/actions.js'

import api from '../api.js'

export const PERMISSION_GET_OK_ACTION = 'PERMISSION_GET_OK_ACTION'

/**
 * READ
 */
export function getPermissions () {
  return function (dispatch) {
    dispatch(busy())
    api.getPermissions().then(result => {
      dispatch(notBusy())
      dispatch(receivedPermissions(result.data.value))
    }).catch(handleGetErrors('permission', dispatch))
  }
}

function receivedPermissions (items) {
  return {
    type: PERMISSION_GET_OK_ACTION,
    items
  }
}
