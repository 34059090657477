import {
  SET_APP_PATH_ACTION,
  SET_WINDOW_WIDTH
} from './actions.js'

const initialState = {
  path: '',
  windowWidth: 1920
}

export function window (state = initialState, action) { // eslint-disable-line
  switch (action.type) {
    case SET_APP_PATH_ACTION:
      return { ...state, path: action.path }
    case SET_WINDOW_WIDTH:
      return { ...state, windowWidth: action.windowWidth }
    default:
      return state
  }
}
