import { createSelector } from '@reduxjs/toolkit'

export const makeSelectorById = () => createSelector(
  (state) => state.employee,
  (_, id) => id,
  (slice, id) => {
    return slice.items?.find(({ id: _id }) => _id === Number(id))
  }
)

export const makeSelectorEmployee = () => createSelector(
  (state) => state.employee,
  (slice) => {
    return slice.items || []
  }
)
