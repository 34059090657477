import { createSelector } from '@reduxjs/toolkit'
import storage from './storage.js'

/**
 * Default data for a page.
 */
export function pageDefault (orderBy, page, defaults = {}) {
  const settings = storage.getPageSettings(page)
  return {
    // Set to true to open dialog for adding an item
    addItem: false,
    // Set to an item to be edited
    editItem: null,
    // Set to an item to be deleted
    deleteItem: null,
    // All items, used for listing in e.g. drop down (legacy)
    items: [],
    // Selected item id for displaying selected row in table
    selectedItemId: null,
    // normalized Items
    normalizedItems: {},
    // Page items, used for pagination on item pages
    pageItems: [],
    // Number of items per page
    limit: 100,
    // Offset in database query for pagination
    offset: 0,
    // Which column data to sort/order by ('' for no specific sort order)
    orderBy,
    // Ascending ('asc'), descending ('desc') or no ('') sort order
    order: 'asc',
    // Search term, currently used as frontend filter on page items
    search: '',
    // Array of filter queries, currently used in database query
    filterQueries: settings.filterQueries || defaults.filterQueries || [],
    // Search term, currently used as frontend filter on page items
    isSearching: false
  }
}

export const makeSelectorFilterQueries = () => createSelector(
  [(state) => state, (_, slice) => slice],
  (state, slice) => {
    return state[slice]?.filterQueries || []
  }
)
