import React, { useCallback, useEffect, useState } from 'react'
import { styled, Input, Waves } from '@pergas-common/pergas-components'
import { useSelector } from 'react-redux'
import { selectLocale } from '../redux/locale/selectors'
import useDebounce from '../hooks/useDebounce'
import { Search } from '@mui/icons-material'
import { Close } from '@pergas-common/pergas-icons'

const Holder = styled.div`
  max-width: 286px;
  width: 100%;
  margin-right: auto;
`

const StyledSearchIcon = styled(Search)`
  transform: translateY(-25%) !important;
`

const AnimationHolder = styled.div`
  position: absolute;
  left: 4px;
  transform: translateY(-5px);
`

const ClearInput = styled.div`
  cursor: pointer;
  position: absolute;
  right: 4px;
  > svg {
    transform: translateY(-5px);
  }
`

const SearchInput = ({ isSearching, onChange, resetSearch, value = '' }) => {
  const locale = useSelector(selectLocale)
  const [input, setInput] = useState(value)

  const onSearch = useCallback((inputValue) => {
    onChange(inputValue)
  }, [onChange])
  const [debouncedSearch] = useDebounce(onSearch, 400)

  const quickKeysEvent = useCallback((event) => {
    if (event.code === 'Escape' && input !== '') {
      setInput('')
      onChange('')
    }
  }, [input, onChange, setInput])

  useEffect(() => {
    document.addEventListener('keydown', quickKeysEvent)
    return () => {
      document.removeEventListener('keydown', quickKeysEvent)
    }
  }, [quickKeysEvent])

  useEffect(() => {
    setInput(value)
  }, [value])

  return (
    <Holder>
      <Input
        name='search' value={input} onChangeValue={(input) => {
          debouncedSearch(input.currentTarget.value)
          setInput(input.currentTarget.value)
        }} placeholder={`${locale.search}...`}
      >
        {isSearching ? <AnimationHolder><Waves width={24} height={24} /></AnimationHolder> : <StyledSearchIcon />}
        <ClearInput onClick={() => {
          resetSearch()
          setInput('')
        }}
        >
          <Close width={18} height={18} />
        </ClearInput>
      </Input>
    </Holder>
  )
}

export default SearchInput
