/**
 * Common file type extensions.
 */
export const EXCEL_FILES = ['xls', 'xlsx']
export const EXCEL_TEMPLATE_FILES = ['xlt', 'xltm', 'xltx']
export const POWERPOINT_FILES = ['ppt', 'pptx']
export const POWERPOINT_TEMPLATE_FILES = ['pot', 'potm', 'potx']
export const WORD_FILES = ['doc', 'docx']
export const WORD_TEMPLATE_FILES = ['dot', 'dotm', 'dotx']
export const TEMPLATE_FILES = EXCEL_TEMPLATE_FILES
  .concat(WORD_TEMPLATE_FILES)
  .concat(POWERPOINT_TEMPLATE_FILES)

/**
 * Return file extension from file name
 */
export function getFileExtension (name) {
  const split = name.split('.')
  return (split.length > 0 ? split[split.length - 1] : '').toLowerCase()
}

/**
 * Return office uri protocol based based on file extension.
 */
function getOfficeUriProtocol (ext) {
  if (WORD_FILES.includes(ext) ||
      WORD_TEMPLATE_FILES.includes(ext)) {
    return 'ms-word'
  } else if (POWERPOINT_FILES.includes(ext) ||
             POWERPOINT_TEMPLATE_FILES.includes(ext)) {
    return 'ms-powerpoint'
  } else if (EXCEL_FILES.includes(ext) ||
             EXCEL_TEMPLATE_FILES.includes(ext)) {
    return 'ms-excel'
  } else {
    return ''
  }
}

/**
 * Return true if extension is a template
 */
function isTemplateFile (ext) {
  return TEMPLATE_FILES.includes(ext)
}

/**
 * Open a document uri based on file type using the office uri scheme.
 * uri: full url to document or template
 */
export function openDocument (uri) {
  const ext = getFileExtension(uri)
  const protocol = getOfficeUriProtocol(ext)
  if (protocol) {
    const command = isTemplateFile(ext) ? 'nft' : 'ofe'
    const url = `${protocol}:${command}|u|${uri}`
    console.info('opening document', url)
    window.open(url, '_top')
  } else {
    console.info('could not find a suitable application for', uri)
  }
}

/**
 * Returns true if there is a matching office protocol for the
 * file type on the url.
 */
export function canOpenDocument (uri) {
  if (typeof uri === 'string') {
    return !!getOfficeUriProtocol(getFileExtension(uri))
  } else {
    return false
  }
}

/**
 * Sorts `items` based on `prop` with case ignored.
 * Returns a new array and leaves `item` immutable.
 */
export function sortArray (items, prop) {
  return items.slice().sort((A, B) => {
    let a = A[prop]
    if (typeof a !== 'string') {
      throw new Error(`${prop} does not exist in A`)
    }
    let b = B[prop]
    if (typeof b !== 'string') {
      throw new Error(`${prop} does not exist in B`)
    }
    a = a.toLowerCase()
    b = b.toLowerCase()
    if (a < b) return -1
    else if (a > b) return 1
    else return 0
  })
}

/**
 * Returns a new formatted string with no \n or whitespace.
 * Required for CSV formatting to not panic.
 */
export function trimNewLineFromString (str) {
  return str ? str.replace(/\n/g, '').trim() : ''
}

/**
 * Return true if a token has more than 90 seconds left
 */
export function isValidToken (token) {
  return typeof token === 'string' && getTokenExpiresInMs(token) > 90 * 1000
}

/**
 * Return time left until token expires in milliseconds
 */
function getTokenExpiresInMs (token) {
  const { exp } = decodeToken(token)
  return exp * 1000 - Date.now()
}

/**
 * Decodes the payload from a json web token. Does not verify.
 */
export function decodeToken (token) {
  const parts = (token || '').split('.')

  if (parts.length !== 3) {
    return {}
  }

  let payload = {}

  try {
    payload = JSON.parse(atob(parts[1]))
  } catch (e) {
    console.error('Failed to parse encoded token payload', e)
  }

  return payload
}

/**
 * Guards against invalid url format (i.e: pergas.se !== https://pergas.se)
 * if invalid ssl certificate, browsers fall back to http
 */
export function urlAsHttps (url = '') {
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `https://${url}`
  }
  return url
}

/**
 * Array of requests and methods that needs access tokens.
 */
const NEED_TOKENS = [
  { path: /^contact$/, method: 'POST' },
  { path: /^contact\/\d+$/, method: 'PUT' },
  { path: /^contact\/\d+$/, method: 'DELETE' },
  { path: /^project$/, method: 'POST' },
  { path: /^project\/\d+$/, method: 'PUT' },
  { path: /^project\/\d+$/, method: 'DELETE' },
  { path: /^department$/, method: 'POST' },
  { path: /^department\/\d+$/, method: 'PUT' },
  { path: /^department\/\d+$/, method: 'DELETE' },
  { path: /^category$/, method: 'POST' },
  { path: /^category\/\d+$/, method: 'PUT' },
  { path: /^category\/\d+$/, method: 'DELETE' },
  { path: /^content-type$/, method: 'GET' },
  { path: /^content-type$/, method: 'DELETE' },
  { path: /^content-type-ui-field$/, method: 'GET' }
]

/**
 * Returns true if a request needs an access token, otherwise false.
 */
export function needsAccessToken ({ path, method }) {
  return NEED_TOKENS.some(r => r.method === method && path.match(r.path))
}

export function responsiblePerson (personRoles) {
  if (!Array.isArray(personRoles)) {
    return []
  }

  return personRoles.find((pr) => pr.role_internal_name === 'responsible')
}

export function withoutResponsiblePerson (personRoles) {
  if (!Array.isArray(personRoles)) {
    return []
  }

  return personRoles.filter((r) => r.role_internal_name !== 'responsible')
}

export function dedupeArrays (arr, arrTwo) {
  return [...arr, ...arrTwo].filter((value, index, v) => {
    return v.findIndex(item => item.person_id === value.person_id && item.role_id === value.role_id) === index
  })
}
