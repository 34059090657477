import { createSelector } from '@reduxjs/toolkit'

export const makeSelectorById = () => createSelector(
  (state) => state.tag,
  (_, id) => id,
  (slice, id) => {
    return slice.items?.find(({ id: _id }) => _id === Number(id))
  }
)

export const makeSelectorTag = () => createSelector(
  (state) => state.tag,
  (slice) => {
    return slice.items || []
  }
)

export const makeSelectorTagFiltered = () => createSelector(
  (state) => state.tag,
  (_, query) => query,
  (slice, query) => {
    return slice.items.filter(({ name }) => name.toLowerCase().includes(query.toLowerCase())).sort((a, b) => {
      if (a.read_only === b.read_only) {
        return a.name.localeCompare(b.name)
      }
      return a.read_only ? -1 : 1
    })
  }
)
