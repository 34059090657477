import React, { useEffect, useCallback, useMemo, useRef } from 'react'
import { connect } from 'react-redux'
import PageToolbar from './PageToolbar.js'
import PageFooter from './PageFooter.jsx'
import redux from '../../redux/index.js'
import SearchField from '../SearchField.js'
import DeleteItem from '../dialogs/DeleteItem.js'
import TablePagination from './TablePagination.jsx'
import { Link, styled, Tag } from '@pergas-common/pergas-components'
import { Table, Td } from '../../pergas-components/index.js'
import { Add } from '@pergas-common/pergas-icons'
import { createDateAccessor } from './columns.js'
import EditTag from '../dialogs/EditTag.js'
import { DeleteItemIcon, TagIcon } from '../icons.js'
import Tooltip from '../Tooltip.js'
import { useNavigate } from 'react-router-dom'

const PageHolder = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 100%;
`

const TableHolder = styled.div`
  display: flex;
  overflow-y: scroll;
  flex-grow: 1;
  flex-basis: 50px;
`

const TagHolder = styled.span`
  margin-left: 5px;
  margin-right: 8px;
`

const TagPage = ({
  locale,
  rows,
  limit,
  offset,
  orderBy,
  order,
  search,
  resetSearch,
  getPageItems,
  setOrder,
  setLimit,
  setOffset,
  setSearch,
  canUpdate,
  canDelete,
  deleteItem,
  onDeleteItemClick,
  onDeleteOk,
  onDeleteCancel,
  editItem,
  onEditItemClick,
  onEditOk,
  onEditCancel,
  canCreate,
  onShowAddDialog
}) => {
  const navigate = useNavigate()
  useEffect(getPageItems, [limit, offset, orderBy, order, search])

  const setPageSizeRef = useRef(() => {})

  const manualSort = useCallback(({ id, isSorted, isSortedDesc }) => {
    if (isSorted && !isSortedDesc) {
      setOrder(id, 'desc')
    } else if (isSorted && isSortedDesc) {
      setOrder('', '')
    } else {
      setOrder(id, 'asc')
    }
  }, [setOrder])

  const columns = useMemo(() => {
    return [
      {
        Header: locale.name,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return (
            <Td
              {...cell.getCellProps()} left={
                <>
                  <TagIcon style={{ width: 16, height: 16 }} />
                  <TagHolder onClick={() => navigate(`/tags/edit?id=${original.id}`)}>
                    <Tag textColor='#FFFFFF' color={original.color}>{original.name}</Tag>
                  </TagHolder>
                </>
            }
            />
          )
        },
        canSort: true,
        id: 'name',
        size: 'sm',
        manualSort,
        sortType: () => {}
      },
      {
        Header: locale.description,
        manualSort,
        accessor: 'description',
        sortType: () => {}
      },
      {
        id: 'tag_type_name',
        Header: locale.tag_type,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return (<Td {...cell.getCellProps()} left={locale[original.tag_type_name] || ''} />)
        },
        isSortable: false
      },
      {
        id: 'updated_at',
        Header: locale.updated_at,
        manualSort,
        accessor: createDateAccessor('updated_at'),
        sortType: () => {}
      },
      {
        id: 'toolbar',
        Header: locale.tool_belt,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return (
            <Td
              {...cell.getCellProps()}
              left={<></>}
              right={canDelete && !original.read_only && <Tooltip side='left' content={locale.delete}><Link onClickHandler={() => { onDeleteItemClick(original) }}><DeleteItemIcon /></Link></Tooltip>}
            />
          )
        },
        size: 'sm',
        isSortable: false
      }
    ]
  }, [locale, canUpdate, canDelete, manualSort, onDeleteItemClick, onEditItemClick])

  let initialSortBy = []
  if (orderBy) {
    initialSortBy = [{
      id: orderBy,
      desc: order === 'desc'
    }]
  }

  const pagination = (
    <TablePagination
      limit={limit}
      offset={offset}
      onRowsPerPageChange={(e) => {
        setLimit(e.target.value)
        setPageSizeRef.current(e.target.value)
      }}
      onPageChange={(e, number) => setOffset(number * limit)}
    />
  )

  return (
    <PageHolder>
      {editItem && <EditTag isEditing item={editItem} onOk={onEditOk} onCancel={onEditCancel} />}
      {deleteItem && <DeleteItem text={deleteItem.name} onOk={() => { onDeleteOk(deleteItem) }} onCancel={onDeleteCancel} />}
      <PageToolbar left={
        <>
          <TagIcon style={{ width: 20, height: 20 }} />
          <span>{locale.tags}</span>
          {canCreate && <Link onClickHandler={() => { navigate('/tags/new') }}><Add width={20} height={20} color='#447fb1' /></Link>}
        </>
        }
      >
        <SearchField resetSearch={resetSearch} value={search} onChange={setSearch} />
      </PageToolbar>
      <TableHolder>
        <Table columns={columns} data={rows} initialPageSize={limit} initialSortBy={initialSortBy}>
          {({ setPageSize }) => {
            // Store table callback in a ref so we can call it from elsewhere
            setPageSizeRef.current = setPageSize
          }}
        </Table>
      </TableHolder>
      <PageFooter>{pagination}</PageFooter>
    </PageHolder>
  )
}

const mapStateToProps = (state) => {
  const { locale, tag } = state
  const permissions = state.login.permissions
  const {
    pageItems,
    limit,
    offset,
    orderBy,
    order,
    search
  } = tag
  return {
    locale: locale.strings,
    rows: pageItems,
    limit,
    offset,
    orderBy,
    order,
    search,
    canUpdate: permissions.tag.canUpdate,
    canDelete: permissions.tag.canDelete,
    canCreate: permissions.tag.canCreate,
    deleteItem: state.tag.deleteItem,
    editItem: state.tag.editItem
  }
}

const mapDispatchToProps = (dispatch) => {
  const { actions: { tag } } = redux
  return {
    onShowAddDialog: () => {
      dispatch(tag.showAddTagDialog())
    },

    onEditOk: (t) => {
      dispatch(tag.hideEditTagDialog())
      dispatch(tag.updateTag(t))
    },
    onEditItemClick: (t) => {
      dispatch(tag.showEditTagDialog(t))
    },
    onEditCancel: () => {
      dispatch(tag.hideEditTagDialog())
    },

    onDeleteOk: (t) => {
      dispatch(tag.hideDeleteTagDialog())
      dispatch(tag.deleteTag(t))
    },
    onDeleteItemClick: (t) => {
      dispatch(tag.showDeleteTagDialog(t))
    },
    onDeleteCancel: () => {
      dispatch(tag.hideDeleteTagDialog())
    },

    getPageItems: () => dispatch(tag.getPageItems()),
    setOrder: (orderBy, order) => dispatch(tag.setOrder(orderBy, order)),
    setLimit: (limit) => dispatch(tag.setLimit(limit)),
    setOffset: (offset) => dispatch(tag.setOffset(offset)),
    setSearch: (search) => dispatch(tag.setSearch(search)),
    resetSearch: () => dispatch(tag.resetSearch())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TagPage)
