import store from '../store.js'
import { getGraphToken } from '../login/actions.js'
import { ERROR_ACTION } from '../error/actions.js'
import { getTeamById } from '../../sharepoint/graph'

const ERROR_TYPE_TEAMS_NO_PAGE = 'ERROR_TYPE_TEAMS_NO_PAGE'

/**
 * Get a teams url for a group and launch it
 *
 * groupId: group id
 */
export function gotoTeamsPage (groupId) {
  return async function (dispatch) {
    const { userData } = store.getState().login
    const { email } = userData
    const token = await getGraphToken({ email })
    try {
      const { webUrl } = await getTeamById(groupId, token)
      window.open(webUrl)
    } catch (err) {
      console.error('failed to get teams url for group', groupId)
      dispatch({
        type: ERROR_ACTION,
        errorType: ERROR_TYPE_TEAMS_NO_PAGE
      })
    }
  }
}
