import React, { Fragment, useState } from 'react'
import {
  styled,
  LinkStyle,
  Grid,
  Link,
  Waves
} from '@pergas-common/pergas-components'
import {
  Remove,
  Contact,
  OpenExternal
} from '@pergas-common/pergas-icons'

import { Link as RLink, useNavigate } from 'react-router-dom'
import { useDeleteEmployeeMutation, useGetEmployeesQuery } from '../redux/employee/hooks'
import { DeleteItemV2 } from './dialogs/DeleteItem'

const ReactRouterLink = styled(RLink)`
  ${LinkStyle};
`

const EmployeeGrid = ({ currentId }) => {
  const navigate = useNavigate()
  const [remove, setRemove] = useState(null)
  const { data, isLoading } = useGetEmployeesQuery()
  const { metadata, mutation: deleteMutation, isLoading: isDeleting } = useDeleteEmployeeMutation()
  if (isLoading) {
    return <Grid.Item><Waves width={24} height={24} /></Grid.Item>
  }

  const employees = data.map((e) => {
    const isBeingDeleted = metadata?.id === e.id && isDeleting
    const currentEmployee = e.id === Number(currentId)
    const name = currentEmployee ? <>{e.first_name} {e.last_name}</> : <ReactRouterLink to={`/employees/edit?id=${e.id}`}>{e.first_name} {e.last_name}</ReactRouterLink>
    return (
      <Fragment key={e.id}>
        <Grid.Item><Contact width={18} height={18} /><span>{name}</span></Grid.Item>
        <Grid.Item><span>{e.address?.email}</span></Grid.Item>
        <Grid.Item actions>
          <span />
          <span />
          <span>{!currentEmployee && <Link onClickHandler={() => { navigate(`/employees/edit?id=${e.id}`) }}><OpenExternal color='#28afe0' width={20} height={20} /></Link>}</span>
          <span>{isBeingDeleted ? <Waves width={20} height={20} /> : !currentEmployee && <Link onClickHandler={() => { setRemove(e) }}><Remove width={20} height={20} color='#FF5656' /></Link>}</span>
        </Grid.Item>

      </Fragment>
    )
  })

  return (
    <>
      <Grid.Content columns='2fr 2fr 1fr'>{employees}</Grid.Content>
      {remove && <DeleteItemV2
        text={`${remove?.first_name} ${remove?.last_name}`}
        isOpen={!!remove}
        onCloseRequest={() => {
          setRemove(null)
        }}
        onSubmit={() => {
          deleteMutation({ id: remove.id })
          setRemove(null)
        }}
                 />}
    </>
  )
}

export default EmployeeGrid
