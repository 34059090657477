import { styled, Layout, Topbar, spacing } from '@pergas-common/pergas-components'
import React from 'react'
import TopbarNavigation from '../TopbarNavigation'

const PageHolder = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Holder = styled.div`
  width: 100%;
  height: 100%;
  background: rgb(247, 249, 250);
`

const Background = styled.div`
  height: 50px;
  background: ${({ theme }) => theme.palette.brand.secondary};
`

const Name = styled.span`
  ${({ theme }) => theme.typography.body};
  color: ${({ theme }) => theme.palette.common.primary};
`

const Main = styled(Layout.Main)`
  height: 100%;
  width: 100%;
  padding-bottom: ${spacing(4)};
  overflow-y: scroll;
`

export default function Page ({ metadata, children }) {
  return (
    <PageHolder>
      <TopbarNavigation title={metadata.title} icon={metadata.icon} link={metadata.link} />
      <Background>
        <Topbar left={<Name>{metadata.title}</Name>} />
      </Background>
      <Holder>
        <Main>
          {children}
        </Main>
      </Holder>
    </PageHolder>
  )
}
