import React, { memo, useCallback, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { styled, Modal, Button as PergasButton, Group } from '@pergas-common/pergas-components'
import { Company, Add, Close as PergasClose } from '@pergas-common/pergas-icons'
import {
  Button,
  MenuItem,
  TextField
} from '@mui/material'
import { CheckBox, Close } from '@mui/icons-material'
import { selectLocale } from '../../redux/locale/selectors'
import redux, { api } from '../../redux'
import useRequest from '../../hooks/useRequest'
import useDebounce from '../../hooks/useDebounce'
import { AddItemIcon } from '../icons'
import { sortArray } from '../../util'
import { InputGroup, itemsWithEmail, Select } from '../Input'
import DialogBase, { actionButtonStyle, actionIconStyle } from './DialogBase'

const Holder = styled.div`
  display: flex;
  height: 130px;
  flex-direction: column;
`

const ContentHolder = styled.div`
  max-width: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 32px;
  align-self: center;
`

const usePersonRole = (defaultPerson) => {
  const [getContactPersons, contactPersons, contactPersonsStatus] = useRequest(api.getContactPersons, 'contactPerson')
  const [getEmployees, employees, employeesStatus] = useRequest(api.getEmployees, 'employee')
  const [getRoles, roles, rolesStatus] = useRequest(api.getRoles, 'roles')
  const [person, setPerson] = useState(defaultPerson)
  const [role, setRole] = useState(null)
  const [people, setPeople] = useState([])

  return [
    people,
    setPeople,
    person,
    setPerson,
    role,
    setRole,
    roles,
    rolesStatus,
    getRoles,
    getEmployees,
    employees,
    employeesStatus,
    getContactPersons, contactPersons,
    contactPersonsStatus
  ]
}

const useAvailableRoles = (values, person, roles) => {
  if (!Array.isArray(roles)) {
    return []
  }
  return roles.filter((r) => {
    if (person && values?.length > 0) {
      const hasRole = values.some((pr) => pr.person_id === person.id && pr.role_id === r.id)
      return !hasRole && r.internal_name !== 'responsible'
    }
    return r.internal_name !== 'responsible'
  })
}

export const AddPersonRoleV2 = memo(({ values, onChangeHandler, isOpen, onCloseRequest }) => {
  const locale = useSelector(selectLocale)
  const [people, setPeople, person, setPerson, role, setRole, roles, rolesStatus, getRoles, getEmployees, employees, employeesStatus, getContactPersons, contactPersons, contactPersonsStatus] = usePersonRole()

  const [debouncedContactPersonsRequest] = useDebounce(getContactPersons, 300)
  const [debouncedEmployeesRequest] = useDebounce(getEmployees, 300)
  const [debouncedRoleRequest] = useDebounce(getRoles, 300)
  const isFetchingPeople = contactPersonsStatus === 'pending' || employeesStatus === 'pending'

  const selectableRoles = useAvailableRoles(values, person, roles)

  const disableSubmit = person === null || role === null || selectableRoles.length === 0
  const onSelect = useCallback((p) => {
    const containing = Array.isArray(values) ? [...values] : []
    const index = containing.findIndex((pr) => pr.person_id === p.person_id && pr.role_id === p.role_id)
    if (index === -1) {
      onChangeHandler([...containing, p])
    }
    onCloseRequest()
  }, [onChangeHandler, onCloseRequest, values])

  useEffect(() => {
    getContactPersons({ sort: 'name.asc', limit: 50 })
    getEmployees({ sort: 'name.asc', limit: 50 })
  }, [getContactPersons, getEmployees])

  useEffect(() => {
    if (Array.isArray(employees) && Array.isArray(contactPersons)) {
      setPeople(sortArray(employees.concat(contactPersons), 'name'))
    }
  }, [employees, contactPersons, setPeople])

  useEffect(() => {
    getRoles({ sort: 'name.asc', limit: 50 })
  }, [getRoles])

  return (
    <Modal
      title={locale.person_role} titleIcon={<Company width={20} height={20} />} isOpen={isOpen} size='lg' footer={() => (
        <Group.Button>
          <PergasButton onClick={() => {
            onCloseRequest()
          }}
          ><span>{locale.cancel}</span><PergasClose width={18} height={18} color='#28afe0' />
          </PergasButton>
          <PergasButton
            disabled={disableSubmit} onClick={() => {
              const personRole = {
                ...person,
                person_id: person.id,
                person_name: person.name,
                role_id: role.id,
                role_name: role.name,
                role_internal_name: role.internal_name
              }
              onSelect(personRole)
            }}
          ><span>{locale.add}</span><Add width={20} height={20} color='#28afe0' />
          </PergasButton>
        </Group.Button>
      )}
    >
      {() => (
        <Holder>
          <ContentHolder>
            <InputGroup fullWidth>
              <Select
                name='name'
                label={locale.name}
                defaultValue={null}
                request={(input) => {
                  debouncedContactPersonsRequest({ query: [{ key: 'name', op: '~', value: input }], sort: 'name.asc', limit: 50 })
                  debouncedEmployeesRequest({ query: [{ key: 'name', op: '~', value: input }], sort: 'name.asc', limit: 50 })
                }}
                requestStatus={isFetchingPeople}
                items={itemsWithEmail(people)}
                handleChange={(_, id, person) => {
                  setPerson({ ...person, id })
                }}
              />
              <Select
                name='role'
                label={locale.role}
                defaultValue={null}
                disabled={!person}
                request={(input) => {
                  debouncedRoleRequest({ query: [{ key: 'name', op: '~', value: input }], sort: 'name.asc', limit: 50 })
                }}
                requestStatus={rolesStatus === 'pending'}
                items={selectableRoles}
                handleChange={(_, id, role) => {
                  setRole({ ...role, id })
                }}
              />
            </InputGroup>
          </ContentHolder>
        </Holder>
      )}
    </Modal>
  )
})
/*
  A couple of things going on in this component.
  1. We support querying for contacts, employees & roles.
  2. We handle both editing and adding of a person role.
  3. We filter the data based on rules such as:
     a) don't show the person if they are already in the list when creating a new person role.
     b) don't show their role if they are already are assigned to that specific role.
*/

class AddPersonRole extends DialogBase {
  constructor (props) {
    super(props)
    const { strings } = this.props
    this.title = {
      Icon: AddItemIcon,
      IconColor: 'white',
      text: strings.person_role_add
    }
    this.state = {
      personId: 0,
      roleId: 0
    }
  }

  componentDidMount () {
    this.props.getData()
  }

  renderPersons () {
    const persons = sortArray(this.props.persons, 'name')
    return persons.map(item => (
      <MenuItem key={item.id} value={item.id}>
        {item.name}
      </MenuItem>
    )).concat(<MenuItem key='0' value={0}>---</MenuItem>)
  }

  renderRoles () {
    const { personId } = this.state
    const { roles, personRole } = this.props
    // Filter out roles that has already been set for the
    // currently selected person
    const filteredRoles = roles.filter(role => {
      if (personId) {
        return !personRole.some(pr => {
          return pr.person_id === personId && pr.role_id === role.id
        })
      } else {
        return true
      }
    })
    const sortedRoles = sortArray(filteredRoles, 'name')
    return sortedRoles.map(item => (
      <MenuItem key={item.id} value={item.id}>
        {item.name}
      </MenuItem>
    )).concat(<MenuItem key='0' value={0}>---</MenuItem>)
  }

  renderContent () {
    const { strings, persons, roles } = this.props
    const { personId, roleId } = this.state

    const handleChangePerson = (event) => {
      this.setState({
        personId: event.target.value,
        roleId: 0
      })
    }

    const handleChangeRole = (event) => {
      this.setState({ roleId: event.target.value })
    }

    return (
      <>
        <TextField
          size='small'
          select
          label={strings.person}
          value={persons.length > 0 ? (personId || 0) : 0}
          onChange={handleChangePerson}
          fullWidth
          required
          margin='dense'
          variant='outlined'
        >
          {this.renderPersons()}
        </TextField>
        <TextField
          size='small'
          select
          label={strings.role}
          value={roles.length > 0 ? (roleId || 0) : 0}
          onChange={handleChangeRole}
          fullWidth
          required
          margin='dense'
          variant='outlined'
          disabled={personId === 0}
        >
          {this.renderRoles()}
        </TextField>
      </>
    )
  }

  localOk () {
    const { onOk, persons, roles } = this.props
    const { personId, roleId } = this.state

    const person = persons.find(p => p.id === personId)
    const role = roles.find(p => p.id === roleId)

    onOk({
      person_id: personId,
      person_name: (person && person.name) || '',
      role_id: roleId,
      role_name: (role && role.name) || '',
      role_internal_name: (role && role.internal_name) || ''
    })
  }

  renderActions () {
    const { strings, onCancel } = this.props
    const { personId, roleId } = this.state
    const okDisabled = personId === 0 || roleId === 0
    return (
      <>
        <Button
          disabled={okDisabled}
          onClick={() => this.localOk()}
          style={actionButtonStyle(okDisabled)}
        >
          <CheckBox style={actionIconStyle} />{strings.ok}
        </Button>
        <Button
          onClick={onCancel}
          style={actionButtonStyle()}
        >
          <Close style={actionIconStyle} />{strings.cancel}
        </Button>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  // TOODO This should be search data
  const { contactPerson, employee } = state
  const persons = contactPerson.items.concat(employee.items)
  return {
    strings: state.locale.strings,
    persons,
    roles: state.role.items
  }
}

const mapDispatchToProps = (dispatch) => {
  const { actions } = redux
  return {
    getData: () => {
      dispatch(actions.contactPerson.getContactPersons())
      dispatch(actions.employee.getEmployees())
      dispatch(actions.role.getRoles())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPersonRole)
