import { busy, notBusy } from '../busy/actions.js'
import {
  handleAddErrors,
  handleGetErrors,
  handleUpdateErrors,
  handleDeleteErrors
} from '../error/actions.js'

import api from '../api.js'
import store from '../store.js'

export const OBJECT_TYPE_CREATE_ACTION = 'OBJECT_TYPE_CREATE_ACTION'
export const OBJECT_TYPE_SHOW_ADD_DIALOG_ACTION = 'OBJECT_TYPE_SHOW_ADD_DIALOG_ACTION'
export const OBJECT_TYPE_HIDE_ADD_DIALOG_ACTION = 'OBJECT_TYPE_HIDE_ADD_DIALOG_ACTION'
export const OBJECT_TYPE_GET_OK_ACTION = 'OBJECT_TYPE_GET_OK_ACTION'
export const OBJECT_TYPE_UPDATE_ACTION = 'OBJECT_TYPE_UPDATE_ACTION'
export const OBJECT_TYPE_SHOW_EDIT_DIALOG_ACTION = 'OBJECT_TYPE_SHOW_EDIT_DIALOG_ACTION'
export const OBJECT_TYPE_HIDE_EDIT_DIALOG_ACTION = 'OBJECT_TYPE_HIDE_EDIT_DIALOG_ACTION'
export const OBJECT_TYPE_DELETE_ACTION = 'OBJECT_TYPE_DELETE_ACTION'
export const OBJECT_TYPE_SHOW_DELETE_DIALOG_ACTION = 'OBJECT_TYPE_SHOW_DELETE_DIALOG_ACTION'
export const OBJECT_TYPE_HIDE_DELETE_DIALOG_ACTION = 'OBJECT_TYPE_HIDE_DELETE_DIALOG_ACTION'

export const OBJECT_TYPE_GET_PAGE_ITEMS_ACTION = 'OBJECT_TYPE_GET_PAGE_ITEMS_ACTION'
export const OBJECT_TYPE_SET_ORDER_ACTION = 'OBJECT_TYPE_SET_ORDER_ACTION'
export const OBJECT_TYPE_SET_LIMIT_ACTION = 'OBJECT_TYPE_SET_LIMIT_ACTION'
export const OBJECT_TYPE_SET_OFFSET_ACTION = 'OBJECT_TYPE_SET_OFFSET_ACTION'
export const OBJECT_TYPE_SET_SEARCH_ACTION = 'OBJECT_TYPE_SET_SEARCH_ACTION'
export const OBJECT_TYPE_RESET_SEARCH_ACTION = 'OBJECT_TYPE_RESET_SEARCH_ACTION'

export function objectTypeCreateAction (objectType) {
  return {
    type: OBJECT_TYPE_CREATE_ACTION,
    objectType
  }
}

export function objectTypeUpdateAction (objectType) {
  return {
    type: OBJECT_TYPE_UPDATE_ACTION,
    objectType
  }
}

export function objectTypeDeleteAction (objectType) {
  return {
    type: OBJECT_TYPE_DELETE_ACTION,
    objectType
  }
}

/**
 * Add dialog
 */
export function showAddObjectTypeDialog () {
  return {
    type: OBJECT_TYPE_SHOW_ADD_DIALOG_ACTION
  }
}

export function hideAddObjectTypeDialog () {
  return {
    type: OBJECT_TYPE_HIDE_ADD_DIALOG_ACTION
  }
}

/**
 * Edit dialog
 */
export function showEditObjectTypeDialog (objectType) {
  return {
    type: OBJECT_TYPE_SHOW_EDIT_DIALOG_ACTION,
    objectType
  }
}

export function hideEditObjectTypeDialog () {
  return {
    type: OBJECT_TYPE_HIDE_EDIT_DIALOG_ACTION
  }
}

/**
 * Delete dialog
 */
export function showDeleteObjectTypeDialog (objectType) {
  return {
    type: OBJECT_TYPE_SHOW_DELETE_DIALOG_ACTION,
    objectType
  }
}

export function hideDeleteObjectTypeDialog () {
  return {
    type: OBJECT_TYPE_HIDE_DELETE_DIALOG_ACTION
  }
}

/**
 * CREATE
 */
export function addObjectType (type) {
  return function (dispatch) {
    dispatch(busy())
    api.addObjectType(type).then(() => {
      dispatch(notBusy())
    }).catch(handleAddErrors('objectType', dispatch))
  }
}

/**
 * READ
 */
export function getObjectTypes () {
  return function (dispatch) {
    dispatch(busy())
    api.getObjectTypes().then(result => {
      dispatch(notBusy())
      dispatch(receivedObjectTypes(result.data.value))
    }).catch(handleGetErrors('objectType', dispatch))
  }
}

export function receivedObjectTypes (items) {
  return {
    type: OBJECT_TYPE_GET_OK_ACTION,
    items
  }
}

/**
 * UPDATE
 */
export function updateObjectType (type) {
  return function (dispatch) {
    dispatch(busy())
    api.updateObjectType(type).then(() => {
      dispatch(notBusy())
    }).catch(handleUpdateErrors('objectType', dispatch))
  }
}

/**
 * DELETE
 */
export function deleteObjectType (type) {
  return function (dispatch) {
    dispatch(busy())
    api.deleteObjectType(type).then(() => {
      dispatch(notBusy())
    }).catch(handleDeleteErrors('objectType', dispatch))
  }
}

/**
 * Get items for a page
 */
export function getPageItems () {
  const state = store.getState()
  const { search, limit, offset, orderBy, order } = state.objectType

  const query = []
  if (typeof search === 'string' && search.length > 0) {
    query.push({ key: 'name', value: search, op: '~' })
  }

  const sort = orderBy ? `${orderBy}.${order}` : null

  return function (dispatch) {
    dispatch(busy())
    api.getObjectTypes({
      query,
      limit,
      offset,
      sort
    }).then(result => {
      dispatch(notBusy())
      dispatch({
        type: OBJECT_TYPE_GET_PAGE_ITEMS_ACTION,
        pageItems: result.data.value
      })
    }).catch(handleGetErrors('objectType', dispatch))
  }
}

/**
 * Sort order
 */
export function setOrder (orderBy, order) {
  return {
    type: OBJECT_TYPE_SET_ORDER_ACTION,
    orderBy,
    order
  }
}

/**
 * Set limit for pagination
 */
export function setLimit (limit) {
  return {
    type: OBJECT_TYPE_SET_LIMIT_ACTION,
    limit
  }
}

/**
 * Set offset for pagination
 */
export function setOffset (offset) {
  return {
    type: OBJECT_TYPE_SET_OFFSET_ACTION,
    offset
  }
}

/**
 * Set search
 */
export function setSearch (search) {
  return {
    type: OBJECT_TYPE_SET_SEARCH_ACTION,
    search
  }
}

/**
 * Reset search
 */
export function resetSearch () {
  return {
    type: OBJECT_TYPE_RESET_SEARCH_ACTION
  }
}
