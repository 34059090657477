import { busy, notBusy } from '../busy/actions.js'
import {
  networkError,
  unknownError,
  resetError,
  ERROR_ACTION
} from '../error/actions.js'
import { isValidToken, needsAccessToken } from '../../util.js'
import { setLocale } from '../locale/actions.js'
import storage from '../storage'
import msal from '../../msal.js'
import api from '../api.js'
import store from '../store.js'
import resources from '../../resources'

export const LOGIN_SUCCESS_ACTION = 'LOGIN_SUCCESS_ACTION'
export const LOGIN_GOT_USER_AVATAR_ACTION = 'LOGIN_GOT_USER_AVATAR_ACTION'
export const ERROR_TYPE_LOGIN = 'ERROR_TYPE_LOGIN'
export const LOGOUT_ACTION = 'LOGOUT_ACTION'

let heartBeatInterval = null

/**
 * Token heart beat.
 */
export function doStartHeartBeat () {
  return function (dispatch) {
    if (heartBeatInterval) {
      clearInterval(heartBeatInterval)
      heartBeatInterval = null
    }

    heartBeatInterval = setInterval(async () => {
      const { userData } = store.getState().login
      if (!isValidToken(userData.token)) {
        storage.deleteApiToken()
        window.location.reload()
      }
    }, 60 * 1000)
  }
}

/**
 * Pre request hook that pulls access tokens from the msal cache and
 * sets the correct headers.
 */
api.setPreHook(async (req) => {
  const ud = store.getState().login.userData
  api.setHeader('Authorization', `Bearer ${ud.token}`)

  api.removeHeader('X-SP-Authorization')
  api.removeHeader('X-Graph-Authorization')

  if (ud.sharePointSaveEnabled && needsAccessToken(req)) {
    api.setHeader('X-SP-Authorization', `Bearer ${await getSpAccessToken(ud)}`)
    api.setHeader('X-Graph-Authorization', `Bearer ${await getGraphToken(ud)}`)
  }
})

/**
 * Main login function.
 */
export function doLogin (idToken) {
  return function (dispatch) {
    dispatch(resetError())

    dispatch(busy())
    api.login(idToken).then(async (result) => {
      dispatch(notBusy())
      const userData = result.data.value
      const tenantSettings = userData?.tenant_settings || {}
      const sharePointSaveEnabled = tenantSettings.sharepoint_save_enabled || false
      const sharePointContactsEnabled = tenantSettings.sharepoint_contacts_enabled || false
      const sharePointTemplatesEnabled = tenantSettings.sharepoint_templates_enabled || false
      userData.sharePointSaveEnabled = sharePointSaveEnabled
      userData.sharePointTemplatesEnabled = sharePointTemplatesEnabled
      userData.sharePointContactsEnabled = sharePointContactsEnabled

      const { locale, branch } = tenantSettings
      if (typeof locale === 'string' && typeof branch === 'string') {
        const localLocale = `${locale.split('-')[0]}_${branch}`
        if (resources[localLocale] && !storage.getLocale()) {
          console.info(`setting ${localLocale} locale from backend`)
          dispatch(setLocale(localLocale))
        }
      }

      dispatch(loginSuccessful(userData))
      await getUserAvatar(userData)
    }).catch(err => {
      console.error('api.login() failed', err)
      dispatch(notBusy())
      const { response } = err
      if (response) {
        if (response.status === 401) {
          dispatch(loginError('LOGIN_ERROR_API_TOKEN'))
        } else {
          dispatch(unknownError(err))
        }
      } else {
        dispatch(networkError(err))
      }
    })

    async function getUserAvatar (userData) {
      const graphToken = await getGraphToken(userData)
      const req = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value?size=96x96', { headers: { Authorization: `Bearer ${graphToken}` } })
      if (req.status === 200) {
        const b = await req.blob()
        const reader = new FileReader()
        reader.onloadend = function () {
          const avatar = reader.result
          if (avatar.startsWith('data:image/jpeg;base64,')) {
            storage.putUserAvatar(avatar)
            dispatch({ type: LOGIN_GOT_USER_AVATAR_ACTION, avatar })
          }
        }
        reader.readAsDataURL(b)
      }
    }
  }
}

/**
 * Fetch an access token for SharePoint
 */
export function getSpAccessToken (userData) {
  const url = new URL(userData.sc_root)
  const scopes = [`${url.protocol}//${url.host}//.default`]
  return getAccessToken(scopes, userData.email)
}

/**
 * Fetch an access token for Graph API
 */
export function getGraphToken (userData) {
  const scopes = ['sites.fullcontrol.all', 'group.readwrite.all', 'user.read']
  return getAccessToken(scopes, userData.email)
}

/**
 * Common access token function.
 */
async function getAccessToken (scopes, email) {
  const account = msal.getAccountByUsername(email)
  const request = { scopes, account }
  try {
    const result = await msal.acquireTokenSilent(request)
    return result.accessToken
  } catch (err) {
    console.error('Failed to get access token', err)
    console.info('Trying aquireTokenRedirect')
    await msal.acquireTokenRedirect(request)
  }
}

function loginSuccessful (ud) {
  const userData = { ...ud }
  console.info('login successful!')
  setTimeout(() => storage.putUserData(userData), 50)
  return {
    type: LOGIN_SUCCESS_ACTION,
    userData
  }
}

function loginError (errorMessage) {
  return {
    type: ERROR_ACTION,
    errorType: ERROR_TYPE_LOGIN,
    errorMessage
  }
}

export function doLogout () {
  api.removeHeader('Authorization')
  api.removeHeader('X-SP-Authorization')
  api.removeHeader('X-Graph-Authorization')
  storage.deleteUserData()
  storage.deleteUserAvatar()
  return function (dispatch) {
    msal.logout().then(res => {
      console.log('msal.logout() successful!')
    }).catch(err => {
      console.error('msal.logout() failed', err)
    })
  }
}
