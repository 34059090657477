import { createSelector } from '@reduxjs/toolkit'

export const makeSelectorById = () => createSelector(
  (state) => state.objectType,
  (_, id) => id,
  (slice, id) => {
    return slice.items?.find(({ id: _id }) => _id === Number(id))
  }
)

export const makeSelectorObjectType = () => createSelector(
  (state) => state.objectType,
  (slice) => {
    return slice.items || []
  }
)

export const makeSelectorObjectTypeFiltered = () => createSelector(
  (state) => state.objectType,
  (_, query) => query,
  (slice, query) => {
    return slice.items.filter(({ name }) => name.toLowerCase().includes(query.toLowerCase()))
  }
)
