import React, { Fragment, useMemo, useState } from 'react'
import {
  styled,
  LinkStyle,
  Grid,
  Link,
  Waves
} from '@pergas-common/pergas-components'
import {
  Remove,
  OpenExternal,
  Folder
} from '@pergas-common/pergas-icons'

import { Link as RLink, useNavigate } from 'react-router-dom'
import { CategoryIcon } from './icons'
import { useDeleteCategoryMutation, useGetCategoryQuery } from '../redux/category/hooks'
import { DeleteItemV2 } from './dialogs/DeleteItem'
import Tooltip from './Tooltip'
import { useSelector } from 'react-redux'
import { selectLocale } from '../redux/locale/selectors'
import { makeSelectorByDepartmentId } from '../redux/category/selectors'

const ReactRouterLink = styled(RLink)`
  ${LinkStyle};
`

const CategoriesGrid = ({ departmentId }) => {
  const locale = useSelector(selectLocale)
  const navigate = useNavigate()
  const [remove, setRemove] = useState(null)
  const selectCategriesByDepartmentId = useMemo(makeSelectorByDepartmentId, [])
  const data = useSelector((state) => selectCategriesByDepartmentId(state, departmentId))
  const { isLoading } = useGetCategoryQuery({ query: [{ key: 'collection_id', op: '=', value: departmentId }] })
  const { metadata, mutation: deleteMutation, isLoading: isDeleting } = useDeleteCategoryMutation()
  if (isLoading) {
    return <Grid.Item><Waves width={24} height={24} /></Grid.Item>
  }
  const categories = data?.map((c) => {
    const isBeingDeleted = metadata?.id === c.id && isDeleting
    const responsible = c?.person_role?.filter((r) => r.role_internal_name === 'responsible')
    return (
      <Fragment key={c.id}>
        <Grid.Item><CategoryIcon width={18} height={18} /><span><ReactRouterLink to={`/categories/edit?id=${c.id}`}>{c.name}</ReactRouterLink></span></Grid.Item>
        <Grid.Item><span>{responsible && responsible.length > 0 && responsible[0].person_name}</span></Grid.Item>
        <Grid.Item actions>
          <span />
          <span><Tooltip side='left' content={locale.category}><span><Link href={`${c.url}`}><Folder width={20} height={20} /></Link></span></Tooltip></span>
          <span><Link onClickHandler={() => { navigate(`/categories/edit?id=${c.id}`) }}><OpenExternal color='#28afe0' width={20} height={20} /></Link></span>
          <span>{isBeingDeleted ? <Waves width={20} height={20} /> : <Link onClickHandler={() => { setRemove(c) }}><Remove width={20} height={20} color='#FF5656' /></Link>}</span>
        </Grid.Item>
      </Fragment>
    )
  })

  return (
    <>
      <Grid.Content columns='2fr 2fr 1fr'>{categories}</Grid.Content>
      {remove && <DeleteItemV2
        text={`${remove?.name}`}
        isOpen={!!remove}
        onCloseRequest={() => {
          setRemove(null)
        }}
        onSubmit={() => {
          deleteMutation({ id: remove.id })
          setRemove(null)
        }}
                 />}
    </>
  )
}

export default CategoriesGrid
