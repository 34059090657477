import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Save } from '@mui/icons-material'
import { Button, Card, Group, Layout, Link, Waves, styled } from '@pergas-common/pergas-components'
import { Add, Folder, Remove } from '@pergas-common/pergas-icons'
import Input from '../../Input.js'
import { useAddCategoryMutation, useDeleteCategoryMutation, useGetCategoryByIdQuery, useUpdateCategoryMutation } from '../../../redux/category/hooks'
import { selectLocale } from '../../../redux/locale/selectors.js'
import useUrlQuery from '../../../hooks/useUrlQuery.js'
import { DeleteItemV2 } from '../../dialogs/DeleteItem'
import { CategoryIcon } from '../../icons.js'
import ObjectTypeDropdown from '../../ObjectTypeDropdown.js'
import { PersonRoleGrid } from '../../PersonRoleGrid.js'
import { AddPersonRoleV2 } from '../../dialogs/AddPersonRole.js'
import ResponsibleDropdown from '../../ResponsibleDropdown.js'
import Page from '../Page.js'
import { dedupeArrays, responsiblePerson, withoutResponsiblePerson } from '../../../util.js'
import TagListCard from '../TagListCard.js'
import { makeSelectorBySharepointStatus, makeSelectorBySharepointUrl } from '../../../redux/category/selectors.js'
import AsyncLoader from '../../AsyncLoader.js'
import DepartmentDropdown from '../../DepartmentDropdown.js'
import CategoriesGrid from '../../CategoriesGrid.js'

const Overflow = styled.div`
  overflow-y: hidden;
  height: 218px;
`

const Form = ({ id, onSubmit, data, personRoles }) => {
  const locale = useSelector(selectLocale)
  const ref = useRef(null)
  const { state } = useLocation()
  const defaultObjectType = {
    id: data?.object_type_id || state?.object_type_id || 0,
    name: data?.object_type_name || state?.object_type_name || ''
  }

  const defaultDepartment = {
    id: data?.collection_id || state?.collection_id || 0,
    name: data?.collection_name || state?.collection_name || ''
  }

  let defaultTags = []
  if (Array.isArray(data?.tags)) {
    defaultTags = [...data.tags]
  }
  if (Array.isArray(state?.tags)) {
    defaultTags = [...defaultTags, ...state.tags]
  }

  const responsibleRole = responsiblePerson(data?.person_role)
  const defaultResponsible = responsibleRole && { ...responsibleRole, name: responsibleRole.person_name, id: responsibleRole.person_id }

  const [responsible, setResponsible] = useState(defaultResponsible)
  const [department, setDepartment] = useState(defaultDepartment)
  const [objectType, setObjectType] = useState(defaultObjectType)
  const [tags, setTags] = useState(defaultTags)

  const submit = (e) => {
    e.preventDefault()
    const payload = {}
    const formData = new FormData(ref.current)
    for (const [key, value] of formData.entries()) {
      if (id) {
        payload.id = Number(id)
      }
      switch (key) {
        case 'object_type_id':
          payload[key] = objectType?.id || null
          break
        case 'collection_id':
          payload[key] = department?.id || null
          break
        case 'responsible_person_role': {
          const withoutResponsible = withoutResponsiblePerson(personRoles)
          if (responsible?.person_id || responsible?.id) {
            payload.person_role = [...withoutResponsible, responsible]
          } else if (Object.values(withoutResponsible).length > 0) {
            payload.person_role = withoutResponsible
          }
          break
        }
        default:
          payload[key] = value
          break
      }
      payload.tags = [...tags.map((tag) => {
        return {
          color: tag.color,
          name: tag.name,
          tag_id: tag.tag_id ?? tag.id,
          tag_type_name: tag.tag_type_name
        }
      })]
    }
    onSubmit(payload)
  }

  return (
    <form onSubmit={submit} id='category' ref={ref}>
      <Input name='name' label={locale.name} defaultValue={data?.name} />
      <DepartmentDropdown disabled={id} label={locale.department} setDepartment={setDepartment} defaultValue={defaultDepartment} />
      <ObjectTypeDropdown type='category' setObjectType={setObjectType} label={locale.category_object_type} defaultValue={defaultObjectType} />
      <ResponsibleDropdown label={locale.responsible} setResponsible={setResponsible} defaultValue={defaultResponsible} />
      <TagListCard tags={tags} tagType='category' onChangeHandler={setTags} />
    </form>
  )
}

const CategoryDetails = () => {
  const [remove, setRemove] = useState(false)
  const [personRoles, setPersonRoles] = useState([])
  const [addPersonRole, setAddPersonRole] = useState(false)
  const navigate = useNavigate()
  const query = useUrlQuery()
  const id = query.get('id')
  const locale = useSelector(selectLocale)

  const selectorBySharepointStatus = useMemo(makeSelectorBySharepointStatus, [])
  const selectorBySharepointUrl = useMemo(makeSelectorBySharepointUrl, [])

  const sharepointStatus = useSelector((state) => selectorBySharepointStatus(state, id))
  const sharepointUrl = useSelector((state) => selectorBySharepointUrl(state, id))
  const disableSharepoint = sharepointStatus !== 'finished'
  const updatingSharepoint = sharepointStatus === 'creating' || sharepointStatus === 'updating'

  const { data, isUnitialized, isLoading, isSuccessful } = useGetCategoryByIdQuery(id)
  const { mutation: updateMutation, isLoading: isUpdating } = useUpdateCategoryMutation()
  const { metadata, mutation: createMutation, isLoading: isCreating, isSuccessful: isCreated } = useAddCategoryMutation()
  const { mutation: deleteMutation, isLoading: isDeleting, isSuccessful: isDeleted } = useDeleteCategoryMutation()

  const pageMetadata = {
    title: locale.category,
    icon: <CategoryIcon width={20} height={20} />,
    link: (
      <Link onClickHandler={() => {
        navigate('/categories/new', {
          replace: true,
          state: {
            object_type_id: data?.object_type_id,
            object_type_name: data?.object_type_name,
            collection_id: data?.collection_id,
            collection_name: data?.collection_name,
            tags: data?.tags
          }
        })
      }}
      ><Add width={20} height={20} color='#28afe0' />
      </Link>
    )
  }

  const submit = (payload) => {
    if (id) {
      updateMutation(payload)
      return
    }
    createMutation(payload)
  }

  useEffect(() => {
    return () => {
      setPersonRoles([])
    }
  }, [id])

  useEffect(() => {
    if (Array.isArray(data?.person_role)) {
      setPersonRoles((prev) => dedupeArrays(prev, data?.person_role))
    }
  }, [data?.person_role])

  useEffect(() => {
    if (metadata?.id) {
      navigate(`/categories/edit?id=${metadata?.id}`, { replace: true })
    }
  }, [metadata, isCreated])

  useEffect(() => {
    if (isDeleted) {
      navigate('/categories', { replace: true })
    }
  }, [isDeleted])

  return (
    <Page metadata={pageMetadata}>
      <Layout.Aside>
        {(isSuccessful || (isUnitialized && !id)) && (
          <Form personRoles={personRoles} id={id} data={data} onSubmit={submit} key={id} />
        )}
        {(isLoading && !isUnitialized) && (
          <Waves width={48} height={48} />
        )}
      </Layout.Aside>
      <Layout.Section>
        <Group.Button>
          <Button
            type='button' disabled={!id} onClick={() => {
              setRemove(true)
            }}
          >
            {isDeleting ? <Waves width={20} height={20} /> : <Remove width={20} height={20} color='#FF5656' />}<span>{locale.delete}</span>
          </Button>
          <Button type='submit' form='category'>{(isUpdating || isCreating) ? <Waves width={20} height={20} /> : <Save style={{ color: '#34b3e1', width: 20, height: 20 }} />}<span>{locale.save}</span></Button>
        </Group.Button>
        <Group.Button>
          <Button type='button' disabled={disableSharepoint} onClick={() => { window.open(`${sharepointUrl}/Forms/AllItems.aspx`) }}>
            <span>{locale.documents}</span>
            <AsyncLoader loading={updatingSharepoint}><Folder width={18} height={18} /></AsyncLoader>
          </Button>
        </Group.Button>
        <Card.Content>
          <Card.Header>{locale.categories_in_same_intranet}</Card.Header>
          <Overflow>
            {data?.collection_id && <CategoriesGrid departmentId={data?.collection_id} />}
          </Overflow>
        </Card.Content>
        <Card.Content>
          <Card.Header title={locale.contact_persons} columns='2fr 2fr 1fr 2fr' extra={['']}>
            <Link onClickHandler={() => {
              setAddPersonRole(true)
            }}
            >
              <Add color='#28afe0' width={20} height={20} />
            </Link>
          </Card.Header>
          <Overflow>
            <PersonRoleGrid values={personRoles} onChangeHandler={setPersonRoles}>
              {addPersonRole && (
                <AddPersonRoleV2
                  values={personRoles}
                  onChangeHandler={setPersonRoles}
                  isOpen={addPersonRole}
                  onCloseRequest={() => {
                    setAddPersonRole(false)
                  }}
                />
              )}
            </PersonRoleGrid>
          </Overflow>
        </Card.Content>
      </Layout.Section>
      {remove && <DeleteItemV2
        text={`${data?.first_name} ${data?.last_name}`}
        isOpen={remove}
        onCloseRequest={() => {
          setRemove(false)
        }}
        onSubmit={() => {
          deleteMutation({ id })
          setRemove(false)
        }}
                 />}

    </Page>
  )
}

export default CategoryDetails
