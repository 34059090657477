import { Button, styled } from '@pergas-common/pergas-components'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectLocale } from '../redux/locale/selectors'
import { makeSelectorByUserEmail } from '../redux/login/selectors'
import { getGraphToken } from '../redux/login/actions'
import { createTeamGroup, getTeamById } from '../sharepoint/graph'
import AsyncLoader from './AsyncLoader'
import { Teams } from '@pergas-common/pergas-icons'
import { css } from 'styled-components'
import { crudError } from '../redux/error/actions'

const StyledButton = styled(Button)`
  ${({ large }) => large && css`
    width: 150px;
  `}
`

const TeamsButton = ({ groupId }) => {
  const dispatch = useDispatch()
  const locale = useSelector(selectLocale)
  const selectUserEmail = useMemo(makeSelectorByUserEmail, [])
  const userEmail = useSelector((state) => selectUserEmail(state))
  const [isLoading, setIsLoading] = useState(true)
  const [url, setUrl] = useState('')
  useEffect(() => {
    const getTeam = async () => {
      try {
        setIsLoading(true)
        const { webUrl } = await getTeamById(groupId, await getGraphToken({ email: userEmail }))
        setUrl(webUrl)
      } catch (e) {
      } finally {
        setIsLoading(false)
      }
    }
    getTeam()
  }, [groupId])

  const createTeam = useCallback(async (groupId, email) => {
    if (groupId) {
      try {
        setIsLoading(true)
        const token = await getGraphToken({ email })
        const { webUrl } = await createTeamGroup(groupId, token)
        setUrl(webUrl)
      } catch (e) {
        dispatch(crudError(e))
      } finally {
        setIsLoading(false)
      }
    }
  }, [])
  return (

    <StyledButton
      large={!url}
      disabled={isLoading} onClick={() => {
        if (url) {
          window.open(url)
        } else {
          createTeam(groupId, userEmail)
        }
      }} type='button'
    >
      <AsyncLoader loading={isLoading}>
        <Teams width={18} height={18} />
        <span>{url ? locale.teams : locale.create_teams}</span>
      </AsyncLoader>
    </StyledButton>
  )
}

export default TeamsButton
