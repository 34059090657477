import { busy, notBusy } from '../busy/actions.js'
import { handleAddErrors, handleGetErrors, handleUpdateErrors, handleDeleteErrors } from '../error/actions.js'
import { resetFileSearch } from '../files/actions.js'

import api from '../api.js'
import store from '../store.js'
import storage from '../storage.js'

export const DEPARTMENT_CREATE_ACTION = 'DEPARTMENT_CREATE_ACTION'
export const DEPARTMENT_SHOW_ADD_DIALOG_ACTION = 'DEPARTMENT_SHOW_ADD_DIALOG_ACTION'
export const DEPARTMENT_HIDE_ADD_DIALOG_ACTION = 'DEPARTMENT_HIDE_ADD_DIALOG_ACTION'
export const DEPARTMENT_CHANGE_ORDERING_ACTION = 'DEPARTMENT_CHANGE_ORDERING_ACTION'
export const DEPARTMENT_GET_OK_ACTION = 'DEPARTMENT_GET_OK_ACTION'
export const DEPARTMENT_UPDATE_ACTION = 'DEPARTMENT_UPDATE_ACTION'
export const DEPARTMENT_SHOW_EDIT_DIALOG_ACTION = 'DEPARTMENT_SHOW_EDIT_DIALOG_ACTION'
export const DEPARTMENT_HIDE_EDIT_DIALOG_ACTION = 'DEPARTMENT_HIDE_EDIT_DIALOG_ACTION'
export const DEPARTMENT_DELETE_ACTION = 'DEPARTMENT_DELETE_ACTION'
export const DEPARTMENT_SHOW_DELETE_DIALOG_ACTION = 'DEPARTMENT_SHOW_DELETE_DIALOG_ACTION'
export const DEPARTMENT_HIDE_DELETE_DIALOG_ACTION = 'DEPARTMENT_HIDE_DELETE_DIALOG_ACTION'

export const DEPARTMENT_GET_PAGE_ITEMS_ACTION = 'DEPARTMENT_GET_PAGE_ITEMS_ACTION'
export const DEPARTMENT_SET_ORDER_ACTION = 'DEPARTMENT_SET_ORDER_ACTION'
export const DEPARTMENT_SET_LIMIT_ACTION = 'DEPARTMENT_SET_LIMIT_ACTION'
export const DEPARTMENT_SET_OFFSET_ACTION = 'DEPARTMENT_SET_OFFSET_ACTION'
export const DEPARTMENT_SET_SEARCH_ACTION = 'DEPARTMENT_SET_SEARCH_ACTION'
export const DEPARTMENT_RESET_SEARCH_ACTION = 'DEPARTMENT_RESET_SEARCH_ACTION'
export const DEPARTMENT_SET_FILTER_QUERIES_ACTION = 'DEPARTMENT_SET_FILTER_QUERIES_ACTION'
export const DEPARTMENT_SET_SELECTED_ITEM_ID = 'DEPARTMENT_SET_SELECTED_ITEM_ID'

export function departmentCreateAction (department) {
  return {
    type: DEPARTMENT_CREATE_ACTION,
    department
  }
}

export function departmentUpdateAction (department) {
  return {
    type: DEPARTMENT_UPDATE_ACTION,
    department
  }
}

export function departmentDeleteAction (department) {
  return {
    type: DEPARTMENT_DELETE_ACTION,
    department
  }
}

export function setSelectedItemId (selectedItemId) {
  return function (dispatch) {
    dispatch({
      type: DEPARTMENT_SET_SELECTED_ITEM_ID,
      selectedItemId
    })
    dispatch(resetFileSearch('department'))
  }
}

/**
 * Add dialog
 */
export function showAddDepartmentDialog () {
  return {
    type: DEPARTMENT_SHOW_ADD_DIALOG_ACTION
  }
}

export function hideAddDepartmentDialog () {
  return {
    type: DEPARTMENT_HIDE_ADD_DIALOG_ACTION
  }
}

/**
 * Edit dialog
 */
export function showEditDepartmentDialog (department) {
  return {
    type: DEPARTMENT_SHOW_EDIT_DIALOG_ACTION,
    department
  }
}

export function hideEditDepartmentDialog () {
  return {
    type: DEPARTMENT_HIDE_EDIT_DIALOG_ACTION
  }
}

/**
 * Delete dialog
 */
export function showDeleteDepartmentDialog (department) {
  return {
    type: DEPARTMENT_SHOW_DELETE_DIALOG_ACTION,
    department
  }
}

export function hideDeleteDepartmentDialog () {
  return {
    type: DEPARTMENT_HIDE_DELETE_DIALOG_ACTION
  }
}

/**
 * CREATE
 */
export function addDepartment (department) {
  return function (dispatch) {
    dispatch(busy())
    api.addDepartment(department).then(() => {
      dispatch(notBusy())
    }).catch(handleAddErrors('department', dispatch))
  }
}

/**
 * READ
 */
export function getDepartments (params = {}) {
  return function (dispatch) {
    dispatch(busy())
    api.getDepartments(params).then(result => {
      dispatch(notBusy())
      dispatch(receivedDepartments(result.data.value))
    }).catch(handleGetErrors('department', dispatch))
  }
}

export function receivedDepartments (items) {
  return {
    type: DEPARTMENT_GET_OK_ACTION,
    items
  }
}

/**
 * UPDATE
 */
export function updateDepartment (department) {
  return function (dispatch) {
    dispatch(busy())
    api.updateDepartment(department).then(() => {
      dispatch(notBusy())
    }).catch(handleUpdateErrors('department', dispatch))
  }
}

/**
 * DELETE
 */
export function deleteDepartment (department) {
  return function (dispatch) {
    dispatch(busy())
    api.deleteDepartment(department).then(result => {
      dispatch(notBusy())
      dispatch(getPageItems())
    }).catch(handleDeleteErrors('department', dispatch))
  }
}

export function toggleFavorite (department) {
  return function (dispatch) {
    const favorite = !department.is_favorite
    dispatch(busy())
    api.setDepartmentFavorite(department.id, favorite).then(result => {
      dispatch(notBusy())
    }).catch(handleUpdateErrors('department', dispatch))
  }
}

/**
 * Get items for a page
 */
export function getPageItems () {
  const state = store.getState()
  const { search, filterQueries, limit, offset, orderBy, order } = state.department

  let query = []
  if (typeof search === 'string' && search.length > 0) {
    const split = search.split(',').map(s => s.trim()).filter(Boolean)
    if (split.length) {
      query.push({ key: 'name', value: split[0], op: '~' })
    }
  }

  if (Array.isArray(filterQueries) && filterQueries.length > 0) {
    query = [...query, ...filterQueries]
  }

  const sort = orderBy ? `${orderBy}.${order}` : null
  return function (dispatch) {
    getDepartments({
      query,
      limit,
      offset,
      sort
    })(dispatch)
  }
}

/**
 * Sort order
 */
export function setOrder (orderBy, order) {
  return {
    type: DEPARTMENT_SET_ORDER_ACTION,
    orderBy,
    order
  }
}

/**
 * Set limit for pagination
 */
export function setLimit (limit) {
  return {
    type: DEPARTMENT_SET_LIMIT_ACTION,
    limit
  }
}

/**
 * Set offset for pagination
 */
export function setOffset (offset) {
  return {
    type: DEPARTMENT_SET_OFFSET_ACTION,
    offset
  }
}

/**
 * Set search
 */
export function setSearch (search) {
  return {
    type: DEPARTMENT_SET_SEARCH_ACTION,
    search
  }
}

/**
 * Set filter queries
 */
export function setFilterQueries (filterQueries) {
  storage.putPageSetting('department', { filterQueries })
  return {
    type: DEPARTMENT_SET_FILTER_QUERIES_ACTION,
    filterQueries
  }
}

/**
 * Reset search
 */
export function resetSearch () {
  return {
    type: DEPARTMENT_RESET_SEARCH_ACTION
  }
}
