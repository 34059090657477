import React from 'react'
import { spacing, styled } from '@pergas-common/pergas-components'
import * as RadixTooltip from '@radix-ui/react-tooltip'

const TooltipArrow = styled(RadixTooltip.TooltipArrow)`
  fill: ${({ theme }) => theme.palette.brand.secondary};

`

const Content = styled(RadixTooltip.Content)`
  background: ${({ theme }) => theme.palette.brand.secondary};
  ${({ theme }) => theme.typography.breadcrumb};
  padding: ${spacing(1)};
  border-radius: 4px;
`

export default function Tooltip ({ children, content, side = 'bottom' }) {
  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root delayDuration={0}>
        <RadixTooltip.Trigger asChild>
          <div>{children}</div>
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <Content sideOffset={5} side={side}>
            {content}
            <TooltipArrow />
          </Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  )
}
