import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useDispatch, connect, useSelector } from 'react-redux'
import { Link as RLink, useNavigate } from 'react-router-dom'
import { createSelector } from '@reduxjs/toolkit'
import { css } from 'styled-components'
import TablePagination from './TablePagination.jsx'
import { Link, styled, Tag, LinkStyle } from '@pergas-common/pergas-components'
import { Table, Td } from '../../pergas-components/index.js'
import { Add, Contact, Company, Email, Favorite, FavoriteFilled, Phone, ArrowDown, ArrowRight, Teams } from '@pergas-common/pergas-icons'
import { DeleteItemIcon, TemplateManagerIcon } from '../icons.js'
import PageToolbar from './PageToolbar.js'
import PageFooter from './PageFooter.jsx'
import redux from '../../redux'
import storage from '../../redux/storage.js'
import SearchField from '../SearchField.js'
import FilterFavoriteButton from '../filter/FilterFavoriteButton.js'
import DeleteItem from '../dialogs/DeleteItem.js'
import { createDateAccessor, SharePointFolder, TeamsToolIcon } from './columns.js'
import { sortArray, trimNewLineFromString } from '../../util.js'
import { DEFAULT_OBJECT_TYPE_COLOR } from '../style.js'
import { SubRow, SubRowContent } from '../SubRow'
import { selectLocale } from '../../redux/locale/selectors'
import { makeSelectorBySharepointContactsEnabled, makeSelectorByTemplatesEnabled, makeSelectorByEmployeeId } from '../../redux/login/selectors'
import { makeSelectorContact, makeSelectorSelectedItemId, makeSelectorIsSearching } from '../../redux/contact/selectors'
import Filterbar from '../filter/Filterbar.js'
import FilterEmployee from '../filter/FilterEmployee'
import FilterObjectType from '../filter/FilterObjectType.js'
import All from '../filter/FilterAll.js'
import FilterMine from '../filter/FilterMine.js'
import { makeSelectorFilterQueries } from '../../redux/common.js'
import CsvButton from '../CsvButton.js'
import Tooltip from '../Tooltip.js'
import FileView from '../FileView.jsx'

const InternalLink = styled(RLink)`
  ${LinkStyle}
`

const PageHolder = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 100%;
`

const TableHolder = styled.div`
  display: flex;
  overflow-y: scroll;
  flex-grow: 1;
  flex-basis: 50px;

  ${({ $isExpanded }) => $isExpanded && css`
    top: 220px;
  `};
`

const TagHolder = styled.span`
  margin-right: 8px;
`

const Spacer = styled.div`
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
`

const FavoriteHolder = styled.div`
  margin-top: -2px;
`

const CompanyHolder = styled(Company)`
  vertical-align: bottom;
  margin: 0 8px;
`

const Label = styled.span`
  margin-left: 24px
`

const FilterList = () => {
  const dispatch = useDispatch()
  const selectFilterQueries = useMemo(makeSelectorFilterQueries, [])
  const selectMyEmployeeId = useMemo(makeSelectorByEmployeeId, [])

  const locale = useSelector(selectLocale)
  const filterQueries = useSelector((state) => selectFilterQueries(state, 'contact'))
  const myEmployeeId = useSelector((state) => selectMyEmployeeId(state))

  const filterByMine = filterQueries?.find(({ key, value }) => key === 'person_role.person_id' && value === myEmployeeId)
  const filterByAll = filterQueries.filter(({ key }) => key === 'person_role.person_id').length === 0
  const filterByProjectResponsible = filterQueries?.find(({ key, value }) => key === 'person_role.person_id' && value !== myEmployeeId)

  const isMine = filterByMine && !filterByAll
  const isAll = filterByAll && !isMine
  const isOther = filterByProjectResponsible && !isMine && !isAll

  const responsibleFilter = useCallback((id, metadata = {}) => {
    const included = filterQueries.filter(({ key }) => key !== 'person_role.person_id' && key !== 'person_role.role_id')
    if (!id) {
      dispatch(redux.actions.contact.setFilterQueries(included))
    } else {
      dispatch(redux.actions.contact.setFilterQueries([...included, { key: 'person_role.person_id', value: id, op: '=', ...metadata }, { key: 'person_role.role_id', value: 1, op: '=' }]))
    }
  }, [dispatch, filterQueries])

  const objectTypeFilter = useCallback((id, metadata = {}) => {
    const included = filterQueries.filter(({ key }) => {
      return key !== 'object_type_id'
    })
    if (!id) {
      dispatch(redux.actions.contact.setFilterQueries(included))
    } else {
      dispatch(redux.actions.contact.setFilterQueries([...included, { key: 'object_type_id', value: id, op: '=', ...metadata }]))
    }
  }, [dispatch, filterQueries])

  return (
    <Filterbar.Content>
      <Filterbar.Grouped border>
        <legend>{locale.contact_responsible}</legend>
        <Filterbar.Column offset={1}>
          <All applyFilter={responsibleFilter} checked={isAll} />
          <FilterMine applyFilter={responsibleFilter} checked={isMine} />
        </Filterbar.Column>
        <Filterbar.Row offset={0}><FilterEmployee checked={isOther} defaultValue={filterByProjectResponsible} applyFilter={responsibleFilter} /></Filterbar.Row>
      </Filterbar.Grouped>
      <Filterbar.Grouped>
        <Filterbar.Row offset={-1}><FilterObjectType type='contact' applyFilter={objectTypeFilter} rules={filterQueries} /></Filterbar.Row>
      </Filterbar.Grouped>
    </Filterbar.Content>
  )
}

const makeSelectorByCollectionId = () => createSelector(
  (state) => state.contactPerson.normalizedItems,
  (_, collectionId) => collectionId,
  (items, collectionId) => {
    const people = []
    Object.keys(items).forEach((id) => {
      if (items[id].collection_id === collectionId) {
        people.push(items[id])
      }
    })
    return sortArray(people, 'name')
  }
)

const AsyncContactPersonDropdown = ({ row: { original } }) => {
  const { actions } = redux
  const { id } = original
  const dispatch = useDispatch()

  const selectByCollectionId = useMemo(makeSelectorByCollectionId, [])
  const people = useSelector((state) => selectByCollectionId(state, id))
  const locale = useSelector(selectLocale)
  const getContactPersons = useCallback((collectionId) => {
    dispatch(actions.contactPerson.getContactPersons({
      query: [{ key: 'collection_id', op: '=', value: collectionId }]
    }))
  }, [dispatch])

  useEffect(() => {
    getContactPersons(id)
  }, [id])

  if (people.length === 0) {
    return (
      <SubRow
        key={id} subRowChildren={[{
          element: (
            <SubRowContent flex={2}>
              <Spacer>
                <Spacer>
                  <Spacer>
                    <Label>{locale.contact_no_contact_persons}</Label>
                  </Spacer>
                </Spacer>
              </Spacer>
            </SubRowContent>
          ),
          key: 'empty'
        }]} colSpan={6} isFirst isLast
      />
    )
  }

  const renderPeople = people.map((person, index) => {
    const { id, name, object_type_name: objectTypeName, address: { phone, email } } = person
    const isFirst = index === 0
    const isLast = index === people.length - 1

    const subRowChildren = [{
      element: (
        <SubRowContent flex={2}>
          <Spacer>
            <Spacer>
              <Contact width={18} height={18} />
            </Spacer>
          </Spacer>
          <InternalLink to={`/contact-persons/edit?id=${id}`}>{name}</InternalLink>
        </SubRowContent>
      ),
      key: `${id}_name`
    },
    {
      element: (
        <SubRowContent>
          {objectTypeName && objectTypeName}
        </SubRowContent>
      ),
      key: `${id}_object_type_name`
    },
    {
      element: (
        <SubRowContent>
          <Link href={`tel:${phone}`}>{phone}</Link>
        </SubRowContent>
      ),
      key: `${id}_phone`
    },
    {
      element: (
        <SubRowContent flex={2} borderRight={false}>
          <Link href={`mailto:${email}`}>{email}</Link>
        </SubRowContent>
      ),
      key: `${id}_email`
    }
    ]

    return (
      <SubRow key={id} subRowChildren={subRowChildren} colSpan={5} isFirst={isFirst} isLast={isLast}>
        <Td
          left={
            <>
              {!!phone && <Spacer><Link href={`tel:${phone}`}><Phone width={18} height={18} /></Link></Spacer>}
              {!!email && <Spacer><Link href={`mailto:${email}`}><Email width={18} height={18} /></Link></Spacer>}
              {!!email && <Spacer><Link href={`https://teams.microsoft.com/l/call/0/0?users=${email}`}><Teams width={20} height={20} /></Link></Spacer>}

            </>
}
        />
      </SubRow>
    )
  })
  return renderPeople
}

const ContactPage = ({
  locale,
  limit,
  offset,
  orderBy,
  order,
  search,
  filterQueries,
  getPageItems,
  setSelectedItemId,
  setOrder,
  setLimit,
  setOffset,
  setSearch,
  resetSearch,
  fileSearchState,
  setFilterQueries,
  canUpdate,
  canDelete,
  deleteItem,
  onDeleteItemClick,
  onDeleteOk,
  onDeleteCancel,
  canCreate,
  onFavoriteItemClick,
  sharePointSaveEnabled,
  getRootFolder
}) => {
  const navigate = useNavigate()
  const settings = storage.getPageSettings('contact')

  const [expanded, setExpanded] = useState(settings.toolbarExpanded || false)
  function onSetExpanded () {
    storage.putPageSetting('contact', { toolbarExpanded: !expanded })
    setExpanded(!expanded)
  }

  const [showFileView, setShowFileView] = useState(settings.showFileView || false)
  function onToggleFileView () {
    storage.putPageSetting('contact', { showFileView: !showFileView })
    setShowFileView(!showFileView)
  }

  function onSetFileViewHeight (height) {
    storage.putPageSetting('contact', { fileViewHeight: height })
  }

  const selectSelectedItemId = useMemo(makeSelectorSelectedItemId, [])
  const selectedItemId = useSelector((state) => selectSelectedItemId(state))

  const selectItems = useMemo(makeSelectorContact, [])
  const items = useSelector((state) => selectItems(state))

  useEffect(() => {
    getPageItems()
  }, [getPageItems, limit, offset, orderBy, order, search, filterQueries])

  const selectTenantSettings = useMemo(makeSelectorBySharepointContactsEnabled, [])
  const sharepointContactsEnabled = useSelector((state) => selectTenantSettings(state))
  const selectIsSearching = useMemo(makeSelectorIsSearching, [])
  const isSearching = useSelector((state) => selectIsSearching(state))

  const selectorByTemplatesEnabled = useMemo(makeSelectorByTemplatesEnabled, [])
  const templatesEnabled = useSelector((state) => selectorByTemplatesEnabled(state))

  const setPageSizeRef = useRef(() => {})

  const transformedRows = useMemo(() => items.map((r) => {
    const row = {
      [locale.id]: r.id,
      [locale.name]: r.name,
      [locale.description]: trimNewLineFromString(r.description),
      [locale.contact_object_type]: r.object_type_name ?? '',
      [locale.vat_no]: r.vat ?? '',
      [locale.address]: r.address?.address ?? '',
      [locale.email]: r.address?.email ?? '',
      [locale.contact_responsible]: r.person_role?.find((pr) => pr.role_internal_name === 'responsible')?.person_name ?? ''
    }

    return row
  }), [items, locale])

  const manualSort = useCallback(({ id, isSorted, isSortedDesc }) => {
    if (isSorted && !isSortedDesc) {
      setOrder(id, 'desc')
    } else if (isSorted && isSortedDesc) {
      setOrder('', '')
    } else {
      setOrder(id, 'asc')
    }
  }, [setOrder])

  const renderRowSubComponent = useCallback(
    ({ row }) => (
      <AsyncContactPersonDropdown
        row={row}
      />
    ),
    []
  )

  const onExpandArrow = (row) => {
    return (e) => {
      e.stopPropagation()
      row.toggleRowExpanded()
    }
  }

  const selectedRowIds = useMemo(() => {
    const index = items.findIndex(item => item.id === selectedItemId)
    if (index !== -1) {
      return { [String(index)]: true }
    } else {
      return {}
    }
  }, [items, selectedItemId])

  const columns = useMemo(() => {
    return [
      {
        Header: locale.name,
        Cell: ({ cell }) => {
          const { row } = cell
          const { isExpanded, original } = row
          const Arrow = isExpanded ? ArrowDown : ArrowRight
          return (
            <Td
              {...cell.getCellProps()} left={
                <>
                  <Arrow width={12} height={12} style={{ cursor: 'pointer' }} onClick={onExpandArrow(row)} />
                  <CompanyHolder width={18} height={18} />
                  {!!original.name && <InternalLink to={`/contacts/edit?id=${original.id}`}>{original.name}</InternalLink>}
                </>
}
            />
          )
        },
        canSort: true,
        id: 'name',
        size: 'md',
        manualSort,
        sortType: () => {}
      },
      {
        id: 'person_role',
        Header: locale.contact_responsible,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          const person = original.person_role && original.person_role.length > 0 && original.person_role.find((role) => role.role_internal_name === 'responsible')
          return (<Td {...cell.getCellProps()} left={person && person.person_name} />)
        },
        size: 'md',
        isSortable: false
      },
      {
        id: 'object_type_name',
        Header: locale.contact_object_type,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return <Td {...cell.getCellProps()} left={original.object_type_name && <Tag color={original.object_type_color || DEFAULT_OBJECT_TYPE_COLOR} textColor={original.object_type_color ? '#FFFFFF' : '#3a4a54'} border='#969696'>{original.object_type_name}</Tag>} />
        },
        canSort: true,
        sortType: () => {},
        manualSort
      },
      {
        id: 'tags_string',
        Header: locale.tags,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          const tags = original.tags && original.tags.length > 0 && sortArray(original.tags, 'name').slice(0, 2).map(({ color, id, name }) => {
            return <TagHolder key={id}><Tag textColor='#FFFFFF' color={color}>{name}</Tag></TagHolder>
          })
          return (<Td {...cell.getCellProps()} left={tags && tags} />)
        },
        canSort: true,
        sortType: () => {},
        manualSort
      },
      {
        id: 'updated_at',
        Header: locale.updated_at,
        manualSort,
        accessor: createDateAccessor('updated_at'),
        sortType: () => {}
      },
      {
        id: 'toolbar',
        Header: locale.tool_belt,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          const stopPropagation = (e) => e.stopPropagation()
          return (
            <Td
              {...cell.getCellProps()}
              left={
                <>
                  {sharePointSaveEnabled && sharepointContactsEnabled && SharePointFolder(original)}
                  {sharePointSaveEnabled && sharepointContactsEnabled && <TeamsToolIcon row={original} />}
                  {templatesEnabled && <Tooltip content={locale.template_manager}><Link href={`${process.env.REACT_APP_TM_URL}?pds_id=${original.id}&pds_type=contact`}> <TemplateManagerIcon width={18} height={18} /></Link></Tooltip>}
                  <Tooltip content={locale.favorite}>
                    <Link
                      onClickHandler={(e) => {
                        stopPropagation(e)
                        e.preventDefault()
                        onFavoriteItemClick(original)
                      }}
                    >
                      <FavoriteHolder>{original.is_favorite ? <FavoriteFilled color='#ca6eb6' width={18} height={18} /> : <Favorite color='#ca6eb6' width={18} height={18} />}</FavoriteHolder>
                    </Link>
                  </Tooltip>
                </>
              }
              right={canDelete && <Tooltip side='left' content={locale.delete}><Link onClickHandler={(e) => { stopPropagation(e); onDeleteItemClick(original) }}><DeleteItemIcon /></Link></Tooltip>}
            />
          )
        },
        size: 'md',
        isSortable: false
      }
    ]
  }, [locale, canUpdate, canDelete, sharepointContactsEnabled, templatesEnabled, sharePointSaveEnabled, manualSort, onDeleteItemClick, onFavoriteItemClick, selectedRowIds])

  let initialSortBy = []
  if (orderBy) {
    initialSortBy = [{
      id: orderBy,
      desc: order === 'desc'
    }]
  }

  const pagination = (
    <TablePagination
      limit={limit}
      offset={offset}
      onRowsPerPageChange={(e) => {
        setLimit(e.target.value)
        setPageSizeRef.current(e.target.value)
      }}
      onPageChange={(e, number) => setOffset(number * limit)}
    />
  )

  const selectedItem = items.find(i => i.id === selectedItemId)

  return (
    <PageHolder>
      {deleteItem && <DeleteItem text={deleteItem.name || deleteItem.first_name} onOk={() => { onDeleteOk(deleteItem) }} onCancel={onDeleteCancel} />}
      <PageToolbar
        expanded={expanded} setExpanded={onSetExpanded} center={<SearchField resetSearch={resetSearch} isSearching={isSearching} onChange={setSearch} value={search} />}left={
          <>
            <Company width={20} height={20} />
            <span>{locale.contacts}</span>
            {canCreate && <Link onClickHandler={() => { navigate('/contacts/new') }}><Add width={20} height={20} color='#447fb1' /></Link>}
            <CsvButton
              download={locale.contact}
              keys={[
                locale.id,
                locale.name,
                locale.description,
                locale.contact_object_type,
                locale.vat_no,
                locale.address,
                locale.email,
                locale.contact_responsible
              ]} data={transformedRows}
            />
            <FilterFavoriteButton filterQueries={filterQueries} onFilterChange={setFilterQueries} />
          </>
}
      >
        <FilterList />
      </PageToolbar>

      <TableHolder $isExpanded={expanded}>
        <Table
          columns={columns}
          data={items}
          initialPageSize={limit}
          initialSortBy={initialSortBy}
          renderRowSubComponent={renderRowSubComponent}
          selectedRowIds={selectedRowIds}
          onRowClick={(e, row, toggleAllPageRowsSelected) => {
            getRootFolder(row.original)
            setSelectedItemId(row.original.id)
            toggleAllPageRowsSelected(false)
            row.toggleRowSelected(true)
          }}
        >
          {({ setPageSize }) => {
            // Store table callback in a ref so we can call it from elsewhere
            setPageSizeRef.current = setPageSize
          }}
        </Table>
      </TableHolder>
      {sharePointSaveEnabled && <FileView siteItem={selectedItem} right={pagination} onShowToggle={onToggleFileView} isOpen={showFileView} initialHeight={settings.fileViewHeight} onSetHeight={onSetFileViewHeight} searchState={fileSearchState.contact} />}
      {!sharePointSaveEnabled && <PageFooter>{pagination}</PageFooter>}
    </PageHolder>
  )
}

const mapStateToProps = (state) => {
  const { locale, contact, files } = state
  const permissions = state.login.permissions
  const {
    limit,
    offset,
    orderBy,
    order,
    search,
    filterQueries
  } = contact
  return {
    locale: locale.strings,
    limit,
    offset,
    orderBy,
    order,
    search,
    filterQueries,
    canUpdate: permissions.contact.canUpdate,
    canDelete: permissions.contact.canDelete,
    canCreate: permissions.contact.canCreate,
    deleteItem: state.contact.deleteItem,
    sharePointSaveEnabled: state.login.userData.sharePointSaveEnabled,
    fileSearchState: files.search
  }
}

const mapDispatchToProps = (dispatch) => {
  const { actions: { contact, file } } = redux
  return {
    onFavoriteItemClick: (c) => {
      dispatch(contact.toggleFavorite(c))
    },
    onDeleteItemClick: (c) => {
      dispatch(contact.showDeleteContactDialog(c))
    },
    onDeleteOk: (c) => {
      dispatch(contact.hideDeleteContactDialog())
      dispatch(contact.deleteContact(c))
    },
    onDeleteCancel: () => {
      dispatch(contact.hideDeleteContactDialog())
    },
    getPageItems: () => {
      dispatch(contact.getPageItems())
    },
    setSelectedItemId: (id) => {
      dispatch(contact.setSelectedItemId(id))
    },
    setOrder: (orderBy, order) => {
      dispatch(contact.setOrder(orderBy, order))
    },
    setLimit: (limit) => {
      dispatch(contact.setLimit(limit))
    },
    setOffset: (offset) => {
      dispatch(contact.setOffset(offset))
    },
    setSearch: (search) => {
      dispatch(contact.setSearch(search))
    },
    resetSearch: () => {
      dispatch(contact.resetSearch())
    },
    setFilterQueries: (filterQueries) => {
      dispatch(contact.setFilterQueries(filterQueries))
    },
    getRootFolder: (item) => {
      dispatch(file.getRootFolder(item))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactPage)
