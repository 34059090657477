import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { createReducers } from './create-reducers'
import resources from '../resources'

export default configureStore({
  reducer: createReducers(resources),
  middleware: [...getDefaultMiddleware({
    serializableCheck: false // temporary disable check to supress console spam.
  })]
})
