import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectLocale } from '../redux/locale/selectors'
import useUrlQuery from '../hooks/useUrlQuery'
import { useNavigate } from 'react-router-dom'
import { Card, Waves, styled } from '@pergas-common/pergas-components'
import { DeleteItemV2 } from './dialogs/DeleteItem'
import SearchInput from './SearchField'
import useDebounce from '../hooks/useDebounce'
import { makeSelectorTagFiltered } from '../redux/tag/selectors'
import { useDeleteTagMutation, useGetTagQuery } from '../redux/tag/hooks'
import TypeItems from './TypeItems'

const Holder = styled.div`
  max-width: 300px;
`
const SearchHolder = styled.div`
  padding: 0 8px;
`
const Overflow = styled.div`
  overflow-y: hidden;
  height: 500px;
`

const List = styled.div`
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 5px;
    background: none;
  }
  &::-webkit-scrollbar-thumb {
    width: 15px;
    background: ${({ theme }) => theme.palette.brand.secondary};
  }
  > div {
    flex: 1 0 100%;
    display: flex;
    padding: 8px 16px;
    
    align-items: flex-end;
    justify-content: space-between;
    > button {
      vertical-align: top;
    }
    &:hover {
      background: #dee4ea;
      cursor: pointer;
    }
  }
  > div > span {
    max-width: 68px;
  }
`
const TagTypesList = () => {
  const query = useUrlQuery()
  const id = query.get('id')
  const navigate = useNavigate()
  const selectByTagType = useMemo(makeSelectorTagFiltered)
  const locale = useSelector(selectLocale)
  const [searchQuery, setSearchQuery] = useState('')
  const [remove, setRemove] = useState(null)
  const data = useSelector((state) => selectByTagType(state, searchQuery))
  const { isLoading, refetch } = useGetTagQuery({ sort: 'name.asc' })

  const { mutation: deleteMutation } = useDeleteTagMutation()
  const [debouncedRequest] = useDebounce(refetch, 300)

  return (
    <>
      <Holder>
        <SearchHolder>
          <SearchInput
            value={searchQuery}
            onChange={(value) => {
              debouncedRequest({ query: [{ key: 'name', op: '~', value }], sort: 'name.asc' })
              setSearchQuery(value)
            }}
            resetSearch={(v) => {
              if (v === null) {
                refetch({ sort: 'name.asc' })
                setSearchQuery('')
              }
            }}
          />
        </SearchHolder>
        <Card.Content>
          <Card.Header title={locale.tags} columns='1fr' />
          <Overflow>
            <List>
              <>
                {isLoading && <Waves width={24} height={24} />}
                <TypeItems types={data} disabled={false} selected={id} onSelect={(tagType) => { navigate(`/tags/edit?id=${tagType.id}`) }} />
              </>
            </List>
          </Overflow>
        </Card.Content>
      </Holder>
      {remove && <DeleteItemV2
        text={remove.name}
        isOpen={!!remove}
        onCloseRequest={() => {
          setRemove(false)
        }}
        onSubmit={() => {
          setRemove(null)
          deleteMutation({ id: remove.id })
        }}
                 />}

    </>
  )
}

export default TagTypesList
