import { busy, notBusy } from '../busy/actions.js'
import { handleGetErrors } from '../error/actions.js'

import api from '../api.js'

export const TENANT_GET_OK_ACTION = 'TENANT_GET_OK_ACTION'

/**
 * READ
 */
export function getTenants () {
  return function (dispatch) {
    dispatch(busy())
    api.getTenants().then(result => {
      dispatch(notBusy())
      dispatch(receivedTenants(result.data.value))
    }).catch(handleGetErrors('teenant', dispatch))
  }
}

function receivedTenants (items) {
  return {
    type: TENANT_GET_OK_ACTION,
    items
  }
}
