import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { styled, media, Button, Group } from '@pergas-common/pergas-components'
import { Email, Favorite, FavoriteFilled, Folder, Remove, Sharepoint } from '@pergas-common/pergas-icons'
import { useSelector } from 'react-redux'
import { selectLocale } from '../../../redux/locale/selectors'
import { makeSelectorByIsFavorite, makeSelectorBySharepointStatus, makeSelectorBySharepointUrl } from '../../../redux/contact/selectors'
import { useDeleteContactMutation, useFavoriteContactMutation } from '../../../redux/contact/hooks'
import { makeSelectorBySharepointContactsEnabled } from '../../../redux/login/selectors'
import useUrlQuery from '../../../hooks/useUrlQuery'
import { DeleteItemV2 } from '../../dialogs/DeleteItem'
import AsyncLoader from '../../AsyncLoader'
import TeamsButton from '../../TeamsButton'

const Holder = styled.div`
  display: flex;
  max-width: 850px;
  align-items: stretch;
  flex-direction: column;
  > div:nth-child(1) {
    width: 100%;
    max-width: 320px;
    flex: 0 0 32px;  
    ${media.medium`
      flex: 1;
      max-width: none;
    `}
  }
  > div:nth-child(2) {
    flex: 0 0 32px;
    width: 100%;
    max-width: 320px;
    padding: 8px 16px;
    ${media.medium`
      flex: 1;
      max-width: none;
      text-align: right;
    `}
  }
  ${media.medium`
    flex-direction: row;
  `}
`

export default ({ groupId, values, children }) => {
  const query = useUrlQuery()
  const id = query.get('id')

  const navigate = useNavigate()
  const [remove, setRemove] = useState(false)
  const locale = useSelector(selectLocale)
  const { mutation: favoriteMutation, isLoading: isFavoriteLoading } = useFavoriteContactMutation()
  const { mutation: deleteMutation, isLoading: isDeleting, isSuccessful } = useDeleteContactMutation()

  const selectByFavorite = useMemo(makeSelectorByIsFavorite, [])
  const selectorBySharepointStatus = useMemo(makeSelectorBySharepointStatus, [])
  const selectorBySharepointUrl = useMemo(makeSelectorBySharepointUrl, [])
  const selectorBySharepointContactsEnabled = useMemo(makeSelectorBySharepointContactsEnabled, [])

  const sharepointContactsEnabled = useSelector((state) => selectorBySharepointContactsEnabled(state))
  const sharepointStatus = useSelector((state) => selectorBySharepointStatus(state, id))
  const sharepointUrl = useSelector((state) => selectorBySharepointUrl(state, id))
  const isFavorite = useSelector((state) => selectByFavorite(state, id))

  useEffect(() => {
    if (isSuccessful) {
      navigate('/contacts', { replace: true })
    }
  }, [navigate, isSuccessful])

  const disableSharepoint = !sharepointUrl || sharepointStatus !== 'finished' || !sharepointContactsEnabled
  const updatingSharepoint = sharepointStatus === 'creating' || sharepointStatus === 'updating'

  const sharepointSiteAsyncLoader = <AsyncLoader loading={updatingSharepoint}><Sharepoint width={18} height={18} /></AsyncLoader>
  const sharepointDocumentAsyncLoader = <AsyncLoader loading={updatingSharepoint}><Folder width={20} height={20} /></AsyncLoader>
  const favoriteAsyncLoader = <AsyncLoader loading={isFavoriteLoading}>{isFavorite ? <FavoriteFilled width={18} height={18} color='#34b3e1' /> : <Favorite width={18} height={18} color='#34b3e1' />}</AsyncLoader>
  const deleteAsyncLoader = <AsyncLoader loading={isDeleting}><Remove color='#b13a22' width={20} height={20} /></AsyncLoader>
  const deleteButton = <Button type='button' disabled={!id} onClick={() => { setRemove(true) }}><span>{locale.delete}</span>{deleteAsyncLoader}</Button>
  const shareButton = (
    <Button
      type='button' disabled={!id} onClick={() => {
        window.location = `mailto:?subject=${values.name}&body=${window.location.href}`
      }}
    ><span>{locale.share}</span><Email color='#34b3e1' width={20} height={20} />
    </Button>
  )

  const onSharepointClick = (url) => {
    if (!url) {
      return
    }
    window.open(url)
  }

  const toggleFavorite = <Button type='button' disabled={isFavoriteLoading} onClick={(e) => { favoriteMutation({ id, is_favorite: isFavorite }) }}><span>{locale.favorite}</span>{favoriteAsyncLoader}</Button>
  const sharepointStartPageButton = <Button type='button' disabled={disableSharepoint} onClick={() => { onSharepointClick(sharepointUrl) }}><span>{locale.startpage}</span>{sharepointSiteAsyncLoader}</Button>
  const sharepointDocumentPageButton = <Button type='button' disabled={disableSharepoint} onClick={() => { onSharepointClick(`${sharepointUrl}/files/Forms/AllItems.aspx`) }}><span>{locale.documents}</span>{sharepointDocumentAsyncLoader}</Button>

  return (
    <Holder>
      <div>
        {remove && <DeleteItemV2
          text={values.name}
          isOpen={remove}
          onCloseRequest={() => {
            setRemove(false)
          }}
          onSubmit={() => {
            deleteMutation({ id })
            setRemove(false)
          }}
                   />}
        <Group.Button>
          {sharepointStartPageButton}
          {shareButton}
          {deleteButton}
        </Group.Button>
        <Group.Button>
          {sharepointDocumentPageButton}
          {children}
          {!disableSharepoint && (
            <TeamsButton groupId={groupId} />
          )}
        </Group.Button>
      </div>
      <div>
        {toggleFavorite}
      </div>
    </Holder>
  )
}
