import React, { useState } from 'react'
import { Card, Link, media, styled } from '@pergas-common/pergas-components'
import { Add } from '@pergas-common/pergas-icons'
import { useSelector } from 'react-redux'
import { selectLocale } from '../../redux/locale/selectors'
import { AddTagV2 } from '../dialogs/AddTag'
import { TagList } from '../Tags'

const Holder = styled.div`
  width: 100%;
  > div {
    padding: 0px 6px 0 0;
  }
  ${media.medium`
    width: 92%;
  `}
  height: 268px;
`

const Overflow = styled.div`
  overflow-y: hidden;
  height: 218px;
`

/*
  TODO: replace current tag lists with this component.
*/
const TagListCard = ({ tags, tagType, onChangeHandler }) => {
  const [isOpen, setIsOpen] = useState(false)
  const locale = useSelector(selectLocale)
  return (
    <Holder>
      <Card.Content>
        <Card.Header title={locale.tags}>
          <Link
            onClickHandler={() => {
              setIsOpen(true)
            }}
          >
            <Add color='#28afe0' width={20} height={20} />
          </Link>
        </Card.Header>
        <Overflow>
          <TagList
            name='tags' tags={tags} handleChange={(_, newTags) => {
              onChangeHandler(newTags)
            }}
          />
        </Overflow>
      </Card.Content>
      {isOpen && <AddTagV2
        tagType={tagType}
        isOpen={isOpen}
        onCloseRequest={() => {
          setIsOpen(false)
        }}
        onSelect={(tag) => {
          onChangeHandler((prev) => {
            const unique = prev.filter((prevTag) => prevTag.tag_id !== tag.id)
            return [...unique, tag]
          })
        }}
                 />}
    </Holder>
  )
}

export default TagListCard
