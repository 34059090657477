import { busy, notBusy } from '../busy/actions.js'
import { handleAddErrors, handleGetErrors, handleUpdateErrors, handleDeleteErrors } from '../error/actions.js'
import { resetFileSearch } from '../files/actions.js'

import api from '../api.js'
import store from '../store.js'
import storage from '../storage.js'

export const PROJECT_CREATE_ACTION = 'PROJECT_CREATE_ACTION'
export const PROJECT_SHOW_ADD_DIALOG_ACTION = 'PROJECT_SHOW_ADD_DIALOG_ACTION'
export const PROJECT_HIDE_ADD_DIALOG_ACTION = 'PROJECT_HIDE_ADD_DIALOG_ACTION'
export const PROJECT_GET_OK_ACTION = 'PROJECT_GET_OK_ACTION'
export const PROJECT_UPDATE_ACTION = 'PROJECT_UPDATE_ACTION'
export const PROJECT_SHOW_EDIT_DIALOG_ACTION = 'PROJECT_SHOW_EDIT_DIALOG_ACTION'
export const PROJECT_HIDE_EDIT_DIALOG_ACTION = 'PROJECT_HIDE_EDIT_DIALOG_ACTION'
export const PROJECT_DELETE_ACTION = 'PROJECT_DELETE_ACTION'
export const PROJECT_SHOW_DELETE_DIALOG_ACTION = 'PROJECT_SHOW_DELETE_DIALOG_ACTION'
export const PROJECT_HIDE_DELETE_DIALOG_ACTION = 'PROJECT_HIDE_DELETE_DIALOG_ACTION'

export const PROJECT_GET_PAGE_ITEMS_ACTION = 'PROJECT_GET_PAGE_ITEMS_ACTION'
export const PROJECT_SET_ORDER_ACTION = 'PROJECT_SET_ORDER_ACTION'
export const PROJECT_SET_LIMIT_ACTION = 'PROJECT_SET_LIMIT_ACTION'
export const PROJECT_SET_OFFSET_ACTION = 'PROJECT_SET_OFFSET_ACTION'
export const PROJECT_SET_SEARCH_ACTION = 'PROJECT_SET_SEARCH_ACTION'
export const PROJECT_RESET_SEARCH_ACTION = 'PROJECT_RESET_SEARCH_ACTION'
export const PROJECT_SET_FILTER_QUERIES_ACTION = 'PROJECT_SET_FILTER_QUERIES_ACTION'
export const PROJECT_SET_SELECTED_ITEM_ID = 'PROJECT_SET_SELECTED_ITEM_ID'

export function projectCreateAction (project) {
  return {
    type: PROJECT_CREATE_ACTION,
    project
  }
}

export function projectUpdateAction (project) {
  return {
    type: PROJECT_UPDATE_ACTION,
    project
  }
}

export function projectDeleteAction (project) {
  return {
    type: PROJECT_DELETE_ACTION,
    project
  }
}

export function setSelectedItemId (selectedItemId) {
  return function (dispatch) {
    dispatch({
      type: PROJECT_SET_SELECTED_ITEM_ID,
      selectedItemId
    })
    dispatch(resetFileSearch('project'))
  }
}

/**
 * Add dialog
 */
export function showAddProjectDialog () {
  return {
    type: PROJECT_SHOW_ADD_DIALOG_ACTION
  }
}

export function hideAddProjectDialog () {
  return {
    type: PROJECT_HIDE_ADD_DIALOG_ACTION
  }
}

/**
 * Edit dialog
 */
export function showEditProjectDialog (project) {
  return {
    type: PROJECT_SHOW_EDIT_DIALOG_ACTION,
    project
  }
}

export function hideEditProjectDialog () {
  return {
    type: PROJECT_HIDE_EDIT_DIALOG_ACTION
  }
}

/**
 * Delete dialog
 */
export function showDeleteProjectDialog (project) {
  return {
    type: PROJECT_SHOW_DELETE_DIALOG_ACTION,
    project
  }
}

export function hideDeleteProjectDialog () {
  return {
    type: PROJECT_HIDE_DELETE_DIALOG_ACTION
  }
}

/**
 * CREATE
 */
export function addProject (project) {
  return function (dispatch) {
    dispatch(busy())
    api.addProject(project).then(result => {
      dispatch(notBusy())
    }).catch(handleAddErrors('project', dispatch))
  }
}

/**
 * READ
 */
export function getProjects (params = {}) {
  return function (dispatch) {
    dispatch(busy())
    api.getProjects(params).then(result => {
      dispatch(notBusy())
      dispatch(receivedProjects(result.data.value))
    }).catch(handleGetErrors('project', dispatch))
  }
}

export function receivedProjects (items) {
  return {
    type: PROJECT_GET_OK_ACTION,
    items
  }
}

/**
 * UPDATE
 */
export function updateProject (project) {
  return function (dispatch) {
    dispatch(busy())
    api.updateProject(project).then(() => {
      dispatch(notBusy())
    }).catch(handleUpdateErrors('project', dispatch))
  }
}

/**
 * DELETE
 */
export function deleteProject (project) {
  return function (dispatch) {
    dispatch(busy())
    api.deleteProject(project).then(() => {
      dispatch(notBusy())
      dispatch(getPageItems())
    }).catch(handleDeleteErrors('project', dispatch))
  }
}

export function toggleFavorite (project) {
  return function (dispatch) {
    const favorite = !project.is_favorite
    dispatch(busy())
    api.setProjectFavorite(project.id, favorite).then(() => {
      dispatch(notBusy())
    }).catch(handleUpdateErrors('project', dispatch))
  }
}

/**
 * Get items for a page
 */
export function getPageItems () {
  const state = store.getState()
  const { search, filterQueries, limit, offset, orderBy, order } = state.project

  let query = []
  if (typeof search === 'string' && search.length > 0) {
    const split = search.split(',').map(s => s.trim())
    if (split.length === 1) {
      if (split[0].length > 0) query.push({ key: 'name', value: split[0], op: '~' })
    } else if (split.length === 2) {
      if (split[0].length > 0) query.push({ key: 'name', value: split[0], op: '~' })
      if (split[1].length > 0) query.push({ key: 'collection_name', value: split[1], op: '~' })
    }
  }

  if (Array.isArray(filterQueries) && filterQueries.length > 0) {
    query = [...query, ...filterQueries]
  }

  const sort = orderBy ? `${orderBy}.${order}` : null

  return function (dispatch) {
    getProjects({
      query,
      limit,
      offset,
      sort
    })(dispatch)
  }
}

/**
 * Sort order
 */
export function setOrder (orderBy, order) {
  return {
    type: PROJECT_SET_ORDER_ACTION,
    orderBy,
    order
  }
}

/**
 * Set limit for pagination
 */
export function setLimit (limit) {
  return {
    type: PROJECT_SET_LIMIT_ACTION,
    limit
  }
}

/**
 * Set offset for pagination
 */
export function setOffset (offset) {
  return {
    type: PROJECT_SET_OFFSET_ACTION,
    offset
  }
}

/**
 * Set search
 */
export function setSearch (search) {
  return {
    type: PROJECT_SET_SEARCH_ACTION,
    search
  }
}

/**
 * Set filter queries
 */
export function setFilterQueries (filterQueries) {
  storage.putPageSetting('project', { filterQueries })
  return {
    type: PROJECT_SET_FILTER_QUERIES_ACTION,
    filterQueries
  }
}

/**
 * Reset search
 */
export function resetSearch () {
  return {
    type: PROJECT_RESET_SEARCH_ACTION
  }
}
