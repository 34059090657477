import React, { useEffect, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'
import dayjs from 'dayjs'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  styled,
  Button,
  Link,
  Waves,
  Layout
} from '@pergas-common/pergas-components'
import {
  Add,
  Company
} from '@pergas-common/pergas-icons'
import { Save } from '@mui/icons-material'
import { selectLocale } from '../../../redux/locale/selectors'
import { makeSelectorPermissionTicket } from '../../../redux/permission/selectors'
import { useAddTicketMutation, useUpdateTicketMutation, useGetTicketByIdQuery } from '../../../redux/ticket/hooks'
import { usePermissionRoute } from '../../../hooks/usePermission'
import useUrlQuery from '../../../hooks/useUrlQuery'
import TopbarNavigation from '../../TopbarNavigation'
import AsyncLoader from '../../AsyncLoader'
import TicketForm from './form'
import Buttons from './buttons'

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const validate = (values) => {
  const errors = {}
  if (!values.name) {
    errors.name = 'Required'
  }
  if (!values.project_id) {
    errors.project_id = 'Required'
  }

  return errors
}

const useInitialValues = (ticket = {}) => {
  const { state } = useLocation()
  const {
    name = '',
    description = '',
    project_id = state?.project?.id || state?.ticket?.project_id || 0,
    project_name = state?.project?.name || state?.ticket?.project_name || '',
    started_at = dayjs(),
    expires_at = dayjs().add(1, 'day'),
    ended_at = null,
    estimate_value = null,
    person_role = state?.ticket?.person_role || [],
    tags = state?.ticket?.tags || []
  } = ticket
  return {
    name,
    description: description ?? '',
    project_id,
    project_name,
    started_at: dayjs(started_at).format('YYYY-MM-DD'),
    expires_at: dayjs(expires_at).format('YYYY-MM-DD'),
    ended_at,
    estimate_value,
    person_role,
    tags
  }
}

const New = () => {
  const navigate = useNavigate()
  const locale = useSelector(selectLocale)
  const selectByPermissionTicket = useMemo(makeSelectorPermissionTicket, [])
  const permissions = useSelector(selectByPermissionTicket)
  const formData = useInitialValues()
  const { metadata, mutation, isLoading, isSuccessful } = useAddTicketMutation()
  const onSubmit = useCallback(async (values) => {
    mutation(values)
  }, [mutation])

  usePermissionRoute(permissions, ['canCreate'])

  useEffect(() => {
    if (!isLoading && isSuccessful) {
      navigate(`/tickets/edit?id=${metadata?.id}`, { replace: true })
    }
  }, [metadata, isSuccessful])

  if (isLoading) {
    return (
      <Layout.Main>
        <Layout.Aside>
          <Center>
            <Waves width={40} height={40} />
          </Center>
        </Layout.Aside>
      </Layout.Main>
    )
  }

  return (
    <Formik initialValues={formData} onSubmit={onSubmit} validate={validate}>
      {(props) => {
        const { errors, isSubmitting, dirty } = props
        const disableSubmit = (Object.values(errors).length === 0 && !isSubmitting && dirty && !isLoading)
        const submitAsyncLoader = <AsyncLoader loading={isSubmitting || isLoading}><Save style={{ color: '#34b3e1', width: 20, height: 20 }} /></AsyncLoader>
        return (
          <TopbarNavigation title={locale.task} icon={<Company width={20} height={20} />}>
            <TicketForm disable={false} {...props}>
              <Buttons {...props}>
                <Button disabled={!disableSubmit} type='submit' form='submitTicket'><span>{locale.save}</span>{submitAsyncLoader}</Button>
              </Buttons>
            </TicketForm>
          </TopbarNavigation>
        )
      }}
    </Formik>
  )
}

const View = () => {
  const navigate = useNavigate()
  const query = useUrlQuery()
  const id = query.get('id')

  const selectByPermissionTicket = useMemo(makeSelectorPermissionTicket, [])
  const permissions = useSelector(selectByPermissionTicket)
  const locale = useSelector(selectLocale)
  const { data, refetch, isLoading } = useGetTicketByIdQuery(id)

  const { mutation: updateMutation, isLoading: isMutating, isSuccessful: isSuccessfulUpdate } = useUpdateTicketMutation()
  const formData = useInitialValues(data)
  usePermissionRoute(permissions, ['canUpdate'])

  const onSubmit = useCallback(async (values) => {
    updateMutation({ id, ...values })
  }, [updateMutation, id])

  useEffect(() => {
    if (isSuccessfulUpdate) {
      refetch()
    }
  }, [isSuccessfulUpdate, refetch])

  if (isLoading) {
    return (
      <Layout.Main>
        <Layout.Aside>
          <Center>
            <Waves width={40} height={40} />
          </Center>
        </Layout.Aside>
      </Layout.Main>
    )
  }

  const createLink = permissions.canCreate && <Link onClickHandler={() => { navigate('/tickets/new') }}><Add width={20} height={20} color='#28afe0' /></Link>
  return (
    <Formik initialValues={formData} onSubmit={onSubmit} validate={validate}>
      {(props) => {
        const { errors, isSubmitting, dirty } = props
        const disableSubmit = Object.values(errors).length > 0 || isSubmitting || !dirty || isMutating
        const submitAsyncLoader = <AsyncLoader loading={isSubmitting || isMutating}><Save style={{ color: '#34b3e1', width: 20, height: 20 }} /></AsyncLoader>
        return (
          <TopbarNavigation title={locale.task} icon={<Company width={20} height={20} />} link={createLink}>
            <TicketForm disable={false} {...props}>
              <Buttons {...props}>
                <Button disabled={disableSubmit} type='submit' form='submitTicket'><span>{locale.save}</span>{submitAsyncLoader}</Button>
              </Buttons>
            </TicketForm>
          </TopbarNavigation>
        )
      }}
    </Formik>
  )
}

export default { View, New }
