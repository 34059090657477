import React, { useEffect, useCallback, useMemo, useRef } from 'react'
import { connect } from 'react-redux'
import { Link as RLink, useNavigate } from 'react-router-dom'
import PageToolbar from './PageToolbar.js'
import redux from '../../redux/index.js'
import SearchField from '../SearchField.js'
import DeleteItem from '../dialogs/DeleteItem.js'
import TablePagination from './TablePagination.jsx'
import PageFooter from './PageFooter.jsx'
import { Link, styled, LinkStyle } from '@pergas-common/pergas-components'
import { Table, Td } from '../../pergas-components/index.js'
import { Add } from '@pergas-common/pergas-icons'
import { createDateAccessor } from './columns.js'
import EditRole from '../dialogs/EditRole.js'
import { DeleteItemIcon, RoleIcon } from '../icons.js'
import Tooltip from '../Tooltip.js'

const PageHolder = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 100%;
`

const TableHolder = styled.div`
  display: flex;
  overflow-y: scroll;
  flex-grow: 1;
  flex-basis: 50px;
`

const NameHolder = styled.div`
  display: inline-block;
  margin: 0 8px;
`

const InternalLink = styled(RLink)`
  ${LinkStyle}
`

const RolePage = ({
  locale,
  rows,
  limit,
  offset,
  orderBy,
  order,
  search,
  resetSearch,
  getPageItems,
  setOrder,
  setLimit,
  setOffset,
  setSearch,
  canUpdate,
  canDelete,
  deleteItem,
  onDeleteItemClick,
  onDeleteOk,
  onDeleteCancel,
  editItem,
  onEditItemClick,
  onEditOk,
  onEditCancel,
  canCreate,
  onShowAddDialog
}) => {
  const navigate = useNavigate()
  useEffect(getPageItems, [limit, offset, orderBy, order, search])

  const setPageSizeRef = useRef(() => {})

  const manualSort = useCallback(({ id, isSorted, isSortedDesc }) => {
    if (isSorted && !isSortedDesc) {
      setOrder(id, 'desc')
    } else if (isSorted && isSortedDesc) {
      setOrder('', '')
    } else {
      setOrder(id, 'asc')
    }
  }, [setOrder])

  const columns = useMemo(() => {
    return [
      {
        Header: locale.name,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return (
            <Td
              {...cell.getCellProps()} left={
                <>
                  <RoleIcon style={{ width: 16, height: 16 }} />
                  <NameHolder>
                    {!!original.name && (canUpdate ? <InternalLink to={`/roles/edit?id=${original.id}`}>{original.name}</InternalLink> : <span>{original.name}</span>)}
                  </NameHolder>
                </>
            }
            />
          )
        },
        canSort: true,
        id: 'name',
        size: 'md',
        manualSort,
        sortType: () => {}
      },
      {
        Header: locale.description,
        manualSort,
        accessor: 'description',
        sortType: () => {}
      },
      {
        id: 'updated_at',
        Header: locale.updated_at,
        manualSort,
        accessor: createDateAccessor('updated_at'),
        sortType: () => {}
      },
      {
        id: 'toolbar',
        Header: locale.tool_belt,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return (
            <Td
              {...cell.getCellProps()}
              left={<></>}
              right={canDelete && !original.read_only && <Tooltip side='left' content={locale.delete}><Link onClickHandler={() => { onDeleteItemClick(original) }}><DeleteItemIcon /></Link></Tooltip>}
            />
          )
        },
        size: 'sm',
        isSortable: false
      }
    ]
  }, [locale, canUpdate, canDelete, manualSort, onDeleteItemClick, onEditItemClick])

  let initialSortBy = []
  if (orderBy) {
    initialSortBy = [{
      id: orderBy,
      desc: order === 'desc'
    }]
  }

  const pagination = (
    <TablePagination
      limit={limit}
      offset={offset}
      onRowsPerPageChange={(e) => {
        setLimit(e.target.value)
        setPageSizeRef.current(e.target.value)
      }}
      onPageChange={(e, number) => setOffset(number * limit)}
    />
  )

  return (
    <PageHolder>
      {editItem && <EditRole isEditing item={editItem} onOk={onEditOk} onCancel={onEditCancel} />}
      {deleteItem && <DeleteItem text={deleteItem.name || deleteItem.first_name} onOk={() => { onDeleteOk(deleteItem) }} onCancel={onDeleteCancel} />}
      <PageToolbar left={
        <>
          <RoleIcon style={{ width: 20, height: 20 }} /> <span>{locale.roles}</span>{canCreate && <Link onClickHandler={() => { navigate('/roles/new') }}><Add width={20} height={20} color='#447fb1' /></Link>}
        </>
        }
      >
        <SearchField resetSearch={resetSearch} value={search} onChange={setSearch} />
      </PageToolbar>
      <TableHolder>
        <Table columns={columns} data={rows} initialPageSize={limit} initialSortBy={initialSortBy}>
          {({ setPageSize }) => {
            // Store table callback in a ref so we can call it from elsewhere
            setPageSizeRef.current = setPageSize
          }}
        </Table>
      </TableHolder>
      <PageFooter>{pagination}</PageFooter>
    </PageHolder>
  )
}

const mapStateToProps = (state) => {
  const { locale, role } = state
  const permissions = state.login.permissions
  const {
    pageItems,
    limit,
    offset,
    orderBy,
    order,
    search
  } = role
  return {
    locale: locale.strings,
    rows: pageItems,
    limit,
    offset,
    orderBy,
    order,
    search,
    canUpdate: permissions.role.canUpdate,
    canDelete: permissions.role.canDelete,
    canCreate: permissions.role.canCreate,
    deleteItem: state.role.deleteItem,
    editItem: state.role.editItem
  }
}

const mapDispatchToProps = (dispatch) => {
  const { actions: { role } } = redux
  return {
    onShowAddDialog: () => {
      dispatch(role.showAddRoleDialog())
    },

    onEditOk: (r) => {
      dispatch(role.hideEditRoleDialog())
      dispatch(role.updateRole(r))
    },
    onEditItemClick: (r) => {
      dispatch(role.showEditRoleDialog(r))
    },
    onEditCancel: () => {
      dispatch(role.hideEditRoleDialog())
    },

    onDeleteOk: (r) => {
      dispatch(role.hideDeleteRoleDialog())
      dispatch(role.deleteRole(r))
    },
    onDeleteItemClick: (r) => {
      dispatch(role.showDeleteRoleDialog(r))
    },
    onDeleteCancel: () => {
      dispatch(role.hideDeleteRoleDialog())
    },

    getPageItems: () => dispatch(role.getPageItems()),
    setOrder: (orderBy, order) => dispatch(role.setOrder(orderBy, order)),
    setLimit: (limit) => dispatch(role.setLimit(limit)),
    setOffset: (offset) => dispatch(role.setOffset(offset)),
    setSearch: (search) => dispatch(role.setSearch(search)),
    resetSearch: () => dispatch(role.resetSearch())

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RolePage)
