import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Save } from '@mui/icons-material'
import { Button, Card, Group, Layout, Link, Waves, styled } from '@pergas-common/pergas-components'
import { Add, Email, Phone, Remove } from '@pergas-common/pergas-icons'
import CollectionDropdown from '../../CollectionDropdown.js'
import Input, { InputGroup } from '../../Input.js'
import ObjectTypeDropdown from '../../ObjectTypeDropdown.js'
import Page from '../Page.js'
import { useAddEmployeeMutation, useDeleteEmployeeMutation, useGetEmployeeByIdQuery, useUpdateEmployeeMutation } from '../../../redux/employee/hooks.js'
import { selectLocale } from '../../../redux/locale/selectors.js'
import useUrlQuery from '../../../hooks/useUrlQuery.js'
import Divider from '../../Divider.js'
import { DeleteItemV2 } from '../../dialogs/DeleteItem'
import EmployeeGrid from '../../EmployeeGrid.js'
import { EmployeeIcon } from '../../icons.js'
import TagListCard from '../TagListCard.js'

const Overflow = styled.div`
  overflow-y: hidden;
  height: 218px;
`

const Form = ({ id, onSubmit, data }) => {
  const ref = useRef(null)
  const { state } = useLocation()
  const defaultCollection = {
    id: data?.collection_id || state?.collection_id || 0,
    name: data?.collection_name || state?.collection_name || ''
  }
  const defaultObjectType = {
    id: data?.object_type_id || state?.object_type_id || 0,
    name: data?.object_type_name || state?.object_type_name || ''
  }

  let defaultTags = []
  if (Array.isArray(data?.tags)) {
    defaultTags = [...data.tags]
  }
  if (Array.isArray(state?.tags)) {
    defaultTags = [...defaultTags, ...state.tags]
  }

  const [collection, setCollection] = useState(defaultCollection)
  const [objectType, setObjectType] = useState(defaultObjectType)
  const [tags, setTags] = useState(defaultTags)
  const locale = useSelector(selectLocale)

  const submit = (e) => {
    e.preventDefault()
    const payload = {}
    const formData = new FormData(ref.current)
    for (const [key, value] of formData.entries()) {
      if (key === 'collection_id') {
        payload[key] = collection?.id || null
      } else if (key === 'object_type_id') {
        payload[key] = objectType?.id || null
      } else {
        const path = key.split('.')
        if (path.length === 2) {
          payload[path[0]] = { ...payload[path[0]], [path[1]]: value }
        } else {
          payload[key] = value
        }
      }
      if (id) {
        payload.id = Number(id)
      }
      payload.tags = [...tags.map((tag) => {
        return {
          color: tag.color,
          name: tag.name,
          tag_id: tag.tag_id ?? tag.id,
          tag_type_name: tag.tag_type_name
        }
      })]
    }
    onSubmit(payload)
  }
  return (
    <form onSubmit={submit} id='employee' ref={ref}>
      <Input name='first_name' label={locale.first_name} defaultValue={data?.first_name} />
      <Input name='last_name' label={locale.last_name} defaultValue={data?.last_name} />
      <Divider />
      <CollectionDropdown setCollection={setCollection} defaultValue={defaultCollection} />
      <ObjectTypeDropdown type='employee' setObjectType={setObjectType} label={locale.employee_object_type} defaultValue={defaultObjectType} />
      <Divider />
      <Input name='address.address' label={locale.address} defaultValue={data?.address?.address} />
      <InputGroup>
        <Input name='address.postal_code' label={locale.postal_code} defaultValue={data?.address?.postal_code} />
        <Input name='address.city' label={locale.city} defaultValue={data?.address?.city} />
      </InputGroup>
      <Input name='address.email' label={locale.email} defaultValue={data?.address?.email}>
        <Link href={`mailto:${data?.address?.email}`}><Email width={16} height={16} color='#28afe0' /></Link>
      </Input>
      <Input name='address.phone' label={locale.phone} defaultValue={data?.address?.phone}>
        <Link href={`tel:${data?.address?.phone}`}><Phone width={18} height={18} color='#28afe0' /></Link>
      </Input>
      <Divider />
      <TagListCard tags={tags} tagType='employee' onChangeHandler={setTags} />
    </form>
  )
}

const EmployeeDetails = () => {
  const [remove, setRemove] = useState(false)
  const navigate = useNavigate()
  const query = useUrlQuery()
  const id = query.get('id')
  const locale = useSelector(selectLocale)
  const { data, isUnitialized, isLoading, isSuccessful } = useGetEmployeeByIdQuery(id)
  const { mutation: updateMutation, isLoading: isUpdating } = useUpdateEmployeeMutation()
  const { metadata, mutation: createMutation, isLoading: isCreating, isSuccessful: isCreated } = useAddEmployeeMutation()
  const { mutation: deleteMutation, isLoading: isDeleting, isSuccessful: isDeleted } = useDeleteEmployeeMutation()

  const pageMetadata = {
    title: locale.employee,
    icon: <EmployeeIcon width={20} height={20} />,
    link: (
      <Link onClickHandler={() => {
        navigate('/employees/new', {
          replace: true,
          state: {
            collection_id: data?.collection_id,
            collection_name: data?.collection_name,
            object_type_id: data?.object_type_id,
            object_type_name: data?.object_type_name,
            tags: data?.tags
          }
        })
      }}
      ><Add width={20} height={20} color='#28afe0' />
      </Link>
    )
  }

  const submit = (payload) => {
    if (id) {
      updateMutation(payload)
      return
    }
    createMutation(payload)
  }

  useEffect(() => {
    if (metadata?.id) {
      navigate(`/employees/edit?id=${metadata?.id}`, { replace: true })
    }
  }, [metadata, isCreated])

  useEffect(() => {
    if (isDeleted) {
      navigate('/employees', { replace: true })
    }
  }, [isDeleted])

  return (
    <Page metadata={pageMetadata}>
      <Layout.Aside>
        {(isSuccessful || isUnitialized) && (
          <Form id={id} data={data} onSubmit={submit} key={id} />
        )}
        {(isLoading && !isUnitialized) && (
          <Waves width={48} height={48} />
        )}
      </Layout.Aside>
      <Layout.Section>
        <Group.Button>
          <Button
            type='button' disabled={!id} onClick={() => {
              setRemove(true)
            }}
          >
            {isDeleting ? <Waves width={20} height={20} /> : <Remove width={20} height={20} color='#FF5656' />}<span>{locale.delete}</span>
          </Button>
          <Button type='submit' form='employee'>{(isUpdating || isCreating) ? <Waves width={20} height={20} /> : <Save style={{ color: '#34b3e1', width: 20, height: 20 }} />}<span>{locale.save}</span></Button>
        </Group.Button>
        <Card.Content>
          <Card.Header title={locale.employees} />
          <Overflow><EmployeeGrid currentId={id} /></Overflow>
        </Card.Content>
      </Layout.Section>
      {remove && <DeleteItemV2
        text={`${data?.first_name} ${data?.last_name}`}
        isOpen={remove}
        onCloseRequest={() => {
          setRemove(false)
        }}
        onSubmit={() => {
          deleteMutation({ id })
          setRemove(false)
        }}
                 />}

    </Page>
  )
}

export default EmployeeDetails
