/**
 * Make sure that singletons are created before anything else!
 */
import msal from './msal.js'
import redux from './redux/index.js'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { theme, ThemeProvider } from '@pergas-common/pergas-components'
import { Provider } from 'react-redux'
import './index.css'
import App from './App'
import Modal from 'react-modal'
import storage from './redux/storage.js'
import { isValidToken } from './util.js'

console.log('Using theme:', theme)
console.log('Default state:', redux.store.getState())
console.log('Version string:', process.env.REACT_APP_VERSION)

Modal.setAppElement('#root-modal')

theme.palette.brand.secondary = '#073A53'
theme.palette.table.primary = '#073A53'
theme.palette.table.tertiary = '#DAE1E5'

function start ({ idToken = '', token = '' }) {
  function Main () {
    return (
      <Provider store={redux.store}>
        <ThemeProvider theme={theme}>
          <App idToken={idToken} token={token} />
        </ThemeProvider>
      </Provider>
    )
  }
  createRoot(document.getElementById('root')).render(<Main />)
}

function redirectLogin () {
  msal.handleRedirectPromise().then((tokenResponse) => {
    if (tokenResponse !== null) {
      console.info('got id token from redirect method')
      start({ idToken: tokenResponse.idToken })
    } else {
      try {
        console.info('initiating redirect method')
        msal.loginRedirect()
      } catch (err) {
        // TODO this is a fatal error -> rende Error page with details
        console.error('msal.loginRedirect failed')
      }
    }
  }).catch((err) => {
    // TODO this is a fatal error -> rende Error page with details
    console.error('msal.handleRedirectPromise failed', err)
  })
}

const { token, email } = redux.store.getState().login.userData

if (isValidToken(token)) {
  console.info('found cached valid token')
  start({ token })
} else {
  const loginHint = email
  if (typeof loginHint === 'string') {
    console.info('msal.ssoSilent() with login hint', loginHint)
    const silentRequest = { loginHint }
    msal.ssoSilent(silentRequest).then(result => {
      start({ idToken: result.idToken })
    }).catch((err) => {
      console.error('msal.ssoSilent failed', err)
      storage.deleteUserData()
      redirectLogin()
    })
  } else {
    redirectLogin()
  }
}
