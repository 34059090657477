import React from 'react'
import { TablePagination as MuiTablePagination } from '@mui/material'
import { connect } from 'react-redux'

const ROWS_PER_PAGE = [
  10,
  25,
  50,
  100,
  500,
  1000
]

const TablePagination = ({
  // Redux state params
  locale,
  // Parent params
  limit,
  offset,
  onRowsPerPageChange,
  onPageChange
}) => {
  return (
    <table>
      <tbody>
        <tr>
          <MuiTablePagination
            rowsPerPage={limit}
            rowsPerPageOptions={ROWS_PER_PAGE}
            count={-1}
            page={offset / limit}
            labelRowsPerPage={locale.rows_per_page}
            labelDisplayedRows={({ from, to }) => `${from}-${to}`}
            onRowsPerPageChange={onRowsPerPageChange}
            onPageChange={onPageChange}
          />
        </tr>
      </tbody>
    </table>
  )
}

const mapStateToProps = (state) => {
  return {
    locale: state.locale.strings
  }
}

export default connect(mapStateToProps)(TablePagination)
