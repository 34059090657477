import React, { useEffect, useState } from 'react'
import {
  styled,
  spacing
} from '@pergas-common/pergas-components'
import { api } from '../../redux'
import useRequest from '../../hooks/useRequest'
import useDebounce from '../../hooks/useDebounce.js'
import { itemsWithEmail, Radio, Select } from '../Input'

const Holder = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  align-self: center;
  max-width: 320px;
  width: 100%;
`

const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: center;
`

const InputHolder = styled.div`
  display: inline-flex;
  align-items: center;
  > small {
    margin-left: ${spacing(1)};
  }
`

const SelectRadioLabel = styled.label`
  display: inline-flex;
  align-items: flex-end;
  > div {
    height: 48px;
    margin-left: ${spacing(1)};
  }
`

const FilterEmployee = ({ label, radio = true, checked = false, defaultValue = { id: 0, name: '', email: '' }, applyFilter }) => {
  const [employee, setEmployee] = useState(defaultValue)
  const [request, result = [], status] = useRequest(api.getEmployees, 'employee')
  const [debouncedRequest] = useDebounce(request, 300)
  useEffect(() => {
    request({ query: [{ key: 'name', op: '=', value: '' }], sort: 'name.asc', limit: 50 })
  }, [request])
  return (
    <>
      <Holder>
        <FilterRow>
          <SelectRadioLabel>
            <InputHolder>
              {radio && (
                <Radio
                  value={checked}
                  disabled={checked}
                  readOnly label={label} checked={checked} onChange={() => {
                    if (employee?.id) {
                      applyFilter(employee.id, employee)
                    }
                  }}
                />
              )}
            </InputHolder>
            <Select
              name='responsible'
              label={label}
              defaultValue={employee}
              cb={({ selectedItem, inputValue }) => {
                if (inputValue === '') {
                  applyFilter(null)
                  setEmployee(null)
                }

                if (selectedItem && inputValue !== '') {
                  applyFilter(selectedItem.id, selectedItem)
                  setEmployee(selectedItem)
                }
              }}
              request={(input) => {
                debouncedRequest({ query: [{ key: 'name', op: '~', value: input }], sort: 'name.asc', limit: 50 })
              }}
              requestStatus={status === 'pending'}
              items={[{ id: 0, name: '', email: '' }, ...itemsWithEmail(result)]}
              handleChange={(_, id, contact) => {}}
            />
          </SelectRadioLabel>
        </FilterRow>
      </Holder>
    </>
  )
}

export default FilterEmployee
