import React, { useEffect, useMemo, useCallback, useRef } from 'react'
import { connect } from 'react-redux'
import TablePagination from './TablePagination.jsx'
import PageToolbar from './PageToolbar.js'
import PageFooter from './PageFooter.jsx'
import redux from '../../redux/index.js'
import SearchField from '../SearchField.js'
import DeleteItem from '../dialogs/DeleteItem.js'
import { Link, styled, Tag } from '@pergas-common/pergas-components'
import { Table, Td } from '../../pergas-components/index.js'
import { Add } from '@pergas-common/pergas-icons'
import { createDateAccessor } from './columns.js'
import EditObjectType from '../dialogs/EditObjectType.js'
import { DeleteItemIcon, ObjectTypeIcon } from '../icons.js'
import { DEFAULT_OBJECT_TYPE_COLOR } from '../style.js'
import Tooltip from '../Tooltip.js'
import { useNavigate } from 'react-router-dom'

const PageHolder = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 100%;
`

const TableHolder = styled.div`
  display: flex;
  overflow-y: scroll;
  flex-grow: 1;
  flex-basis: 50px;
`

const TagHolder = styled.span`
  margin-left: 5px;
  margin-right: 8px;
`

const ObjectTypePage = ({
  locale,
  rows,
  limit,
  offset,
  orderBy,
  order,
  search,
  resetSearch,
  getPageItems,
  setOrder,
  setLimit,
  setOffset,
  setSearch,
  canUpdate,
  canDelete,
  deleteItem,
  onDeleteItemClick,
  onDeleteOk,
  onDeleteCancel,
  editItem,
  onEditItemClick,
  onEditOk,
  onEditCancel,
  canCreate,
  onShowAddDialog
}) => {
  const navigate = useNavigate()
  useEffect(getPageItems, [limit, offset, orderBy, order, search])

  const setPageSizeRef = useRef(() => {})

  const manualSort = useCallback(({ id, isSorted, isSortedDesc }) => {
    if (isSorted && !isSortedDesc) {
      setOrder(id, 'desc')
    } else if (isSorted && isSortedDesc) {
      setOrder('', '')
    } else {
      setOrder(id, 'asc')
    }
  }, [setOrder])

  const columns = useMemo(() => {
    return [
      {
        Header: locale.name,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return (
            <Td
              {...cell.getCellProps()} left={
                <>
                  <ObjectTypeIcon style={{ width: 16, height: 16 }} />
                  <TagHolder onClick={() => navigate(`/object-types/edit?id=${original.id}`)}>
                    <Tag color={original.color || DEFAULT_OBJECT_TYPE_COLOR} textColor={original.color ? '#FFFFFF' : '#3a4a54'} border='#969696'>{original.name}</Tag>
                  </TagHolder>
                </>
            }
            />
          )
        },
        canSort: true,
        id: 'name',
        size: 'sm',
        manualSort,
        sortType: () => {}
      },
      {
        Header: locale.description,
        manualSort,
        accessor: 'description',
        sortType: () => {}
      },
      {
        id: 'object_type_name',
        Header: locale.object_type_type,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return (<Td {...cell.getCellProps()} left={locale[original.object_type_type] || ''} />)
        },
        isSortable: false
      },
      {
        id: 'updated_at',
        Header: locale.updated_at,
        manualSort,
        accessor: createDateAccessor('updated_at'),
        sortType: () => {}
      },
      {
        id: 'toolbar',
        Header: locale.tool_belt,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return (
            <Td
              {...cell.getCellProps()}
              left={<></>}
              right={canDelete && !original.read_only && <Tooltip side='left' content={locale.delete}><Link onClickHandler={() => { onDeleteItemClick(original) }}><DeleteItemIcon /></Link></Tooltip>}
            />
          )
        },
        size: 'sm',
        isSortable: false
      }
    ]
  }, [locale, canUpdate, canDelete, manualSort, onDeleteItemClick, onEditItemClick])

  let initialSortBy = []
  if (orderBy) {
    initialSortBy = [{
      id: orderBy,
      desc: order === 'desc'
    }]
  }

  const pagination = (
    <TablePagination
      limit={limit}
      offset={offset}
      onRowsPerPageChange={(e) => {
        setLimit(e.target.value)
        setPageSizeRef.current(e.target.value)
      }}
      onPageChange={(e, number) => setOffset(number * limit)}
    />
  )

  return (
    <PageHolder>
      {editItem && <EditObjectType isEditing item={editItem} onOk={onEditOk} onCancel={onEditCancel} />}
      {deleteItem && <DeleteItem text={deleteItem.name} onOk={() => { onDeleteOk(deleteItem) }} onCancel={onDeleteCancel} />}
      <PageToolbar left={
        <>
          <ObjectTypeIcon style={{ width: 20, height: 20 }} />
          <span>{locale.object_types}</span>
          {canCreate && <Link onClickHandler={() => { navigate('/object-types/new') }}><Add width={20} height={20} color='#447fb1' /></Link>}
        </>
      }
      >
        <SearchField value={search} onChange={setSearch} />
      </PageToolbar>
      <TableHolder>
        <Table columns={columns} data={rows} initialPageSize={limit} initialSortBy={initialSortBy}>
          {({ setPageSize }) => {
            // Store table callback in a ref so we can call it from elsewhere
            setPageSizeRef.current = setPageSize
          }}
        </Table>
      </TableHolder>
      <PageFooter>{pagination}</PageFooter>
    </PageHolder>
  )
}

const mapStateToProps = (state) => {
  const { locale, objectType } = state
  const permissions = state.login.permissions
  const {
    pageItems,
    limit,
    offset,
    orderBy,
    order,
    search
  } = objectType
  return {
    locale: locale.strings,
    rows: pageItems,
    limit,
    offset,
    orderBy,
    order,
    search,
    canUpdate: permissions.objectType.canUpdate,
    canDelete: permissions.objectType.canDelete,
    canCreate: permissions.objectType.canCreate,
    deleteItem: state.objectType.deleteItem,
    editItem: state.objectType.editItem
  }
}

const mapDispatchToProps = (dispatch) => {
  const { actions: { objectType } } = redux
  return {
    onShowAddDialog: () => {
      dispatch(objectType.showAddObjectTypeDialog())
    },

    onEditOk: (type) => {
      dispatch(objectType.hideEditObjectTypeDialog())
      dispatch(objectType.updateObjectType(type))
    },
    onEditItemClick: (type) => {
      dispatch(objectType.showEditObjectTypeDialog(type))
    },
    onEditCancel: () => {
      dispatch(objectType.hideEditObjectTypeDialog())
    },

    onDeleteOk: (type) => {
      dispatch(objectType.hideDeleteObjectTypeDialog())
      dispatch(objectType.deleteObjectType(type))
    },
    onDeleteItemClick: (type) => {
      dispatch(objectType.showDeleteObjectTypeDialog(type))
    },
    onDeleteCancel: () => {
      dispatch(objectType.hideDeleteObjectTypeDialog())
    },

    getPageItems: () => dispatch(objectType.getPageItems()),
    setOrder: (orderBy, order) => dispatch(objectType.setOrder(orderBy, order)),
    setLimit: (limit) => dispatch(objectType.setLimit(limit)),
    setOffset: (offset) => dispatch(objectType.setOffset(offset)),
    setSearch: (search) => dispatch(objectType.setSearch(search)),
    resetSearch: () => dispatch(objectType.resetSearch())

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ObjectTypePage)
