export default Object.freeze({
  // admin
  account: {
    add: 'ERROR_TYPE_ACCOUNT_ADD',
    get: 'ERROR_TYPE_ACCOUNT_GET',
    update: 'ERROR_TYPE_ACCOUNT_UPDATE',
    del: 'ERROR_TYPE_ACCOUNT_DELETE',
    notFound: 'ERROR_TYPE_ACCOUNT_NOT_FOUND',
    noAddPermission: 'ERROR_TYPE_ACCOUNT_NO_ADD_PERMISSION',
    noGetPermission: 'ERROR_TYPE_ACCOUNT_NO_GET_PERMISSION',
    noUpdatePermission: 'ERROR_TYPE_ACCOUNT_NO_UPDATE_PERMISSION',
    noDeletePermission: 'ERROR_TYPE_ACCOUNT_NO_DELETE_PERMISSION'
  },
  permission: {
    get: 'ERROR_TYPE_PERMISSION_GET',
    notFound: 'ERROR_TYPE_PERMISSION_NOT_FOUND',
    noGetPermission: 'ERROR_TYPE_PERMISSION_NO_GET_PERMISSION'
  },
  tenant: {
    get: 'ERROR_TYPE_TENANT_GET',
    notFound: 'ERROR_TYPE_TENANT_NOT_FOUND',
    noGetPermission: 'ERROR_TYPE_TENANT_NO_GET_PERMISSION'
  },

  // user
  category: {
    add: 'ERROR_TYPE_CATEGORY_ADD',
    get: 'ERROR_TYPE_CATEGORY_GET',
    update: 'ERROR_TYPE_CATEGORY_UPDATE',
    del: 'ERROR_TYPE_CATEGORY_DELETE',
    conflict: 'ERROR_TYPE_CATEGORY_CONFLICT',
    notFound: 'ERROR_TYPE_CATEGORY_NOT_FOUND',
    noAddPermission: 'ERROR_TYPE_CATEGORY_NO_ADD_PERMISSION',
    noGetPermission: 'ERROR_TYPE_CATEGORY_NO_GET_PERMISSION',
    noUpdatePermission: 'ERROR_TYPE_CATEGORY_NO_UPDATE_PERMISSION',
    noDeletePermission: 'ERROR_TYPE_CATEGORY_NO_DELETE_PERMISSION'
  },
  contact: {
    add: 'ERROR_TYPE_CONTACT_ADD',
    get: 'ERROR_TYPE_CONTACT_GET',
    update: 'ERROR_TYPE_CONTACT_UPDATE',
    del: 'ERROR_TYPE_CONTACT_DELETE',
    conflict: 'ERROR_TYPE_CONTACT_CONFLICT',
    notFound: 'ERROR_TYPE_CONTACT_NOT_FOUND',
    noAddPermission: 'ERROR_TYPE_CONTACT_NO_ADD_PERMISSION',
    noGetPermission: 'ERROR_TYPE_CONTACT_NO_GET_PERMISSION',
    noUpdatePermission: 'ERROR_TYPE_CONTACT_NO_UPDATE_PERMISSION',
    noDeletePermission: 'ERROR_TYPE_CONTACT_NO_DELETE_PERMISSION'
  },
  contactPerson: {
    add: 'ERROR_TYPE_CONTACT_PERSON_ADD',
    get: 'ERROR_TYPE_CONTACT_PERSON_GET',
    update: 'ERROR_TYPE_CONTACT_PERSON_UPDATE',
    del: 'ERROR_TYPE_CONTACT_PERSON_DELETE',
    notFound: 'ERROR_TYPE_CONTACT_PERSON_NOT_FOUND',
    noAddPermission: 'ERROR_TYPE_CONTACT_PERSON_NO_ADD_PERMISSION',
    noGetPermission: 'ERROR_TYPE_CONTACT_PERSON_NO_GET_PERMISSION',
    noUpdatePermission: 'ERROR_TYPE_CONTACT_PERSON_NO_UPDATE_PERMISSION',
    noDeletePermission: 'ERROR_TYPE_CONTACT_PERSON_NO_DELETE_PERMISSION'
  },
  contentType: {
    get: 'ERROR_TYPE_CONTENT_TYPE_GET',
    notFound: 'ERROR_TYPE_CONTENT_TYPE_NOT_FOUND',
    noGetPermission: 'ERROR_TYPE_CONTENT_TYPE_NO_GET_PERMISSION'
  },
  department: {
    add: 'ERROR_TYPE_DEPARTMENT_ADD',
    get: 'ERROR_TYPE_DEPARTMENT_GET',
    update: 'ERROR_TYPE_DEPARTMENT_UPDATE',
    del: 'ERROR_TYPE_DEPARTMENT_DELETE',
    conflict: 'ERROR_TYPE_DEPARTMENT_CONFLICT',
    notFound: 'ERROR_TYPE_DEPARTMENT_NOT_FOUND',
    noAddPermission: 'ERROR_TYPE_DEPARTMENT_NO_ADD_PERMISSION',
    noGetPermission: 'ERROR_TYPE_DEPARTMENT_NO_GET_PERMISSION',
    noUpdatePermission: 'ERROR_TYPE_DEPARTMENT_NO_UPDATE_PERMISSION',
    noDeletePermission: 'ERROR_TYPE_DEPARTMENT_NO_DELETE_PERMISSION'
  },
  employee: {
    add: 'ERROR_TYPE_EMPLOYEE_ADD',
    get: 'ERROR_TYPE_EMPLOYEE_GET',
    update: 'ERROR_TYPE_EMPLOYEE_UPDATE',
    del: 'ERROR_TYPE_EMPLOYEE_DELETE',
    notFound: 'ERROR_TYPE_EMPLOYEE_NOT_FOUND',
    noAddPermission: 'ERROR_TYPE_EMPLOYEE_NO_ADD_PERMISSION',
    noGetPermission: 'ERROR_TYPE_EMPLOYEE_NO_GET_PERMISSION',
    noUpdatePermission: 'ERROR_TYPE_EMPLOYEE_NO_UPDATE_PERMISSION',
    noDeletePermission: 'ERROR_TYPE_EMPLOYEE_NO_DELETE_PERMISSION'
  },
  objectType: {
    add: 'ERROR_TYPE_OBJECT_TYPE_ADD',
    get: 'ERROR_TYPE_OBJECT_TYPE_GET',
    update: 'ERROR_TYPE_OBJECT_TYPE_UPDATE',
    del: 'ERROR_TYPE_OBJECT_TYPE_DELETE',
    notFound: 'ERROR_TYPE_OBJECT_TYPE_NOT_FOUND',
    noAddPermission: 'ERROR_TYPE_OBJECT_TYPE_NO_ADD_PERMISSION',
    noGetPermission: 'ERROR_TYPE_OBJECT_TYPE_NO_GET_PERMISSION',
    noUpdatePermission: 'ERROR_TYPE_OBJECT_TYPE_NO_UPDATE_PERMISSION',
    noDeletePermission: 'ERROR_TYPE_OBJECT_TYPE_NO_DELETE_PERMISSION'
  },
  project: {
    add: 'ERROR_TYPE_PROJECT_ADD',
    get: 'ERROR_TYPE_PROJECT_GET',
    update: 'ERROR_TYPE_PROJECT_UPDATE',
    del: 'ERROR_TYPE_PROJECT_DELETE',
    conflict: 'ERROR_TYPE_PROJECT_CONFLICT',
    notFound: 'ERROR_TYPE_PROJECT_NOT_FOUND',
    noAddPermission: 'ERROR_TYPE_PROJECT_NO_ADD_PERMISSION',
    noGetPermission: 'ERROR_TYPE_PROJECT_NO_GET_PERMISSION',
    noUpdatePermission: 'ERROR_TYPE_PROJECT_NO_UPDATE_PERMISSION',
    noDeletePermission: 'ERROR_TYPE_PROJECT_NO_DELETE_PERMISSION'
  },
  role: {
    add: 'ERROR_TYPE_ROLE_ADD',
    get: 'ERROR_TYPE_ROLE_GET',
    update: 'ERROR_TYPE_ROLE_UPDATE',
    del: 'ERROR_TYPE_ROLE_DELETE',
    notFound: 'ERROR_TYPE_ROLE_NOT_FOUND',
    noAddPermission: 'ERROR_TYPE_ROLE_NO_ADD_PERMISSION',
    noGetPermission: 'ERROR_TYPE_ROLE_NO_GET_PERMISSION',
    noUpdatePermission: 'ERROR_TYPE_ROLE_NO_UPDATE_PERMISSION',
    noDeletePermission: 'ERROR_TYPE_ROLE_NO_DELETE_PERMISSION'
  },
  tag: {
    add: 'ERROR_TYPE_TAG_ADD',
    get: 'ERROR_TYPE_TAG_GET',
    update: 'ERROR_TYPE_TAG_UPDATE',
    del: 'ERROR_TYPE_TAG_DELETE',
    notFound: 'ERROR_TYPE_TAG_NOT_FOUND',
    noAddPermission: 'ERROR_TYPE_TAG_NO_ADD_PERMISSION',
    noGetPermission: 'ERROR_TYPE_TAG_NO_GET_PERMISSION',
    noUpdatePermission: 'ERROR_TYPE_TAG_NO_UPDATE_PERMISSION',
    noDeletePermission: 'ERROR_TYPE_TAG_NO_DELETE_PERMISSION'
  },
  ticket: {
    add: 'ERROR_TYPE_TICKET_ADD',
    get: 'ERROR_TYPE_TICKET_GET',
    update: 'ERROR_TYPE_TICKET_UPDATE',
    del: 'ERROR_TYPE_TICKET_DELETE',
    notFound: 'ERROR_TYPE_TICKET_NOT_FOUND',
    noAddPermission: 'ERROR_TYPE_TICKET_NO_ADD_PERMISSION',
    noGetPermission: 'ERROR_TYPE_TICKET_NO_GET_PERMISSION',
    noUpdatePermission: 'ERROR_TYPE_TICKET_NO_UPDATE_PERMISSION',
    noDeletePermission: 'ERROR_TYPE_TICKET_NO_DELETE_PERMISSION'
  }
})
