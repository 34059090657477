import { busy, notBusy } from '../busy/actions.js'
import {
  handleAddErrors,
  handleGetErrors,
  handleUpdateErrors,
  handleDeleteErrors
} from '../error/actions.js'

import api from '../api.js'
import store from '../store.js'

export const ACCOUNT_CREATE_ACTION = 'ACCOUNT_CREATE_ACTION'
export const ACCOUNT_SHOW_ADD_DIALOG_ACTION = 'ACCOUNT_SHOW_ADD_DIALOG_ACTION'
export const ACCOUNT_HIDE_ADD_DIALOG_ACTION = 'ACCOUNT_HIDE_ADD_DIALOG_ACTION'
export const ACCOUNT_GET_OK_ACTION = 'ACCOUNT_GET_OK_ACTION'
export const ACCOUNT_UPDATE_ACTION = 'ACCOUNT_UPDATE_ACTION'
export const ACCOUNT_SHOW_EDIT_DIALOG_ACTION = 'ACCOUNT_SHOW_EDIT_DIALOG_ACTION'
export const ACCOUNT_HIDE_EDIT_DIALOG_ACTION = 'ACCOUNT_HIDE_EDIT_DIALOG_ACTION'
export const ACCOUNT_DELETE_ACTION = 'ACCOUNT_DELETE_ACTION'
export const ACCOUNT_SHOW_DELETE_DIALOG_ACTION = 'ACCOUNT_SHOW_DELETE_DIALOG_ACTION'
export const ACCOUNT_HIDE_DELETE_DIALOG_ACTION = 'ACCOUNT_HIDE_DELETE_DIALOG_ACTION'

export const ACCOUNT_GET_PAGE_ITEMS_ACTION = 'ACCOUNT_GET_PAGE_ITEMS_ACTION'
export const ACCOUNT_SET_ORDER_ACTION = 'ACCOUNT_SET_ORDER_ACTION'
export const ACCOUNT_SET_LIMIT_ACTION = 'ACCOUNT_SET_LIMIT_ACTION'
export const ACCOUNT_SET_OFFSET_ACTION = 'ACCOUNT_SET_OFFSET_ACTION'
export const ACCOUNT_SET_SEARCH_ACTION = 'ACCOUNT_SET_SEARCH_ACTION'
export const ACCOUNT_RESET_SEARCH_ACTION = 'ACCOUNT_RESET_SEARCH_ACTION'

export function accountCreateAction (account) {
  return {
    type: ACCOUNT_CREATE_ACTION,
    account
  }
}

export function accountUpdateAction (account) {
  return {
    type: ACCOUNT_UPDATE_ACTION,
    account
  }
}

export function accountDeleteAction (account) {
  return {
    type: ACCOUNT_DELETE_ACTION,
    account
  }
}

/**
 * Add dialog
 */
export function showAddAccountDialog () {
  return {
    type: ACCOUNT_SHOW_ADD_DIALOG_ACTION
  }
}

export function hideAddAccountDialog () {
  return {
    type: ACCOUNT_HIDE_ADD_DIALOG_ACTION
  }
}

/**
 * Edit dialog
 */
export function showEditAccountDialog (account) {
  return {
    type: ACCOUNT_SHOW_EDIT_DIALOG_ACTION,
    account
  }
}

export function hideEditAccountDialog () {
  return {
    type: ACCOUNT_HIDE_EDIT_DIALOG_ACTION
  }
}

/**
 * Delete dialog
 */
export function showDeleteAccountDialog (account) {
  return {
    type: ACCOUNT_SHOW_DELETE_DIALOG_ACTION,
    account
  }
}

export function hideDeleteAccountDialog () {
  return {
    type: ACCOUNT_HIDE_DELETE_DIALOG_ACTION
  }
}

/**
 * CREATE
 */
export function addAccount (account) {
  return function (dispatch) {
    dispatch(busy())
    api.addAccount(account).then(() => {
      dispatch(notBusy())
    }).catch(handleAddErrors('account', dispatch))
  }
}

/**
 * READ
 */
// TODO remove
export function getAccounts () {
  return function (dispatch) {
    dispatch(busy())
    api.getAccounts().then(result => {
      dispatch(notBusy())
      dispatch(receivedAccounts(result.data.value))
    }).catch(handleGetErrors('account', dispatch))
  }
}

function receivedAccounts (items) {
  return {
    type: ACCOUNT_GET_OK_ACTION,
    items
  }
}

/**
 * UPDATE
 */
export function updateAccount (account) {
  return function (dispatch) {
    dispatch(busy())
    api.updateAccount(account).then(() => {
      dispatch(notBusy())
    }).catch(handleUpdateErrors('account', dispatch))
  }
}

/**
 * DELETE
 */
export function deleteAccount (account) {
  return function (dispatch) {
    dispatch(busy())
    api.deleteAccount(account).then(() => {
      dispatch(notBusy())
    }).catch(handleDeleteErrors('account', dispatch))
  }
}

/**
 * Get items for a page
 */
export function getPageItems () {
  const state = store.getState()
  const { search, limit, offset, orderBy, order } = state.account

  const query = []
  if (typeof search === 'string' && search.length > 0) {
    query.push({ key: 'name', value: search, op: '~' })
  }

  const sort = orderBy ? `${orderBy}.${order}` : null

  return function (dispatch) {
    dispatch(busy())
    api.getAccounts({
      query,
      limit,
      offset,
      sort
    }).then(result => {
      dispatch(notBusy())
      dispatch({
        type: ACCOUNT_GET_PAGE_ITEMS_ACTION,
        pageItems: result.data.value
      })
    }).catch(handleGetErrors('account', dispatch))
  }
}

/**
 * Sort order
 */
export function setOrder (orderBy, order) {
  return {
    type: ACCOUNT_SET_ORDER_ACTION,
    orderBy,
    order
  }
}

/**
 * Set limit for pagination
 */
export function setLimit (limit) {
  return {
    type: ACCOUNT_SET_LIMIT_ACTION,
    limit
  }
}

/**
 * Set offset for pagination
 */
export function setOffset (offset) {
  return {
    type: ACCOUNT_SET_OFFSET_ACTION,
    offset
  }
}

/**
 * Set search
 */
export function setSearch (search) {
  return {
    type: ACCOUNT_SET_SEARCH_ACTION,
    search
  }
}

export function resetSearch () {
  return {
    type: ACCOUNT_RESET_SEARCH_ACTION
  }
}
