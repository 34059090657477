import React, { useEffect, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import {
  styled,
  Button,
  Link,
  Waves,
  Layout
} from '@pergas-common/pergas-components'
import {
  Add,
  Company
} from '@pergas-common/pergas-icons'
import { Save } from '@mui/icons-material'
import { selectLocale } from '../../../redux/locale/selectors'
import { makeSelectorPermissionContact } from '../../../redux/permission/selectors'
import { useAddContactMutation, useUpdateContactMutation, useGetContactByIdQuery } from '../../../redux/contact/hooks'
import TopbarNavigation from '../../TopbarNavigation'
import { usePermissionRoute } from '../../../hooks/usePermission'
import useUrlQuery from '../../../hooks/useUrlQuery'
import AsyncLoader from '../../AsyncLoader'
import ContactForm from './form'
import Buttons from './buttons'

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const validate = (values) => {
  const errors = {}
  if (!values.name) {
    errors.name = 'Required'
  }

  return errors
}

const initialValues = (contact) => {
  const {
    name = '',
    description = '',
    person_role = [],
    collection_role = [],
    object_type_name = '',
    object_type_id = 0,
    tags = [],
    vat_no: vatNo = ''
  } = contact ?? {}

  const {
    phone = '',
    email = '',
    postal_code: postalCode = '',
    homepage = '',
    city = '',
    address: _address = ''
  } = contact?.address ?? {}

  return {
    name,
    description: description ?? '',
    tags,
    vat_no: vatNo ?? '',
    collection_role,
    object_type_name,
    object_type_id,
    person_role: person_role,
    address: _address ?? '',
    phone: phone ?? '',
    email: email ?? '',
    postal_code: postalCode ?? '',
    homepage: homepage ?? '',
    city: city ?? ''
  }
}

function useFormikValues (cb) {
  const onSubmit = useCallback(async (values) => {
    const payload = {
      ...values,
      address: {
        address: values.address,
        city: values.city,
        email: values.email,
        homepage: values.homepage,
        phone: values.phone,
        postal_code: values.postal_code
      }
    }
    cb(payload)
  }, [cb])
  return onSubmit
}

const New = () => {
  const navigate = useNavigate()
  const locale = useSelector(selectLocale)
  const selectByPermissionContact = useMemo(makeSelectorPermissionContact, [])
  const permissions = useSelector(selectByPermissionContact)
  const formData = initialValues()
  const { metadata, mutation, isLoading, isSuccessful } = useAddContactMutation()
  const onSubmitFormik = useFormikValues(mutation)
  usePermissionRoute(permissions, ['canCreate'])

  useEffect(() => {
    if (!isLoading && isSuccessful) {
      navigate(`/contacts/edit?id=${metadata?.id}`, { replace: true })
    }
  }, [metadata, isSuccessful])

  if (isLoading) {
    return (
      <Layout.Main>
        <Layout.Aside>
          <Center>
            <Waves width={40} height={40} />
          </Center>
        </Layout.Aside>
      </Layout.Main>
    )
  }

  return (
    <Formik initialValues={formData} onSubmit={onSubmitFormik} validate={validate}>
      {(props) => {
        const { inputError, isSubmitting, dirty } = props
        const disableSubmit = (!inputError && !isSubmitting && dirty && !isLoading)
        const submitAsyncLoader = <AsyncLoader loading={isSubmitting || isLoading}><Save style={{ color: '#34b3e1', width: 20, height: 20 }} /></AsyncLoader>
        return (
          <>
            <TopbarNavigation title={locale.contact} icon={<Company width={20} height={20} />} />
            <ContactForm disabled={false} {...props}>
              <Buttons {...props}>
                <Button disabled={!disableSubmit} type='submit' form='submitContact'><span>{locale.save}</span>{submitAsyncLoader}</Button>
              </Buttons>
            </ContactForm>
          </>
        )
      }}
    </Formik>
  )
}

const View = () => {
  const navigate = useNavigate()
  const query = useUrlQuery()
  const id = query.get('id')

  const selectByPermissionContact = useMemo(makeSelectorPermissionContact, [])
  const permissions = useSelector(selectByPermissionContact)
  const locale = useSelector(selectLocale)
  const { data, refetch, isLoading } = useGetContactByIdQuery(id)

  const { mutation: updateMutation, isLoading: isMutating, isSuccessful: isSuccessfulUpdate } = useUpdateContactMutation()

  const formData = initialValues(data)
  usePermissionRoute(permissions, ['canUpdate'])

  const onSubmit = useCallback(async (values) => {
    const payload = {
      ...values,
      address: {
        address: values.address,
        city: values.city,
        email: values.email,
        homepage: values.homepage,
        phone: values.phone,
        postal_code: values.postal_code
      }
    }

    updateMutation({ id, ...payload })
  }, [updateMutation, id])

  useEffect(() => {
    if (isSuccessfulUpdate) {
      refetch()
    }
  }, [isSuccessfulUpdate, refetch])

  if (isLoading) {
    return (
      <Layout.Main>
        <Layout.Aside>
          <Center>
            <Waves width={40} height={40} />
          </Center>
        </Layout.Aside>
      </Layout.Main>
    )
  }

  const createLink = permissions.canCreate && <Link onClickHandler={() => { navigate('/contacts/new') }}><Add width={20} height={20} color='#28afe0' /></Link>
  return (
    <Formik initialValues={formData} onSubmit={onSubmit} validate={validate}>
      {(props) => {
        const { inputError, isSubmitting, dirty } = props
        const disableSubmit = inputError || isSubmitting || !dirty || isMutating
        const submitAsyncLoader = <AsyncLoader loading={isSubmitting || isMutating}><Save style={{ color: '#34b3e1', width: 20, height: 20 }} /></AsyncLoader>
        return (
          <>
            <TopbarNavigation title={locale.contact} icon={<Company width={20} height={20} />} link={createLink} />
            <ContactForm disable={false} {...props} original={data}>
              <Buttons {...props} groupId={data?.group_id}>
                <Button disabled={disableSubmit} type='submit' form='submitContact'><span>{locale.save}</span>{submitAsyncLoader}</Button>
              </Buttons>
            </ContactForm>
          </>
        )
      }}
    </Formik>
  )
}

export default { View, New }
