import Permissions from '@pergas-pds/pds-permissions'
import { ERROR_ACTION } from '../error/actions.js'
import { UnknownAvatarBase64 } from '../../components/icons.js'
import storage from '../storage.js'
import {
  LOGIN_SUCCESS_ACTION,
  LOGIN_GOT_USER_AVATAR_ACTION,
  ERROR_TYPE_LOGIN,
  LOGOUT_ACTION
} from './actions.js'

function calculatePermissions (userData) {
  return Permissions(userData ? userData.permissions : '')
}

const userData = storage.getUserData()
const { token } = userData
if (token) {
  console.info('Cached login!')
} else {
  console.info('No cached tokens found')
}

console.info('email:', userData.email)
console.info('name:', userData.name)
console.info('permissions:', userData.permissions)
console.info('tenant:', userData.tenant_name)
console.info('sharepoint save enabled:', userData.sharePointSaveEnabled)
console.info('sc root:', userData.sc_root)

const userAvatar = storage.getUserAvatar()
if (userAvatar) {
  console.info('found cached user avatar')
} else {
  console.info('no cached user avatar, using default for now')
}

const LOGIN_DEFAULT = {
  userData,
  permissions: calculatePermissions(userData),
  avatar: userAvatar || UnknownAvatarBase64
}

/**
 * Reducer for handling login/logout.
 */
export function login (state = LOGIN_DEFAULT, action) { // eslint-disable-line
  switch (action.type) {
    case LOGIN_SUCCESS_ACTION: {
      const { userData } = action
      return {
        ...state,
        userData,
        permissions: calculatePermissions(userData)
      }
    }
    case LOGIN_GOT_USER_AVATAR_ACTION: {
      const { avatar } = action
      return { ...state, avatar }
    }
    case LOGOUT_ACTION:
      return {
        ...state,
        permissions: calculatePermissions(),
        avatar: UnknownAvatarBase64
      }
    case ERROR_ACTION:
      if (action.errorType === ERROR_TYPE_LOGIN) {
        return { ...state, permissions: calculatePermissions() }
      } else {
        return state
      }
    default:
      return state
  }
}
