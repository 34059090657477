import React from 'react'
import { Link } from '@pergas-common/pergas-components'
import { Favorite, FavoriteFilled } from '@pergas-common/pergas-icons'

const FilterFavoriteButton = ({ filterQueries, onFilterChange }) => {
  const filteredByFavorites = Array.isArray(filterQueries) && filterQueries.find(filterQuery => filterQuery.key === 'is_favorite' && filterQuery.value === true)
  const FavoriteFilterIcon = filteredByFavorites ? FavoriteFilled : Favorite

  const toggleFavoriteFilter = () => {
    if (filteredByFavorites) {
      onFilterChange(filterQueries.filter(filterQuery => filterQuery.key !== 'is_favorite'))
    } else {
      onFilterChange([...filterQueries, { key: 'is_favorite', value: true, op: '=' }])
    }
  }

  return (
    <Link onClickHandler={toggleFavoriteFilter}><FavoriteFilterIcon width={20} height={20} color='#ca6eb6' /></Link>
  )
}

export default FilterFavoriteButton
