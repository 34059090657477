import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectLocale } from '../redux/locale/selectors'
import { Company } from '@pergas-common/pergas-icons'
import { Select } from './Input'
import useRequest from '../hooks/useRequest'
import api from '../redux/api'
import useDebounce from '../hooks/useDebounce'
import { useNavigate } from 'react-router-dom'

const CollectionDropdown = ({ setCollection, defaultValue }) => {
  const navigate = useNavigate()
  const [current, setCurrent] = useState(null)
  const [request, data, status] = useRequest(api.getContacts, 'contact')
  const [debouncedRequest] = useDebounce(request, 300)

  useEffect(() => {
    request({ sort: 'name.asc', limit: 50 })
  }, [request])
  const locale = useSelector(selectLocale)

  return (
    <Select
      name='collection_id'
      label={locale.contact}
      disabled={false}
      defaultValue={defaultValue}
      request={(input) => {
        debouncedRequest({ query: [{ key: 'name', op: '~', value: input }], sort: 'name.asc', limit: 50 })
      }}
      requestStatus={status === 'pending'}
      items={data}
      handleChange={(_, id, item) => {
        setCurrent(item)
        setCollection(item)
      }}
    >
      <Company
        onClick={() => {
          if (current) {
            navigate(`/contacts/edit?id=${current.id}`)
          }
        }} color='#28afe0' width={18} height={18}
      />
    </Select>
  )
}
export default CollectionDropdown
