import React from 'react'
import {
  styled,
  Tag as PergasTag,
  spacing
} from '@pergas-common/pergas-components'
import { Radio } from './Input'
import { useSelector } from 'react-redux'
import { selectLocale } from '../redux/locale/selectors'

const TagBackground = styled.div`
  background: ${({ theme }) => theme.palette.common.primary};
  border-radius: 8px;
  padding: 4px;
  margin: 8px 0;
  width: 100%;
  max-width: 148px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 2px 2px #f0f3f6;
`

const Label = styled.label`
  display: flex;
  align-self: stretch;
  align-items: center;
  cursor: pointer;
  > input {
    margin-right: ${spacing(1)};
  }
  > span {
    padding-left: ${spacing(1)};
    ${({ theme }) => theme.typography.body};
  }
  &:hover {
    transform: scale(1.1);
  }
`

const ColorInput = styled.input`
  &[type="color"] {
    padding: 0;
    margin: 0;
    height: 40px;
    border-color: transparent;
  }
  &::-webkit-color-swatch {
    border-color: transparent;
    border-radius: 4px;
  }
  
  &::-moz-color-swatch {
    border-color: transparent;
    border-radius: 5px;
  }
  min-width: 140px;
  border: none;
  background: none;
`

const Holder = styled.div`
  > ${Label}:last-child {
    > ${TagBackground} {
      padding: 0px 2px;
    }
  }
`
const ColorList = ({ color, setColor }) => {
  const locale = useSelector(selectLocale)
  const renderedColors = ['#133c55', '#386fa4', '#746d75', '#8c4843', '#7c3238', '#63372c', '#57737a', '#85bdbf', '#0f5257', '#52050a', '#6cae75', '#ff8c42']
  return (
    <Holder>
      {renderedColors.map((clr) => {
        return (
          <Label key={clr} htmlFor={clr}>
            <Radio
              name='color'
              id={clr}
              checked={color === clr}
              onChange={(e) => {
                setColor(clr)
              }}
              value={clr}
            />
            <TagBackground>
              <PergasTag color={clr} />
            </TagBackground>
          </Label>
        )
      })}

      <Label>
        <Radio
          name='color'
          id='customColor'
          checked={!renderedColors.includes(color)}
          onChange={() => {
            setColor('#0b3142')
          }}
        />
        <TagBackground>
          <ColorInput
            type='color' disabled={false} value={!renderedColors.includes(color) ? color : '#0b3142'} onChange={(e) => {
              setColor(e.target.value)
            }}
          />
        </TagBackground>
        <span>{locale.define_own_color}</span>
      </Label>
    </Holder>
  )
}

export default ColorList
