import * as MSAL from '@azure/msal-browser'
const msalOptions = {
  auth: {
    clientId: 'a74248fd-de5b-4feb-8389-7457015a9147'
  },
  cache: {
    cacheLocation: 'sessionStorage'
  }
}
export default new MSAL.PublicClientApplication(msalOptions)
