import React, { useEffect } from 'react'
import { Select } from './Input'
import api from '../redux/api'
import useRequest from '../hooks/useRequest'
import useDebounce from '../hooks/useDebounce'

const ObjectTypeDropdown = ({ setObjectType, label, type, defaultValue }) => {
  const [request, data, status] = useRequest(api.getObjectTypes, 'objectType')
  const [debouncedGetObjectTypesRequest] = useDebounce(request, 300)

  useEffect(() => {
    request({ query: [{ key: 'object_type_type', op: '=', value: type }], limit: 50, sort: 'name.asc' })
  }, [type, request])

  return (
    <Select
      name='object_type_id'
      label={label}
      disabled={false}
      defaultValue={defaultValue || { id: -1, name: '' }}
      request={(input) => {
        debouncedGetObjectTypesRequest({ query: [{ key: 'name', op: '~', value: input }, { key: 'object_type_type', op: '=', value: type }], limit: 50, sort: 'name.asc' })
      }}
      requestStatus={status === 'pending'}
      items={data}
      handleChange={(_, id, item) => {
        setObjectType(item)
      }}
    />
  )
}
export default ObjectTypeDropdown
