import React, { Fragment } from 'react'
import { styled, TR as Tr } from '@pergas-common/pergas-components'
import { css } from 'styled-components'

const Row = styled(Tr)`
  background: ${({ isSelected }) => isSelected ? '#DAE1E5' : '#f7f9fa'};
  box-shadow: ${({ isFirst, isLast }) => {
    if (isFirst && isLast) return 'inset 0 8px 8px -8px rgba(0, 0, 0, 0.23),inset  0 -8px 8px -8px rgba(0, 0, 0, 0.23)'
    if (isFirst) return 'inset 0 8px 8px -8px rgba(0, 0, 0, 0.23)'
    if (isLast) return 'inset  0 -8px 8px -8px rgba(0, 0, 0, 0.23)'
    return 'none'
  }}
`

const Holder = styled.td`
  border-left: 1px solid #e4e9f0;
`
const InnerHolder = styled.div`
  display: flex;
  flex-direction: row;
  height: 48px;
  &:first-child: {
    border-left: none;
  }
`
const InnerHolderOverflow = styled.div`
  height: 49px;
  overflow: hidden;
  flex: ${({ flex }) => flex};
  ${({ borderBottom, borderRight }) => {
    if (borderBottom && borderRight) {
 return css`
      border-right 1px solid #e4e9f0;
      border-bottom: 1px solid #e4e9f0
    `
}
    if (borderRight) {
 return css`
      border-right 1px solid #e4e9f0;
    `
}
    if (borderBottom) {
 return css`
      border-bottom: 1px solid #e4e9f0
    `
}
  }};
`

const Inner = styled.div`
  display: flex;
  height: 100%;
  min-width: 50px;
  margin: auto;
  justify-content: flex-start;
  align-items: center;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
`

const InnerChild = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 22px;
`

const ContentHolder = styled.div`
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
`

export const SubRow = ({
  subRowChildren,
  id,
  children,
  colSpan = 1,
  onClick,
  isSelected = false,
  isFirst = false,
  isLast = false
}) => {
  return (
    <Row onClick={onClick} key={id} isSelected={isSelected} isFirst={isFirst} isLast={isLast}>
      <Holder colSpan={colSpan}>
        <InnerHolder>
          {subRowChildren.map((child) => <Fragment key={child.key}>{child.element}</Fragment>)}
        </InnerHolder>
      </Holder>
      {children}
    </Row>
  )
}

export const SubRowContent = ({ children, flex = 1, borderBottom = true, borderRight = true }) => {
  return (
    <InnerHolderOverflow key={children} borderBottom={borderBottom} borderRight={borderRight} flex={flex}>
      <ContentHolder>
        <Inner>
          <InnerChild>{children}</InnerChild>
        </Inner>
      </ContentHolder>
    </InnerHolderOverflow>
  )
}
