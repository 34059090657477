import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Save } from '@mui/icons-material'
import { Button, Group, Layout, Link, Waves } from '@pergas-common/pergas-components'
import { Add, Remove } from '@pergas-common/pergas-icons'
import Input, { Textarea } from '../../Input.js'
import Page from '../Page.js'
import { useAddRoleMutation, useDeleteRoleMutation, useGetRoleByIdQuery, useUpdateRoleMutation } from '../../../redux/role/hooks.js'
import { selectLocale } from '../../../redux/locale/selectors.js'
import useUrlQuery from '../../../hooks/useUrlQuery.js'
import { RoleIcon } from '../../icons.js'
import RolesList from '../../RoleList.js'
import { DeleteItemV2 } from '../../dialogs/DeleteItem.js'

const RoleDetails = () => {
  const ref = useRef(null)
  const [remove, setRemove] = useState(false)
  const navigate = useNavigate()
  const query = useUrlQuery()
  const id = query.get('id')
  const locale = useSelector(selectLocale)
  const { data, isUnitialized, isLoading, isSuccessful } = useGetRoleByIdQuery(id)
  const { mutation: updateMutation, isLoading: isUpdating } = useUpdateRoleMutation()
  const { metadata, mutation: createMutation, isLoading: isCreating, isSuccessful: isCreated } = useAddRoleMutation()
  const { mutation: deleteMutation, isLoading: isDeleting, isSuccessful: isDeleted } = useDeleteRoleMutation()

  const pageMetadata = {
    title: locale.role,
    icon: <RoleIcon width={20} height={20} />,
    link: (
      <Link onClickHandler={() => {
        navigate('/roles/new', {
          replace: true
        })
      }}
      ><Add width={20} height={20} color='#28afe0' />
      </Link>
    )
  }

  const submit = (e) => {
    e.preventDefault()
    const payload = {}
    const formData = new FormData(ref.current)
    for (const [key, value] of formData.entries()) {
      payload[key] = value
    }
    if (id) {
      payload.id = Number(id)
      updateMutation(payload)
      return
    }
    createMutation(payload)
  }

  useEffect(() => {
    if (metadata?.id) {
      navigate(`/roles/edit?id=${metadata?.id}`, { replace: true })
    }
  }, [metadata, isCreated])

  useEffect(() => {
    if (isDeleted) {
      navigate('/roles', { replace: true })
    }
  }, [isDeleted])

  return (
    <Page metadata={pageMetadata}>
      <Layout.Aside>
        <RolesList />
      </Layout.Aside>
      <Layout.Section>
        <Group.Button>
          <Button
            type='button' disabled={!id || data?.read_only} onClick={() => {
              setRemove(true)
            }}
          >
            {isDeleting ? <Waves width={20} height={20} /> : <Remove width={20} height={20} color='#FF5656' />}<span>{locale.delete}</span>
          </Button>
          <Button type='submit' form='role'>{(isUpdating || isCreating) ? <Waves width={20} height={20} /> : <Save style={{ color: '#34b3e1', width: 20, height: 20 }} />}<span>{locale.save}</span></Button>
        </Group.Button>
        {(isSuccessful || isUnitialized) && (
          <form onSubmit={submit} id='role' ref={ref}>
            <Input name='name' label={locale.name} defaultValue={data?.name} />
            <Textarea name='description' label={locale.description} defaultValue={data?.description} />
          </form>
        )}
        {(isLoading && !isUnitialized) && (
          <Waves width={48} height={48} />
        )}
      </Layout.Section>
      {remove && <DeleteItemV2
        text={`${data?.name}`}
        isOpen={remove}
        onCloseRequest={() => {
          setRemove(false)
        }}
        onSubmit={() => {
          deleteMutation({ id })
          setRemove(false)
        }}
                 />}

    </Page>
  )
}

export default RoleDetails
