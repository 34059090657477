import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Save } from '@mui/icons-material'
import { Button, DropdownSelect, Group, Layout, Link, Waves, media, spacing, styled } from '@pergas-common/pergas-components'
import { Add, Remove } from '@pergas-common/pergas-icons'
import Input, { Textarea } from '../../Input.js'
import Page from '../Page.js'
import { selectLocale } from '../../../redux/locale/selectors.js'
import useUrlQuery from '../../../hooks/useUrlQuery.js'
import Divider from '../../Divider.js'
import { DeleteItemV2 } from '../../dialogs/DeleteItem'
import { ObjectTypeIcon } from '../../icons.js'
import { useAddObjectTypeMutation, useDeleteObjectTypeMutation, useGetObjectTypesByIdQuery, useUpdateObjectTypeMutation } from '../../../redux/object-type/hooks.js'
import { sortArray } from '../../../util.js'
import ObjectTypesList from '../../ObjectTypesList.js'
import ColorList from '../../ColorList.js'

const DropdownHolder = styled.div`
  width: 100%;
  max-width: 320px;
  background: ${({ theme }) => theme.palette.common.primary};
`

const StyledForm = styled.form`
  display: flex;
  max-width: 800px;
  flex-direction: column;
  align-items: stretch;

  ${media.medium`
    flex-direction: row;
  `}

  > div:first-child {
    max-width: 320px;
    width: 100%;
  }
  > div:last-child {
    padding-left: ${spacing(4)};
  }
`

const Form = ({ id, onSubmit, data }) => {
  const ref = useRef(null)
  const { state } = useLocation()
  const locale = useSelector(selectLocale)
  const defaultObjectType = {
    id: data?.object_type_type || state?.object_type_type || 'employee',
    name: data?.name || state?.name || locale.employee,
    color: data?.color || state?.color || '#0b3142'
  }

  const objectTypeTypes = sortArray([
    { id: 'contact', name: locale.contact },
    { id: 'department', name: locale.department },
    { id: 'project', name: locale.project },
    { id: 'category', name: locale.category },
    { id: 'contact_person', name: locale.contact_person },
    { id: 'employee', name: locale.employee }
  ], 'name')

  const [objectType, setObjectType] = useState(defaultObjectType)

  const submit = (e) => {
    e.preventDefault()
    const payload = {}
    const formData = new FormData(ref.current)
    for (const [key, value] of formData.entries()) {
      /* we are doing some magical things with input type color and radio buttons.
      This is required to force initial checked state to a color and not a boolean string
      */
      if (key === 'color') {
        payload.color = objectType.color
      } else {
        payload[key] = value
      }
      if (id) {
        payload.id = Number(id)
      }
    }
    payload.object_type_type = objectType.id

    onSubmit(payload)
  }
  return (
    <StyledForm onSubmit={submit} id='objectType' ref={ref}>
      <div>
        <Input name='name' label={locale.name} defaultValue={data?.name} />
        <Textarea name='description' label={locale.description} defaultValue={data?.description} />
        <Divider />
        <DropdownHolder>
          <DropdownSelect
            name='object_type_type'
            disabled={id}
            buttonLabel={objectTypeTypes.find(({ id }) => objectType?.id === id).name}
            items={objectTypeTypes.map(({ name }) => name)}
            handleSelectedItemChange={(item) => {
              const selected = objectTypeTypes.find(({ name }) => name === item.selectedItem)
              setObjectType({
                ...selected,
                color: '#0b3142'
              })
            }}
          />
        </DropdownHolder>
      </div>
      <div>
        <ColorList
          color={objectType.color} setColor={(color) => {
            setObjectType((prev) => {
              return {
                ...prev,
                color
              }
            })
          }}
        />
      </div>
    </StyledForm>
  )
}

const ObjectTypeDetails = () => {
  const [remove, setRemove] = useState(false)
  const navigate = useNavigate()
  const query = useUrlQuery()
  const id = query.get('id')
  const locale = useSelector(selectLocale)
  const { data, isUnitialized, isLoading, isSuccessful } = useGetObjectTypesByIdQuery(id)
  const { mutation: updateMutation, isLoading: isUpdating } = useUpdateObjectTypeMutation()
  const { metadata, mutation: createMutation, isLoading: isCreating, isSuccessful: isCreated } = useAddObjectTypeMutation()
  const { mutation: deleteMutation, isLoading: isDeleting, isSuccessful: isDeleted } = useDeleteObjectTypeMutation()

  const pageMetadata = {
    title: locale.object_type,
    icon: <ObjectTypeIcon width={20} height={20} />,
    link: (
      <Link onClickHandler={() => {
        navigate('/object-types/new', {
          replace: true,
          state: {
            collection_id: data?.collection_id,
            collection_name: data?.collection_name,
            object_type_id: data?.object_type_id,
            object_type_name: data?.object_type_name,
            tags: data?.tags
          }
        })
      }}
      ><Add width={20} height={20} color='#28afe0' />
      </Link>
    )
  }

  const submit = (payload) => {
    if (id) {
      updateMutation(payload)
      return
    }
    createMutation(payload)
  }

  useEffect(() => {
    if (metadata?.id) {
      navigate(`/object-types/edit?id=${metadata?.id}`, { replace: true })
    }
  }, [metadata, isCreated])

  useEffect(() => {
    if (isDeleted) {
      navigate('/object-types', { replace: true })
    }
  }, [isDeleted])

  return (
    <Page metadata={pageMetadata}>
      <Layout.Aside>
        <ObjectTypesList />
      </Layout.Aside>
      <Layout.Section>
        <Group.Button>
          <Button
            type='button' disabled={!id || data?.read_only} onClick={() => {
              setRemove(true)
            }}
          >
            {isDeleting ? <Waves width={20} height={20} /> : <Remove width={20} height={20} color='#FF5656' />}<span>{locale.delete}</span>
          </Button>
          <Button type='submit' form='objectType'>{(isUpdating || isCreating) ? <Waves width={20} height={20} /> : <Save style={{ color: '#34b3e1', width: 20, height: 20 }} />}<span>{locale.save}</span></Button>
        </Group.Button>
        {(isSuccessful || isUnitialized) && (
          <Form id={id} data={data} onSubmit={submit} key={id} />
        )}
        {(isLoading && !isUnitialized) && (
          <Waves width={48} height={48} />
        )}
      </Layout.Section>
      {remove && <DeleteItemV2
        text={`${data?.name}`}
        isOpen={remove}
        onCloseRequest={() => {
          setRemove(false)
        }}
        onSubmit={() => {
          deleteMutation({ id })
          setRemove(false)
        }}
                 />}

    </Page>
  )
}

export default ObjectTypeDetails
