import React from 'react'
import { connect } from 'react-redux'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader
} from '@mui/material'
import { AddItemIcon, DeleteItemIcon } from '../icons'
import ColoredTag from '../ColoredTag'
import { sortArray } from '../../util'

/**
 * Renders a list of tags.
 * The component has an add button which takes a handler.
 * Each row in the list has a delete button and will call
 * back with the updated array so the parent component can
 * update its state.
 */
const TagList = ({
  items,
  allTags,
  onAddClick,
  setTags,
  strings
}) => {
  // TODO refactor list styles
  const listItemStyle = {
    width: '250px',
    height: '50px',
    marginLeft: '30px',
    paddingLeft: '0px',
    borderBottom: '1px solid #707070'
  }
  const listItemTextStyle = {
    borderBottom: '1px solid #707070'
  }

  const handleDelete = (tag) => {
    const copy = items.slice(0)
    const index = copy.findIndex(t => t.tag_id === tag.tag_id)
    if (index !== -1) {
      copy.splice(index, 1)
    }
    setTags(copy)
  }
  const list = sortArray(items, 'name').map(tag => {
    return (
      <ListItem style={listItemStyle} key={tag.tag_id}>
        <ColoredTag name={tag.name} color={tag.color} />
        <ListItemSecondaryAction>
          <IconButton onClick={handleDelete.bind(null, tag)} edge='end' aria-label='Delete'>
            <DeleteItemIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    )
  })

  const disableAdd = allTags.every(tag => {
    return !!items.find(t => t.tag_id === tag.id)
  })

  const subheader = (
    <ListSubheader component='div'>
      <ListItemText style={listItemTextStyle} primary={strings.add_tags} />
      <ListItemSecondaryAction>
        <IconButton
          disabled={disableAdd}
          onClick={onAddClick}
          edge='end'
          aria-label='Add'
        >
          <AddItemIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListSubheader>
  )

  return (<List subheader={subheader}>{list}</List>)
}

const mapStateToProps = (state) => {
  return {
    strings: state.locale.strings
  }
}

export default connect(mapStateToProps)(TagList)
