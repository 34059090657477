const en = {
  account: 'User',
  accounts: 'Users',
  add: 'add',
  add_tag: 'Add Tag',
  add_tags: 'Add Tags',
  address: 'Address',
  admin: 'Admin',
  all: 'All',
  activities: 'Activities',
  assigned: 'Assigned',
  back: 'Back',
  cancel: 'Cancel',
  intranet: 'Intranet',
  category: 'Intranet Folder',
  category_object_type: 'Intranet Folder Type',
  categories: 'Intranet Folders',
  categories_in_same_intranet: 'Intranet Folders in same intranet',
  city: 'City',
  contact: 'Corporation',
  contact_object_type: 'Corporation type',
  contact_person: 'Contact Person',
  contact_person_object_type: 'Contact Person type',
  contact_persons: 'Contact Persons',
  contact_persons_in_same_contact: 'Contact persons in same corporation',
  contact_role: 'Contact (role)',
  contact_role_add: 'Add Contact/Role',
  contacts: 'Corporations',
  content_type_name: 'Content Type Name',
  content_types: 'Content Types',
  create: 'Create',
  created_at: 'Created',
  define_own_color: 'Define own color',
  delete: 'Delete',
  department: 'Intranet Section',
  department_object_type: 'Intranet Section Type',
  departments: 'Intranet Sections',
  description: 'Description',
  documents: 'Documents',
  email: 'E-mail',
  employee: 'Employee',
  employee_object_type: 'Employee Type',
  employees: 'Employees',
  external_contact_persons: 'External contact persons',
  external_contacts: 'External contacts',
  external_id: 'External id',
  contact_no_contact_persons: 'No contact persons',
  expires_at: 'Expire Date',
  estimate_value: 'Estimate',
  explainlogin: 'Contact your PDS administrator if you can\'t log in.',
  favorite: 'Favorite',
  field_internal_name: 'Field Name',
  field_type_as_string: 'Field Type',
  field_id: 'Field Id',
  field_title: 'Field Title',
  finished: 'Finished',
  first_name: 'First Name',
  gdpr: 'GDPR',
  handle: 'Handle',
  homepage: 'Homepage',
  id: 'Id',
  is_logging_in: 'Logging in...',
  last_name: 'Last Name',
  latest: 'latest',
  logout: 'Log out',
  logout_confirm: 'Are you sure you would like to sign out?',
  login_with_microsoft: 'Sign in with Microsoft',
  mine: 'Mine',
  minutes: 'min',
  missing_content_page: 'Content is missing for this page',
  name: 'Name',
  no: 'No',
  object_type: 'Object Type',
  object_type_type: 'Specific type',
  object_types: 'Object Types',
  office: 'Office',
  ok: 'Ok',
  password: 'Password',
  permission: 'Permissions',
  person: 'Person',
  person_role: 'Person (role)',
  person_role_add: 'Add Person/Role',
  phone: 'Phone',
  phone_number: 'Phone number',
  postal_code: 'ZIP code',
  project: 'Project',
  projects: 'Projects',
  project_name: 'Project name',
  project_object_type: 'Project type',
  project_participants: 'Project participants',
  project_responsible: 'Project manager',
  project_role: 'Project role',
  project_tasks: 'Project tasks',
  reallydelete: 'Deleting data can cause removal of related data and can\'t be undone. Are you sure you want to delete',
  read: 'Read',
  relogin_in: 'Re-login in',
  responsible: 'Responsible',
  contact_responsible: 'Contact responsible',
  role: 'Role',
  roles: 'Roles',
  rows_per_page: 'Rows per page:',
  save: 'Save',
  search: 'Search',
  settings: 'Settings',
  share: 'Share',
  sharepoint: 'SharePoint',
  should_be_finished_by: 'Should be finished by',
  started_at: 'Start Date',
  startpage: 'home',
  status: 'Status',
  tag: 'Tag',
  tags: 'Tags',
  tag_type: 'Tag Type',
  task: 'Task',
  tasks: 'Tasks',
  tasks_no_tasks: 'No tasks',
  tasks_in_same_project: 'Tasks in the same project',
  teams: 'Teams',
  teams_url: 'Teams Url',
  create_teams: 'Create teams',
  tenant_name: 'Tenant',
  template_manager: 'Template manager',
  ticket: 'Ticket',
  title: 'Title',
  tool_belt: 'Tools',
  update: 'Update',
  updated_at: 'Updated',
  url: 'Url',
  username: 'User',
  vat_no: 'VAT Number',
  version: 'Version',
  warning: 'Warning',
  welcome: 'welcome',
  yes: 'Yes',

  // Admin errors

  ERROR_TYPE_ACCOUNT_ADD: 'Failed to add account.',
  ERROR_TYPE_ACCOUNT_GET: 'Failed to get accounts.',
  ERROR_TYPE_ACCOUNT_DELETE: 'Failed to delete account.',
  ERROR_TYPE_ACCOUNT_NOT_FOUND: 'Could not find account.',
  ERROR_TYPE_ACCOUNT_UPDATE: 'Failed to update account.',
  ERROR_TYPE_ACCOUNT_NO_ADD_PERMISSION: 'Not enough permissions to add account.',
  ERROR_TYPE_ACCOUNT_NO_GET_PERMISSION: 'Not enough permissions to read account.',
  ERROR_TYPE_ACCOUNT_NO_UPDATE_PERMISSION: 'Not enough permissions to update account.',
  ERROR_TYPE_ACCOUNT_NO_DELETE_PERMISSION: 'Not enough permissions to delete account.',

  ERROR_TYPE_PERMISSION_GET: 'Failed to get permissions.',
  ERROR_TYPE_PERMISSION_NOT_FOUND: 'Could not find permission.',
  ERROR_TYPE_PERMISSION_NO_GET_PERMISSION: 'Not enough permissions to read permission.',

  ERROR_TYPE_TENANT_GET: 'Failed to get tenants.',
  ERROR_TYPE_TENANT_NOT_FOUND: 'Could not find tenant.',
  ERROR_TYPE_TENANT_NO_GET_PERMISSION: 'Not enough permissions to read tenants.',

  // User errors

  ERROR_TYPE_CATEGORY_ADD: 'Failed to add intranet folder.',
  ERROR_TYPE_CATEGORY_GET: 'Failed to get intranet folders.',
  ERROR_TYPE_CATEGORY_DELETE: 'Failed to delete intranet folder.',
  ERROR_TYPE_CATEGORY_CONFLICT: 'Could not update intranet folder right now. Please try again later.',
  ERROR_TYPE_CATEGORY_NOT_FOUND: 'Could not find intranet folder.',
  ERROR_TYPE_CATEGORY_UPDATE: 'Failed to update intranet folder.',
  ERROR_TYPE_CATEGORY_NO_ADD_PERMISSION: 'Not enough permissions to add intranet folder.',
  ERROR_TYPE_CATEGORY_NO_GET_PERMISSION: 'Not enough permissions to read intranet folder.',
  ERROR_TYPE_CATEGORY_NO_UPDATE_PERMISSION: 'Not enough permissions to update intranet folder.',
  ERROR_TYPE_CATEGORY_NO_DELETE_PERMISSION: 'Not enough permissions to delete intranet folder.',

  ERROR_TYPE_CONTACT_ADD: 'Failed to add contact.',
  ERROR_TYPE_CONTACT_GET: 'Failed to get contacts.',
  ERROR_TYPE_CONTACT_DELETE: 'Failed to delete contact.',
  ERROR_TYPE_CONTACT_CONFLICT: 'Could not update contact right now. Please try again later.',
  ERROR_TYPE_CONTACT_NOT_FOUND: 'Could not find contact.',
  ERROR_TYPE_CONTACT_UPDATE: 'Failed to update contact.',
  ERROR_TYPE_CONTACT_NO_ADD_PERMISSION: 'Not enough permissions to add contact.',
  ERROR_TYPE_CONTACT_NO_GET_PERMISSION: 'Not enough permissions to read contact.',
  ERROR_TYPE_CONTACT_NO_UPDATE_PERMISSION: 'Not enough permissions to update contact.',
  ERROR_TYPE_CONTACT_NO_DELETE_PERMISSION: 'Not enough permissions to delete contact.',

  ERROR_TYPE_CONTACT_PERSON_ADD: 'Failed to add contact person.',
  ERROR_TYPE_CONTACT_PERSON_GET: 'Failed to get contact persons.',
  ERROR_TYPE_CONTACT_PERSON_DELETE: 'Failed to delete contact person.',
  ERROR_TYPE_CONTACT_PERSON_NOT_FOUND: 'Could not find contact person.',
  ERROR_TYPE_CONTACT_PERSON_UPDATE: 'Failed to update contact person.',
  ERROR_TYPE_CONTACT_PERSON_NO_ADD_PERMISSION: 'Not enough permissions to add contact person.',
  ERROR_TYPE_CONTACT_PERSON_NO_GET_PERMISSION: 'Not enough permissions to read contact person.',
  ERROR_TYPE_CONTACT_PERSON_NO_UPDATE_PERMISSION: 'Not enough permissions to update contact person.',
  ERROR_TYPE_CONTACT_PERSON_NO_DELETE_PERMISSION: 'Not enough permissions to delete contact person. ',

  ERROR_TYPE_CONTENT_TYPE_GET: 'Failed to get content types.',
  ERROR_TYPE_CONTENT_TYPE_NOT_FOUND: 'Could not find content type.',
  ERROR_TYPE_CONTENT_TYPE_NO_GET_PERMISSION: 'Not enough permissions to read content types.',

  ERROR_TYPE_DEPARTMENT_ADD: 'Failed to add intranet section.',
  ERROR_TYPE_DEPARTMENT_GET: 'Failed to get intranet sections.',
  ERROR_TYPE_DEPARTMENT_DELETE: 'Failed to delete intranet section.',
  ERROR_TYPE_DEPARTMENT_CONFLICT: 'Could not update intranet section right now. Please try again later.',
  ERROR_TYPE_DEPARTMENT_NOT_FOUND: 'Could not find intranet section.',
  ERROR_TYPE_DEPARTMENT_UPDATE: 'Failed to update intranet sections.',
  ERROR_TYPE_DEPARTMENT_NO_ADD_PERMISSION: 'Not enough permissions to add intranet section.',
  ERROR_TYPE_DEPARTMENT_NO_GET_PERMISSION: 'Not enough permissions to read intranet section.',
  ERROR_TYPE_DEPARTMENT_NO_UPDATE_PERMISSION: 'Not enough permissions to update intranet section.',
  ERROR_TYPE_DEPARTMENT_NO_DELETE_PERMISSION: 'Not enough permissions to delete intranet section.',

  ERROR_TYPE_DOC_LIB_GET: 'Failed to get files from document library.',
  ERROR_TYPE_DOC_LIB_NO_ACCESS: 'You don\'t have permission to view these files.',

  ERROR_TYPE_EMPLOYEE_ADD: 'Failed to add employee.',
  ERROR_TYPE_EMPLOYEE_GET: 'Failed to get employees.',
  ERROR_TYPE_EMPLOYEE_DELETE: 'Failed to delete employee.',
  ERROR_TYPE_EMPLOYEE_NOT_FOUND: 'Could not find employee.',
  ERROR_TYPE_EMPLOYEE_UPDATE: 'Failed to update employee.',
  ERROR_TYPE_EMPLOYEE_NO_ADD_PERMISSION: 'Not enough permissions to add employee.',
  ERROR_TYPE_EMPLOYEE_NO_GET_PERMISSION: 'Not enough permissions to read employee.',
  ERROR_TYPE_EMPLOYEE_NO_UPDATE_PERMISSION: 'Not enough permissions to update employee.',
  ERROR_TYPE_EMPLOYEE_NO_DELETE_PERMISSION: 'Not enough permissions to delete employee.',

  ERROR_TYPE_OBJECT_TYPE_ADD: 'Failed to add object type.',
  ERROR_TYPE_OBJECT_TYPE_GET: 'Failed to get object types.',
  ERROR_TYPE_OBJECT_TYPE_DELETE: 'Failed to delete object type.',
  ERROR_TYPE_OBJECT_TYPE_NOT_FOUND: 'Could not find object type.',
  ERROR_TYPE_OBJECT_TYPE_UPDATE: 'Failed to update object type.',
  ERROR_TYPE_OBJECT_TYPE_NO_ADD_PERMISSION: 'Not enough permissions to add object type.',
  ERROR_TYPE_OBJECT_TYPE_NO_GET_PERMISSION: 'Not enough permissions to read object type.',
  ERROR_TYPE_OBJECT_TYPE_NO_UPDATE_PERMISSION: 'Not enough permissions to update object type.',
  ERROR_TYPE_OBJECT_TYPE_NO_DELETE_PERMISSION: 'Not enough permissions to delete object type.',

  ERROR_TYPE_PROJECT_ADD: 'Failed to add project.',
  ERROR_TYPE_PROJECT_GET: 'Failed to get projects.',
  ERROR_TYPE_PROJECT_DELETE: 'Failed to delete project.',
  ERROR_TYPE_PROJECT_CONFLICT: 'Could not update project right now. Please try again later.',
  ERROR_TYPE_PROJECT_NOT_FOUND: 'Could not find project.',
  ERROR_TYPE_PROJECT_UPDATE: 'Failed to update project.',
  ERROR_TYPE_PROJECT_NO_ADD_PERMISSION: 'Not enough permissions to add project.',
  ERROR_TYPE_PROJECT_NO_GET_PERMISSION: 'Not enough permissions to read project.',
  ERROR_TYPE_PROJECT_NO_UPDATE_PERMISSION: 'Not enough permissions to update project.',
  ERROR_TYPE_PROJECT_NO_DELETE_PERMISSION: 'Not enough permissions to delete project.',

  ERROR_TYPE_ROLE_ADD: 'Failed to add role.',
  ERROR_TYPE_ROLE_GET: 'Failed to get roles.',
  ERROR_TYPE_ROLE_DELETE: 'Failed to delete role.',
  ERROR_TYPE_ROLE_NOT_FOUND: 'Could not find role.',
  ERROR_TYPE_ROLE_UPDATE: 'Failed to update role.',
  ERROR_TYPE_ROLE_NO_ADD_PERMISSION: 'Not enough permissions to add role.',
  ERROR_TYPE_ROLE_NO_GET_PERMISSION: 'Not enough permissions to read role.',
  ERROR_TYPE_ROLE_NO_UPDATE_PERMISSION: 'Not enough permissions to update role.',
  ERROR_TYPE_ROLE_NO_DELETE_PERMISSION: 'Not enough permissions to delete role.',

  ERROR_TYPE_TAG_ADD: 'Failed to add tag.',
  ERROR_TYPE_TAG_GET: 'Failed to get tags.',
  ERROR_TYPE_TAG_DELETE: 'Failed to delete tag.',
  ERROR_TYPE_TAG_NOT_FOUND: 'Could not find tag.',
  ERROR_TYPE_TAG_UPDATE: 'Failed to update tag.',
  ERROR_TYPE_TAG_NO_ADD_PERMISSION: 'Not enough permissions to add tag.',
  ERROR_TYPE_TAG_NO_GET_PERMISSION: 'Not enough permissions to read tag.',
  ERROR_TYPE_TAG_NO_UPDATE_PERMISSION: 'Not enough permissions to update tag.',
  ERROR_TYPE_TAG_NO_DELETE_PERMISSION: 'Not enough permissions to delete tag.',

  ERROR_TYPE_TEAMS_NO_PAGE: 'The group doesn\'t have a teams page.',

  ERROR_TYPE_TICKET_ADD: 'Failed to add ticket.',
  ERROR_TYPE_TICKET_GET: 'Failed to get tickets.',
  ERROR_TYPE_TICKET_DELETE: 'Failed to delete ticket.',
  ERROR_TYPE_TICKET_NOT_FOUND: 'Could not find ticket.',
  ERROR_TYPE_TICKET_UPDATE: 'Failed to update ticket.',
  ERROR_TYPE_TICKET_NO_ADD_PERMISSION: 'Not enough permissions to add ticket.',
  ERROR_TYPE_TICKET_NO_GET_PERMISSION: 'Not enough permissions to read ticket.',
  ERROR_TYPE_TICKET_NO_UPDATE_PERMISSION: 'Not enough permissions to update ticket.',
  ERROR_TYPE_TICKET_NO_DELETE_PERMISSION: 'Not enough permissions to delete ticket.',

  ERROR_TYPE_AUTH: 'Authentication error. Try to login again.',
  ERROR_TYPE_LOGIN: 'Failed to login.',
  LOGIN_ERROR_ID_TOKEN: 'Could not authenticate with Microsoft. Please make sure you are using a Microsoft account and that popups are enabled in the browser and try again.',
  LOGIN_ERROR_API_TOKEN: 'Contact your administrator and make sure you have an account registered with PDS. Please logout and try again with an enabled account.',
  LOGIN_ERROR_SHAREPOINT_TOKEN: 'Could not get access to SharePoint. Please logout and try again.',
  LOGIN_ERROR_GRAPH_TOKEN: 'Could not get access to Microsoft Graph. Please logout and try again.',

  ERROR_TYPE_NETWORK: 'Connection refused. Make sure you have a working internet connection.',
  ERROR_TYPE_UNKNOWN: 'Unknown error. Please try again later.'
}

const sv = {
  account: 'Användare',
  accounts: 'Användare',
  add: 'lägg till',
  add_tag: 'Lägg till tagg',
  add_tags: 'Lägg till taggar',
  address: 'Adress',
  admin: 'Admin',
  all: 'Alla',
  activities: 'Aktiviteter',
  assigned: 'Tilldelat',
  back: 'Tillbaka',
  cancel: 'Avbryt',
  intranet: 'Intranät',
  category: 'Intranätsmapp',
  category_object_type: 'Intranätsmappstyp',
  categories: 'Intranätsmappar',
  categories_in_same_intranet: 'Intranätsmappar i samma intranät',
  city: 'Stad',
  contact: 'Bolag',
  contact_object_type: 'Bolagstyp',
  contact_person: 'Kontaktperson',
  contact_person_object_type: 'Kontaktpersonstyp',
  contact_persons: 'Kontaktpersoner',
  contact_persons_in_same_contact: 'Kontaktpersoner i samma bolag',
  contact_role: 'Kontakt (roll)',
  contact_role_add: 'Lägg till kontakt/roll',
  contacts: 'Bolag',
  content_type_name: 'Innehållstypsnamn',
  content_types: 'Innehållstyper',
  create: 'Skapa',
  created_at: 'Skapad',
  define_own_color: 'Definera egen färg',
  delete: 'Radera',
  department: 'Intranätsdel',
  department_object_type: 'Intranätsdelstyp',
  departments: 'Intranätsdelar',
  description: 'Beskrivning',
  documents: 'Dokument',
  email: 'Mejl',
  employee: 'Anställd',
  employee_object_type: 'Anställningstyp',
  employees: 'Anställda',
  external_contact_persons: 'Externa kontaktpersoner',
  external_contacts: 'Externa bolag',
  external_id: 'Externt id',
  contact_no_contact_persons: 'Inga kontaktpersoner',
  expires_at: 'Förfallodatum',
  estimate_value: 'Estimat',
  explainlogin: 'Kontakta din administratör för PDS om du inte kan logga in.',
  favorite: 'Favorit',
  field_internal_name: 'Fältnamn',
  field_type_as_string: 'Fälttyp',
  field_id: 'Fältid',
  field_title: 'Fälttitel',
  finished: 'Slutförd',
  first_name: 'Förnamn',
  gdpr: 'GDPR',
  handle: 'Hantera',
  homepage: 'Hemsida',
  id: 'Id',
  is_logging_in: 'Loggar in...',
  last_name: 'Efternamn',
  latest: 'senaste',
  logout: 'Logga ut',
  logout_confirm: 'Är du säker på att du vill logga ut?',
  login_with_microsoft: 'Logga in med Microsoft',
  mine: 'Mina',
  minutes: 'min',
  missing_content_page: 'Det saknas innehåll för den här sidan',
  name: 'Namn',
  no: 'Nej',
  object_type: 'Objekttyp',
  object_type_type: 'Specifik typ',
  object_types: 'Objekttyper',
  office: 'Kontor',
  ok: 'Ok',
  password: 'Lösenord',
  permission: 'Behörighet',
  person: 'Person',
  person_role: 'Person (roll)',
  person_role_add: 'Lägg till person/roll',
  phone: 'Telefon',
  phone_number: 'Telefonnummer',
  postal_code: 'Postnr.',
  project: 'Projekt',
  projects: 'Projekt',
  project_name: 'Projektnamn',
  project_object_type: 'Projekttyp',
  project_participants: 'Projektdeltagare',
  project_responsible: 'Projektansvarig',
  project_role: 'Projektroll',
  project_tasks: 'Projektuppgifter',
  reallydelete: 'Borttagande av data kan orsaka att relaterat data också försvinner och kan inte göras ogjort. Är du säker på att du vill radera',
  read: 'Läsa',
  relogin_in: 'Loggar in igen om',
  responsible: 'Ansvarig',
  contact_responsible: 'Bolagsansvarig',
  role: 'Roll',
  roles: 'Roller',
  rows_per_page: 'Rader per sida:',
  save: 'Spara',
  search: 'Sök',
  settings: 'Inställningar',
  share: 'Dela',
  sharepoint: 'SharePoint',
  should_be_finished_by: 'Skall vara slutförd',
  started_at: 'Startdatum',
  startpage: 'Startsida',
  status: 'Status',
  tag: 'Tagg',
  tags: 'Taggar',
  tag_type: 'Taggtyp',
  task: 'Uppgift',
  tasks: 'Uppgifter',
  tasks_no_tasks: 'Inga uppgifter',
  tasks_in_same_project: 'Uppgifter i samma projekt',
  teams: 'Teams',
  teams_url: 'Teams url',
  create_teams: 'Skapa teams',
  tenant_name: 'Tenant',
  template_manager: 'Mallhanterare',
  ticket: 'Ticket',
  title: 'Titel',
  tool_belt: 'Verktygsfält',
  update: 'Ändra',
  updated_at: 'Ändrad',
  url: 'Url',
  username: 'Användarnamn',
  vat_no: 'Momsnummer',
  version: 'Version',
  warning: 'Varning',
  welcome: 'välkommen',
  yes: 'Ja',

  // Admin errors

  ERROR_TYPE_ACCOUNT_ADD: 'Kunde inte lägga till användare.',
  ERROR_TYPE_ACCOUNT_GET: 'Kunde inte hämta användare.',
  ERROR_TYPE_ACCOUNT_DELETE: 'Kunde inte ta bort användaren.',
  ERROR_TYPE_ACCOUNT_NOT_FOUND: 'Användare saknas.',
  ERROR_TYPE_ACCOUNT_UPDATE: 'Kunde inte ändra användaren.',
  ERROR_TYPE_ACCOUNT_NO_ADD_PERMISSION: 'Inte tillräcklig behörighet att lägga till användare.',
  ERROR_TYPE_ACCOUNT_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa användare.',
  ERROR_TYPE_ACCOUNT_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra användare.',
  ERROR_TYPE_ACCOUNT_NO_DELETE_PERMISSION: 'Inte tillräcklig behörighet att ta bort användare.',

  ERROR_TYPE_PERMISSION_GET: 'Kunde inte hämta behörigheter.',
  ERROR_TYPE_PERMISSION_NOT_FOUND: 'Behörigheten saknas.',
  ERROR_TYPE_PERMISSION_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa behörigheter.',

  ERROR_TYPE_TENANT_GET: 'Kunde inte hämta tenants.',
  ERROR_TYPE_TENANT_NOT_FOUND: 'Tenant saknas.',
  ERROR_TYPE_TENANT_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa tenants.',

  // User errors

  ERROR_TYPE_CATEGORY_ADD: 'Kunde inte lägga till intranätsmapp.',
  ERROR_TYPE_CATEGORY_GET: 'Kunde inte hämta intranätsmappar.',
  ERROR_TYPE_CATEGORY_DELETE: 'Kunde inte ta bort intranätsmapp.',
  ERROR_TYPE_CATEGORY_CONFLICT: 'Kunde inte ändra intranätsmapp just nu. Var god försök igen om en stund.',
  ERROR_TYPE_CATEGORY_NOT_FOUND: 'Intranätsmapp saknas.',
  ERROR_TYPE_CATEGORY_UPDATE: 'Kunde inte ändra intranätsmapp.',
  ERROR_TYPE_CATEGORY_NO_ADD_PERMISSION: 'Inte tillräcklig behörighet att lägga till intranätsmapp.',
  ERROR_TYPE_CATEGORY_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa intranätsmapp.',
  ERROR_TYPE_CATEGORY_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra intranätsmapp.',
  ERROR_TYPE_CATEGORY_NO_DELETE_PERMISSION: 'Inte tillräcklig behörighet att ta bort intranätsmapp.',

  ERROR_TYPE_CONTACT_ADD: 'Kunde inte lägga till kontakten.',
  ERROR_TYPE_CONTACT_GET: 'Kunde inte hämta kontakter.',
  ERROR_TYPE_CONTACT_DELETE: 'Kunde inte ta bort kontakten.',
  ERROR_TYPE_CONTACT_CONFLICT: 'Kunde inte ändra kontakten just nu. Var god försök igen om en stund.',
  ERROR_TYPE_CONTACT_NOT_FOUND: 'Kontakten saknas.',
  ERROR_TYPE_CONTACT_UPDATE: 'Kunde inte ändra kontakten.',
  ERROR_TYPE_CONTACT_NO_ADD_PERMISSION: 'Inte tillräcklig behörighet att lägga till kontakt.',
  ERROR_TYPE_CONTACT_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa kontakt.',
  ERROR_TYPE_CONTACT_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra kontakt.',
  ERROR_TYPE_CONTACT_NO_DELETE_PERMISSION: 'Inte tillräcklig behörighet att ta bort kontakt.',

  ERROR_TYPE_CONTACT_PERSON_ADD: 'Kunde inte lägga till kontaktpersonen.',
  ERROR_TYPE_CONTACT_PERSON_GET: 'Kunde inte hämta kontaktpersoner.',
  ERROR_TYPE_CONTACT_PERSON_DELETE: 'Kunde inte ta bort kontaktpersonen.',
  ERROR_TYPE_CONTACT_PERSON_NOT_FOUND: 'Kontaktpersonen saknas.',
  ERROR_TYPE_CONTACT_PERSON_UPDATE: 'Kunde inte ändra kontaktpersonen.',
  ERROR_TYPE_CONTACT_PERSON_NO_ADD_PERMISSION: 'Inte tillräcklig behörighet att lägga till kontaktperson.',
  ERROR_TYPE_CONTACT_PERSON_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa kontaktperson.',
  ERROR_TYPE_CONTACT_PERSON_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra kontaktperson.',
  ERROR_TYPE_CONTACT_PERSON_NO_DELETE_PERMISSION: 'Inte tillräcklig behörighet att ta bort kontaktperson.',

  ERROR_TYPE_CONTENT_TYPE_GET: 'Kunde inte hämta innehållstyper.',
  ERROR_TYPE_CONTENT_TYPE_NOT_FOUND: 'Innehållstypen saknas.',
  ERROR_TYPE_CONTENT_TYPE_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa innehållstyper.',

  ERROR_TYPE_DEPARTMENT_ADD: 'Kunde inte lägga till intranätsdelen.',
  ERROR_TYPE_DEPARTMENT_GET: 'Kunde inte hämta intranätsdelar.',
  ERROR_TYPE_DEPARTMENT_DELETE: 'Kunde inte ta bort intranätsdelen.',
  ERROR_TYPE_DEPARTMENT_CONFLICT: 'Kunde inte ändra intranätsdelen just nu. Var god försök igen om en stund.',
  ERROR_TYPE_DEPARTMENT_NOT_FOUND: 'Intranätsdelen saknas.',
  ERROR_TYPE_DEPARTMENT_UPDATE: 'Kunde inte ändra intranätsdelen.',
  ERROR_TYPE_DEPARTMENT_NO_ADD_PERMISSION: 'Inte tillräcklig behörighet att lägga till intranätsdel.',
  ERROR_TYPE_DEPARTMENT_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa intranätsdel.',
  ERROR_TYPE_DEPARTMENT_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra intranätsdel.',
  ERROR_TYPE_DEPARTMENT_NO_DELETE_PERMISSION: 'Inte tillräcklig behörighet att ta bort intranätsdel.',

  ERROR_TYPE_DOC_LIB_GET: 'Kunde inte hämta filer från dokumentbibliotek.',
  ERROR_TYPE_DOC_LIB_NO_ACCESS: 'Du har inte tillräckliga rättigheter för att se de här filerna.',

  ERROR_TYPE_EMPLOYEE_ADD: 'Kunde inte lägga till anställd.',
  ERROR_TYPE_EMPLOYEE_GET: 'Kunde inte hämta anställda.',
  ERROR_TYPE_EMPLOYEE_DELETE: 'Kunde inte ta bort anställd.',
  ERROR_TYPE_EMPLOYEE_NOT_FOUND: 'Anställd saknas.',
  ERROR_TYPE_EMPLOYEE_UPDATE: 'Kunde inte ändra anställd.',
  ERROR_TYPE_EMPLOYEE_NO_ADD_PERMISSION: 'Inte tillräcklig behörighet att lägga till anställd.',
  ERROR_TYPE_EMPLOYEE_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa anställd.',
  ERROR_TYPE_EMPLOYEE_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra anställd.',
  ERROR_TYPE_EMPLOYEE_NO_DELETE_PERMISSION: 'Inte tillräcklig behörighet att ta bort anställd.',

  ERROR_TYPE_OBJECT_TYPE_ADD: 'Kunde inte lägga till objekttypen.',
  ERROR_TYPE_OBJECT_TYPE_GET: 'Kunde inte hämta objekttyper.',
  ERROR_TYPE_OBJECT_TYPE_DELETE: 'Kunde inte ta bort objekttypen.',
  ERROR_TYPE_OBJECT_TYPE_NOT_FOUND: 'Objekttypen saknas.',
  ERROR_TYPE_OBJECT_TYPE_UPDATE: 'Kunde inte ändra objekttypen.',
  ERROR_TYPE_OBJECT_TYPE_NO_ADD_PERMISSION: 'Inte tillräcklig behörighet att lägga till objekttyp.',
  ERROR_TYPE_OBJECT_TYPE_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa objekttyp.',
  ERROR_TYPE_OBJECT_TYPE_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra objekttyp.',
  ERROR_TYPE_OBJECT_TYPE_NO_DELETE_PERMISSION: 'Inte tillräcklig behörighet att ta bort objekttyp.',

  ERROR_TYPE_PROJECT_ADD: 'Kunde inte lägga till projektet.',
  ERROR_TYPE_PROJECT_GET: 'Kunde inte hämta projekt.',
  ERROR_TYPE_PROJECT_DELETE: 'Kunde inte ta bort projektet.',
  ERROR_TYPE_PROJECT_CONFLICT: 'Kunde inte ändra projektet just nu. Var god försök igen om en stund.',
  ERROR_TYPE_PROJECT_NOT_FOUND: 'Projektet saknas.',
  ERROR_TYPE_PROJECT_UPDATE: 'Kunde inte ändra projektet.',
  ERROR_TYPE_PROJECT_NO_ADD_PERMISSION: 'Inte tillräcklig behörighet att lägga till projekt.',
  ERROR_TYPE_PROJECT_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa projekt.',
  ERROR_TYPE_PROJECT_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra projekt.',
  ERROR_TYPE_PROJECT_NO_DELETE_PERMISSION: 'Inte tillräcklig behörighet att ta bort projekt.',

  ERROR_TYPE_ROLE_ADD: 'Kunde inte lägga till rollen.',
  ERROR_TYPE_ROLE_GET: 'Kunde inte hämta roll.',
  ERROR_TYPE_ROLE_DELETE: 'Kunde inte ta bort rollen.',
  ERROR_TYPE_ROLE_NOT_FOUND: 'Rollen saknas.',
  ERROR_TYPE_ROLE_UPDATE: 'Kunde inte ändra rollen.',
  ERROR_TYPE_ROLE_NO_ADD_PERMISSION: 'Inte tillräcklig behörighet att lägga till roll.',
  ERROR_TYPE_ROLE_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa roll.',
  ERROR_TYPE_ROLE_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra roll.',
  ERROR_TYPE_ROLE_NO_DELETE_PERMISSION: 'Inte tillräcklig behörighet att ta bort roll.',

  ERROR_TYPE_TAG_ADD: 'Kunde inte lägga till taggen.',
  ERROR_TYPE_TAG_GET: 'Kunde inte hämta taggar.',
  ERROR_TYPE_TAG_DELETE: 'Kunde inte ta bort taggen.',
  ERROR_TYPE_TAG_NOT_FOUND: 'Taggen saknas.',
  ERROR_TYPE_TAG_UPDATE: 'Kunde inte ändra taggen.',
  ERROR_TYPE_TAG_NO_ADD_PERMISSION: 'Inte tillräcklig behörighet att lägga till tagg.',
  ERROR_TYPE_TAG_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa tagg.',
  ERROR_TYPE_TAG_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra tagg.',
  ERROR_TYPE_TAG_NO_DELETE_PERMISSION: 'Inte tillräcklig behörighet att ta bort tagg.',

  ERROR_TYPE_TEAMS_NO_PAGE: 'Den här gruppen saknar teamssida.',

  ERROR_TYPE_TICKET_ADD: 'Kunde inte lägga till ticket.',
  ERROR_TYPE_TICKET_GET: 'Kunde inte hämta tickets.',
  ERROR_TYPE_TICKET_DELETE: 'Kunde inte ta bort ticket.',
  ERROR_TYPE_TICKET_NOT_FOUND: 'Ticket saknas.',
  ERROR_TYPE_TICKET_UPDATE: 'Kunde inte ändra ticket.',
  ERROR_TYPE_TICKET_NO_ADD_PERMISSION: 'Inte tillräcklig behörighet att lägga till ticket.',
  ERROR_TYPE_TICKET_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa ticket.',
  ERROR_TYPE_TICKET_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra ticket.',
  ERROR_TYPE_TICKET_NO_DELETE_PERMISSION: 'Inte tillräcklig behörighet att ta bort ticket.',

  ERROR_TYPE_AUTH: 'Autentiseringsfel. Prova att logga in igen.',

  ERROR_TYPE_LOGIN: 'Kunde inte logga in.',
  LOGIN_ERROR_ID_TOKEN: 'Kunde inte autentisera med Microsoft. Se till att du använder att konto från Microsoft, att popup fönster kan öppnas i din webläsare och försök igen.',
  LOGIN_ERROR_API_TOKEN: 'Kontakta din administrator och se till att du har ett konto registrerat hos PDS. Logga ut och försök igen.',
  LOGIN_ERROR_SHAREPOINT_TOKEN: 'Kunde inte få access till SharePoint. Logga ut och försök igen.',
  LOGIN_ERROR_GRAPH_TOKEN: 'Kunde inte få access till Microsoft Graph. Logga ut och försök igen.',

  ERROR_TYPE_NETWORK: 'Nätverksproblem. Se till att du har en fungerande internetuppkoppling.',
  ERROR_TYPE_UNKNOWN: 'Okänt problem. Var god försök igen om en stund.'
}

const resources = {
  en_consultancy: en,
  en_lawfirm: {
    ...en,
    ERROR_TYPE_CONTACT_ADD: 'Failed to add client.',
    ERROR_TYPE_CONTACT_CONFLICT: 'Could not update client right now. Please try again later.',
    ERROR_TYPE_CONTACT_DELETE: 'Failed to delete client.',
    ERROR_TYPE_CONTACT_GET: 'Failed to get clients.',
    ERROR_TYPE_CONTACT_NOT_FOUND: 'Could not find client.',
    ERROR_TYPE_CONTACT_NO_ADD_PERMISSION: 'Not enough permissions to add client.',
    ERROR_TYPE_CONTACT_NO_DELETE_PERMISSION: 'Not enough permissions to delete client.',
    ERROR_TYPE_CONTACT_NO_GET_PERMISSION: 'Not enough permissions to read client.',
    ERROR_TYPE_CONTACT_NO_UPDATE_PERMISSION: 'Not enough permissions to update client.',
    ERROR_TYPE_CONTACT_UPDATE: 'Failed to update client.',
    contact: 'Client',
    contact_object_type: 'Client type',
    contact_persons_in_same_contact: 'Contact persons in the same client',
    contact_responsible: 'Client responsible',
    contact_role: 'Client (role)',
    contact_role_add: 'Add Client/Role',
    contacts: 'Clients',
    external_contacts: 'External clients',
    project: 'Matter',
    project_name: 'Matter Name',
    project_object_type: 'Matter Type',
    project_participants: 'Matter Participants',
    project_responsible: 'Matter Manager',
    project_role: 'Matter Role',
    project_tasks: 'Matter Tasks',
    projects: 'Matters',
    tasks_in_same_project: 'Tasks in the same matter'
  },
  es_consultancy: {
    account: 'Usuario',
    accounts: 'Usuarios',
    add: 'añadir',
    add_tag: 'Añadir etiqueta',
    add_tags: 'Añadir etiquetas',
    address: 'Dirección',
    admin: 'Administrador',
    all: 'Todos',
    activities: 'actividades',
    assigned: 'Asignado',
    back: 'Atrás',
    cancel: 'Cancelar',
    intranet: 'Intranet',
    category: 'Carpeta de intranet',
    category_object_type: 'Tipo de carpeta de intranet',
    categories: 'Carpetas de intranet',
    categories_in_same_intranet: 'Intranet Carpetas en la misma intranet',
    city: 'Ciudades',
    contact: 'Empresa',
    contact_object_type: 'Tipo de corporation',
    contact_person: 'Persona de contacto',
    contact_person_object_type: 'Tipo de persona de contacto',
    contact_persons: 'Personas de contacto',
    contact_persons_in_same_contact: 'Contact persons in same corporation',
    contact_role: 'Contacto (rol)',
    contact_role_add: 'Añadir contacto / rol',
    contacts: 'Empresas',
    content_type_name: 'Nombre del tipo de contacto',
    content_types: 'Tipos de contacto',
    create: 'Crear',
    created_at: 'Creado',
    define_own_color: 'Definir color propio',
    delete: 'Borrar',
    department: 'Sección de la intranet',
    department_object_type: 'Tipo de sección de la intranet',
    departments: 'Secciones de la intranet',
    description: 'Descripción',
    documents: 'Documentos',
    email: 'Email',
    employee: 'Empleado',
    employee_object_type: 'Tipo de empleado',
    employees: 'Empleados',
    external_contact_persons: 'Personas de contacto externas',
    external_contacts: 'Contactos externos',
    external_id: 'Id. externo',
    contact_no_contact_persons: 'Sin personas de contacto',
    expires_at: 'Fecha de expiración',
    estimate_value: 'Estimar',
    explainlogin: 'Contacte con su administrador de PDS si no puede acceder.',
    favorite: 'Favorito',
    field_internal_name: 'Nombre del campo',
    field_type_as_string: 'Tipo del campo',
    field_id: 'Id. del campo',
    field_title: 'Título del campo',
    finished: 'Finalizado',
    first_name: 'Nombre',
    gdpr: 'GDPR',
    handle: 'Identificador',
    homepage: 'Página principal',
    id: 'Id.',
    is_logging_in: 'Iniciando sesión...',
    last_name: 'Apellidos',
    latest: 'último',
    logout: 'Cerrar sesión',
    logout_confirm: 'Está seguro de que desea cerrar sesión?',
    login_with_microsoft: 'Iniciar sesión con Microsoft',
    mine: 'Míos',
    minutes: 'min',
    missing_content_page: 'Esta página no tiene contenido',
    name: 'Nombre',
    no: 'No',
    object_type: 'Tipo de objeto',
    object_type_type: 'Tipo específico',
    object_types: 'Tipos de objeto',
    office: 'Oficina',
    ok: 'Ok',
    password: 'Contraseña',
    permission: 'Permisos',
    person: 'Persona',
    person_role: 'Persona (rol)',
    person_role_add: 'Añadir persona / rol',
    phone: 'Teléfono',
    phone_number: 'Número de teléfono',
    postal_code: 'Código postal',
    project: 'Proyecto',
    projects: 'Proyectos',
    project_name: 'Nombre del proyecto',
    project_object_type: 'Tipo del proyecto',
    project_participants: 'Participantes del proyecto',
    project_responsible: 'Responsable del proyecto',
    project_role: 'Rol del proyecto',
    project_tasks: 'Tareas del proyecto',
    reallydelete: 'Borrar los datos puede provocar que se borren además los datos relacionados. Esta operación no se puede deshacer. ¿Está seguro de que quiere continuar con el borrado?',
    read: 'Leer',
    relogin_in: 'Volver a iniciar sesión',
    responsible: 'Responsable',
    contact_responsible: 'Contacto de la persona responsable',
    role: 'Rol',
    roles: 'Roles',
    rows_per_page: 'Filas por página:',
    save: 'Guardar',
    search: 'Buscar',
    settings: 'Configuración',
    share: 'Compartir',
    sharepoint: 'SharePoint',
    should_be_finished_by: 'Debería finalizar el',
    started_at: 'Fecha de inicio',
    startpage: 'inicio',
    status: 'Estado',
    tag: 'Etiqueta',
    tags: 'Etiquetas',
    tag_type: 'Tipo de etiqueta',
    task: 'Tarea',
    tasks: 'Tareas',
    tasks_no_tasks: 'No hay tareas',
    tasks_in_same_project: 'Tareas en el mismo proyecto',
    teams: 'Teams',
    teams_url: 'URL de Teams',
    create_teams: 'Crear teams',
    tenant_name: 'Instancia',
    template_manager: 'Gestor de plantillas',
    ticket: 'Ticket',
    title: 'Título',
    tool_belt: 'Herramientas',
    update: 'Actualizar',
    updated_at: 'Actualizado',
    url: 'URL',
    username: 'Usuario',
    vat_no: 'NIF / CIF / Núm. IVA',
    version: 'Versión',
    warning: 'Advertencia',
    welcome: 'bienvenido/a',
    yes: 'Sí',

    // Admin errors

    ERROR_TYPE_ACCOUNT_ADD: 'Error al añadir la cuenta.',
    ERROR_TYPE_ACCOUNT_GET: 'Error al obtener las cuentas.',
    ERROR_TYPE_ACCOUNT_DELETE: 'Error al borrar la cuenta.',
    ERROR_TYPE_ACCOUNT_NOT_FOUND: 'No se puede encontrar la cuenta.',
    ERROR_TYPE_ACCOUNT_UPDATE: 'Error al actualizar la cuenta.',
    ERROR_TYPE_ACCOUNT_NO_ADD_PERMISSION: 'No tiene permisos para añadir cuenta.',
    ERROR_TYPE_ACCOUNT_NO_GET_PERMISSION: 'No tiene permisos para acceder a la cuenta.',
    ERROR_TYPE_ACCOUNT_NO_UPDATE_PERMISSION: 'No tiene permisos para actualizar la cuenta.',
    ERROR_TYPE_ACCOUNT_NO_DELETE_PERMISSION: 'No tiene permisos para borrar la cuenta.',

    ERROR_TYPE_PERMISSION_GET: 'Error al obtener los permisos.',
    ERROR_TYPE_PERMISSION_NOT_FOUND: 'No se puede encontrar el permiso.',
    ERROR_TYPE_PERMISSION_NO_GET_PERMISSION: 'No tiene permisos para obtener los permisos.',

    ERROR_TYPE_TENANT_GET: 'Error al acceder a las instancias.',
    ERROR_TYPE_TENANT_NOT_FOUND: 'No se puede encontrar la instancia.',
    ERROR_TYPE_TENANT_NO_GET_PERMISSION: 'No tiene permisos para acceder a la instancia.',

    // User errors

    ERROR_TYPE_CATEGORY_ADD: 'Error al añadir la carpeta de la intranet.',
    ERROR_TYPE_CATEGORY_GET: 'Error al obtener las carpetas de la intranet.',
    ERROR_TYPE_CATEGORY_DELETE: 'Error al borrar la carpeta de la intranet.',
    ERROR_TYPE_CATEGORY_CONFLICT: 'No se puede actualizar la carpeta de la intranet en este momento. Por favor inténtelo de nuevo más tarde.',
    ERROR_TYPE_CATEGORY_NOT_FOUND: 'No se puede encontrar la carpeta de la intranet.',
    ERROR_TYPE_CATEGORY_UPDATE: 'Error al actualizar la carpeta de la intranet.',
    ERROR_TYPE_CATEGORY_NO_ADD_PERMISSION: 'No tiene permisos para añadir la carpeta de la intranet.',
    ERROR_TYPE_CATEGORY_NO_GET_PERMISSION: 'No tiene permisos para leer la carpeta de la intranet.',
    ERROR_TYPE_CATEGORY_NO_UPDATE_PERMISSION: 'No tiene permisos para actualizar la carpeta de la intranet.',
    ERROR_TYPE_CATEGORY_NO_DELETE_PERMISSION: 'No tiene permisos para borrar la carpeta de la intranet.',

    ERROR_TYPE_CONTACT_ADD: 'Error al añadir el contacto.',
    ERROR_TYPE_CONTACT_GET: 'Error al obtener los contactos.',
    ERROR_TYPE_CONTACT_DELETE: 'Error al borrar el contacto.',
    ERROR_TYPE_CONTACT_CONFLICT: 'No se puede actualizar el contacto en este momento. Por favor inténtelo de nuevo más tarde.',
    ERROR_TYPE_CONTACT_NOT_FOUND: 'No se puede encontrar el contacto.',
    ERROR_TYPE_CONTACT_UPDATE: 'Error al actualizar el contacto.',
    ERROR_TYPE_CONTACT_NO_ADD_PERMISSION: 'No tiene permisos para añadir el contacto.',
    ERROR_TYPE_CONTACT_NO_GET_PERMISSION: 'No tiene permisos para leer el contacto.',
    ERROR_TYPE_CONTACT_NO_UPDATE_PERMISSION: 'No tiene permisos para actualizar el contacto.',
    ERROR_TYPE_CONTACT_NO_DELETE_PERMISSION: 'No tiene permisos para borrar el contacto.',

    ERROR_TYPE_CONTACT_PERSON_ADD: 'Error al añadir la persona de contacto.',
    ERROR_TYPE_CONTACT_PERSON_GET: 'Error al obtener la persona de contacto.',
    ERROR_TYPE_CONTACT_PERSON_DELETE: 'Error al borrar la persona de contacto.',
    ERROR_TYPE_CONTACT_PERSON_NOT_FOUND: 'No se puede encontrar la persona de contacto.',
    ERROR_TYPE_CONTACT_PERSON_UPDATE: 'Error al actualizar la persona de contacto.',
    ERROR_TYPE_CONTACT_PERSON_NO_ADD_PERMISSION: 'No tiene permisos para añadir la persona de contacto.',
    ERROR_TYPE_CONTACT_PERSON_NO_GET_PERMISSION: 'No tiene permisos para leer la persona de contacto.',
    ERROR_TYPE_CONTACT_PERSON_NO_UPDATE_PERMISSION: 'No tiene permisos para actualizar la persona de contacto.',
    ERROR_TYPE_CONTACT_PERSON_NO_DELETE_PERMISSION: 'No tiene permisos para borrar la persona de contacto. ',

    ERROR_TYPE_CONTENT_TYPE_GET: 'Error al obtener los tipos de contenido.',
    ERROR_TYPE_CONTENT_TYPE_NOT_FOUND: 'No se puede encontrar el tipo de contenido.',
    ERROR_TYPE_CONTENT_TYPE_NO_GET_PERMISSION: 'No tiene permisos para leer los tipos de contenido.',

    ERROR_TYPE_DEPARTMENT_ADD: 'Error al añadir la sección de la intranet.',
    ERROR_TYPE_DEPARTMENT_GET: 'Error al obtener la sección de la intranet.',
    ERROR_TYPE_DEPARTMENT_DELETE: 'Error al borrar la sección de la intranet.',
    ERROR_TYPE_DEPARTMENT_CONFLICT: 'No se puede utilizar la sección de la intranet en este momento. Por favor inténtelo de nuevo más tarde.',
    ERROR_TYPE_DEPARTMENT_NOT_FOUND: 'No se puede encontrar la sección de la intranet.',
    ERROR_TYPE_DEPARTMENT_UPDATE: 'Error al actualizar la sección de la intranet.',
    ERROR_TYPE_DEPARTMENT_NO_ADD_PERMISSION: 'No tiene permisos para añadir la sección de la intranet.',
    ERROR_TYPE_DEPARTMENT_NO_GET_PERMISSION: 'No tiene permisos para leer la sección de la intranet.',
    ERROR_TYPE_DEPARTMENT_NO_UPDATE_PERMISSION: 'No tiene permisos para actualizar la sección de la intranet.',
    ERROR_TYPE_DEPARTMENT_NO_DELETE_PERMISSION: 'No tiene permisos para borrar la sección de la intranet.',

    ERROR_TYPE_DOC_LIB_GET: 'Error al obtener archivos desde la galería de documentos.',
    ERROR_TYPE_DOC_LIB_NO_ACCESS: 'No tiene permisos para ver estos archivos.',

    ERROR_TYPE_EMPLOYEE_ADD: 'Error al añadir el empleado.',
    ERROR_TYPE_EMPLOYEE_GET: 'Error al obtener empleados.',
    ERROR_TYPE_EMPLOYEE_DELETE: 'Error al borrar el empleado.',
    ERROR_TYPE_EMPLOYEE_NOT_FOUND: 'No se puede encontrar el empleado.',
    ERROR_TYPE_EMPLOYEE_UPDATE: 'Error al actualizar el empleado.',
    ERROR_TYPE_EMPLOYEE_NO_ADD_PERMISSION: 'No tiene permisos para añadir el empleado.',
    ERROR_TYPE_EMPLOYEE_NO_GET_PERMISSION: 'No tiene permisos para leer el empleado.',
    ERROR_TYPE_EMPLOYEE_NO_UPDATE_PERMISSION: 'No tiene permisos para actualizar el empleado.',
    ERROR_TYPE_EMPLOYEE_NO_DELETE_PERMISSION: 'No tiene permisos para borrar el empleado.',

    ERROR_TYPE_OBJECT_TYPE_ADD: 'Error al añadir el tipo de objeto.',
    ERROR_TYPE_OBJECT_TYPE_GET: 'Error al obtener los tipos de objeto.',
    ERROR_TYPE_OBJECT_TYPE_DELETE: 'Error al borrar el tipo de objeto.',
    ERROR_TYPE_OBJECT_TYPE_NOT_FOUND: 'No se puede encontrar el tipo de objeto.',
    ERROR_TYPE_OBJECT_TYPE_UPDATE: 'Error al actualizar el tipo de objeto.',
    ERROR_TYPE_OBJECT_TYPE_NO_ADD_PERMISSION: 'No tiene permisos para añadir el tipo de objeto.',
    ERROR_TYPE_OBJECT_TYPE_NO_GET_PERMISSION: 'No tiene permisos para leer el tipo de objeto.',
    ERROR_TYPE_OBJECT_TYPE_NO_UPDATE_PERMISSION: 'No tiene permisos para actualizar el tipo de objeto.',
    ERROR_TYPE_OBJECT_TYPE_NO_DELETE_PERMISSION: 'No tiene permisos para borrar el tipo de objeto.',

    ERROR_TYPE_PROJECT_ADD: 'Error al añadir el proyecto.',
    ERROR_TYPE_PROJECT_GET: 'Error al obtener los proyectos.',
    ERROR_TYPE_PROJECT_DELETE: 'Error al borrar el proyecto.',
    ERROR_TYPE_PROJECT_CONFLICT: 'No se puede actualizar el proyecto en este momento. Por favor inténtelo de nuevo más tarde.',
    ERROR_TYPE_PROJECT_NOT_FOUND: 'No se puede encontrar el proyecto.',
    ERROR_TYPE_PROJECT_UPDATE: 'Error al actualizar el proyecto.',
    ERROR_TYPE_PROJECT_NO_ADD_PERMISSION: 'No tiene permisos para añadir el proyecto.',
    ERROR_TYPE_PROJECT_NO_GET_PERMISSION: 'No tiene permisos para leer el proyecto.',
    ERROR_TYPE_PROJECT_NO_UPDATE_PERMISSION: 'No tiene permisos para actualizar el proyecto.',
    ERROR_TYPE_PROJECT_NO_DELETE_PERMISSION: 'No tiene permisos para borrar el proyecto.',

    ERROR_TYPE_ROLE_ADD: 'Error al añadir el rol.',
    ERROR_TYPE_ROLE_GET: 'Error al obtener los roles.',
    ERROR_TYPE_ROLE_DELETE: 'Error al borrar el rol.',
    ERROR_TYPE_ROLE_NOT_FOUND: 'No se puede encontrar el rol.',
    ERROR_TYPE_ROLE_UPDATE: 'Error al actualizar el rol.',
    ERROR_TYPE_ROLE_NO_ADD_PERMISSION: 'No tiene permisos para añadir el rol.',
    ERROR_TYPE_ROLE_NO_GET_PERMISSION: 'No tiene permisos para leer el rol.',
    ERROR_TYPE_ROLE_NO_UPDATE_PERMISSION: 'No tiene permisos para actualizar el rol.',
    ERROR_TYPE_ROLE_NO_DELETE_PERMISSION: 'No tiene permisos para borrar el rol.',

    ERROR_TYPE_TAG_ADD: 'Error al añadir la etiqueta.',
    ERROR_TYPE_TAG_GET: 'Error al obtener etiquetas.',
    ERROR_TYPE_TAG_DELETE: 'Error al borrar la etiqueta.',
    ERROR_TYPE_TAG_NOT_FOUND: 'No se puede encontrar la etiqueta.',
    ERROR_TYPE_TAG_UPDATE: 'Error al actualizar la etiqueta.',
    ERROR_TYPE_TAG_NO_ADD_PERMISSION: 'No tiene permisos para añadir la etiqueta.',
    ERROR_TYPE_TAG_NO_GET_PERMISSION: 'No tiene permisos para leer la etiqueta.',
    ERROR_TYPE_TAG_NO_UPDATE_PERMISSION: 'No tiene permisos para actualizar la etiqueta.',
    ERROR_TYPE_TAG_NO_DELETE_PERMISSION: 'No tiene permisos para borrar la etiqueta.',

    ERROR_TYPE_TEAMS_NO_PAGE: 'TODO @mazurdo.',

    ERROR_TYPE_TICKET_ADD: 'Error al añadir el ticket.',
    ERROR_TYPE_TICKET_GET: 'Error al obtener los tickets.',
    ERROR_TYPE_TICKET_DELETE: 'Error al borrar el ticket.',
    ERROR_TYPE_TICKET_NOT_FOUND: 'No se puede encontrar el ticket.',
    ERROR_TYPE_TICKET_UPDATE: 'Error al actualizar el ticket.',
    ERROR_TYPE_TICKET_NO_ADD_PERMISSION: 'No tiene permisos para añadir el ticket.',
    ERROR_TYPE_TICKET_NO_GET_PERMISSION: 'No tiene permisos para leer el ticket.',
    ERROR_TYPE_TICKET_NO_UPDATE_PERMISSION: 'No tiene permisos para actualizar el ticket.',
    ERROR_TYPE_TICKET_NO_DELETE_PERMISSION: 'No tiene permisos para borrar el ticket.',

    ERROR_TYPE_AUTH: 'Error de autenticación. Intente acceder de nuevo.',
    ERROR_TYPE_LOGIN: 'Error al iniciar sesión.',
    LOGIN_ERROR_ID_TOKEN: 'No se puede autenticar con Microsoft. Por favor asegúrese de que está utilizando una cuenta de Microsoft y que las ventanas emergentes del navegador están habilitadas e inténtelo de nuevo.',
    LOGIN_ERROR_API_TOKEN: 'Contacte con su administrador y asegúrese de que tiene una cuenta registrada con PDS. Por favor cierre la sesión y acceda de nuevo utilizando una cuenta habilitada para ello.',
    LOGIN_ERROR_SHAREPOINT_TOKEN: 'No se puede acceder a SharePoint. Por favor cierre sesión e inténtelo de nuevo.',
    LOGIN_ERROR_GRAPH_TOKEN: 'No se puede acceder a Microsoft Graph. Por favor cierre sesión e inténtelo de nuevo.',

    ERROR_TYPE_NETWORK: 'No se puede conectar. Asegúrese de que dispone de conexión a Internet.',
    ERROR_TYPE_UNKNOWN: 'Error no especificado. Por favor vuelva a intentarlo de nuevo más tarde.'
  },
  sv_consultancy: sv,
  sv_lawfirm: {
    ...sv,
    ERROR_TYPE_CONTACT_ADD: 'Kunde inte lägga till klienten.',
    ERROR_TYPE_CONTACT_CONFLICT: 'Kunde inte ändra klienten just nu. Var god försök igen om en stund.',
    ERROR_TYPE_CONTACT_DELETE: 'Kunde inte ta bort klienten.',
    ERROR_TYPE_CONTACT_GET: 'Kunde inte hämta klienter.',
    ERROR_TYPE_CONTACT_NOT_FOUND: 'Klienten saknas.',
    ERROR_TYPE_CONTACT_NO_ADD_PERMISSION: 'Inte tillräcklig behörighet att lägga till klient.',
    ERROR_TYPE_CONTACT_NO_DELETE_PERMISSION: 'Inte tillräcklig behörighet att ta bort klient.',
    ERROR_TYPE_CONTACT_NO_GET_PERMISSION: 'Inte tillräcklig behörighet att läsa klient.',
    ERROR_TYPE_CONTACT_NO_UPDATE_PERMISSION: 'Inte tillräcklig behörighet att ändra klient.',
    ERROR_TYPE_CONTACT_UPDATE: 'Kunde inte ändra klienten.',
    contact: 'Klient',
    contact_object_type: 'Klienttyp',
    contact_persons_in_same_contact: 'Kontaktpersoner i samma Klient',
    contact_responsible: 'Klientansvarig',
    contact_role: 'Klient (roll)',
    contact_role_add: 'Lägg till klient/roll',
    contacts: 'Klienter',
    external_contacts: 'Externa klienter',
    project: 'Ärende',
    project_name: 'Ärendenamn',
    project_object_type: 'Ärendetyp',
    project_participants: 'Ärendedeltagare',
    project_responsible: 'Ärendeansvarig',
    project_role: 'Ärenderoll',
    project_tasks: 'Ärendeuppgifter',
    projects: 'Ärenden',
    tasks_in_same_project: 'Uppgifter i samma ärende'
  }
}

export default resources
