import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPageItems, receivedEmployees } from './actions'
import { makeSelectorById, makeSelectorEmployee } from './selectors'
import { makeSelectMetadataByRequestId, selectStatusByRequestId } from '../busy/selectors'
import { busy, notBusy } from '../busy/reducer'
import api from '../api'
import { handleAddErrors, handleDeleteErrors, handleGetErrors, handleUpdateErrors } from '../error/actions'
import { useRequestId } from '../../hooks/useRequestId'

export function useGetEmployeeByIdQuery (id) {
  const reqId = useRequestId([id])
  const dispatch = useDispatch()
  const [refetching, setRefetching] = useState(false)
  const selectEmployeeById = useMemo(makeSelectorById, [])

  const status = useSelector((state) => selectStatusByRequestId(state, reqId))
  const data = useSelector((state) => selectEmployeeById(state, id))

  const effects = useCallback((requestId, params) => {
    dispatch(busy({ requestId }))
    api.getEmployees(params).then(result => {
      dispatch(receivedEmployees(result.data.value))
      dispatch(notBusy({ requestId }))
    }).catch(handleGetErrors('employee', dispatch, requestId))
  }, [dispatch])

  useEffect(() => {
    if ((status === undefined || refetching) && id) {
      effects(reqId, { query: [{ key: 'id', op: '=', value: id }] })
      setRefetching(false)
    }
  }, [effects, id, reqId, status, refetching, setRefetching])

  const refetch = useCallback(() => {
    setRefetching(true)
  }, [setRefetching])

  const isLoading = status === 'pending' || status === undefined
  const isError = status === 'rejected'
  const isSuccessful = status === 'resolved'
  const isUnitialized = status === undefined
  return { isUnitialized, isError, isSuccessful, data, refetch, isLoading }
}

export function useGetEmployeesQuery (params = { limit: 50 }) {
  const reqId = useRequestId()
  const dispatch = useDispatch()
  const [refetching, setRefetching] = useState(false)
  const selectEmployees = useMemo(makeSelectorEmployee, [])

  const status = useSelector((state) => selectStatusByRequestId(state, reqId))
  const data = useSelector(selectEmployees)

  const effects = useCallback((requestId, params) => {
    dispatch(busy({ requestId }))
    api.getEmployees(params).then(result => {
      dispatch(receivedEmployees(result.data.value))
      dispatch(notBusy({ requestId }))
    }).catch(handleGetErrors('employee', dispatch, requestId))
  }, [dispatch])

  useEffect(() => {
    if (status === undefined || refetching) {
      effects(reqId, params)
      setRefetching(false)
    }
  }, [effects, params, reqId, status, refetching, setRefetching])

  const refetch = useCallback(() => {
    setRefetching(true)
  }, [setRefetching])

  const isLoading = status === 'pending' || status === undefined
  const isError = status === 'rejected'
  const isSuccessful = status === 'fulfilled'
  const isUnitialized = status === undefined
  return { isUnitialized, isError, isSuccessful, data, refetch, isLoading }
}

export function useAddEmployeeMutation () {
  const requestId = useRequestId()
  const dispatch = useDispatch()
  const selectMetadataByRequestId = useMemo(makeSelectMetadataByRequestId, [])

  const metadata = useSelector((state) => selectMetadataByRequestId(state, requestId))
  const status = useSelector((state) => selectStatusByRequestId(state, requestId))

  const mutation = useCallback((employee) => {
    dispatch(busy({ requestId }))
    api.addEmployee(employee).then(result => {
      dispatch(notBusy({ requestId, id: result.data.value?.id }))
    }).catch(handleAddErrors('employee', dispatch))
  }, [dispatch, requestId])

  const isUnitialized = status === 'pending' || status === undefined
  const isLoading = status === 'pending'
  const isSuccessful = status === 'resolved'
  const isError = status === 'rejected'

  return { metadata, mutation, isLoading, isError, isUnitialized, isSuccessful }
}

export function useUpdateEmployeeMutation () {
  const requestId = useRequestId()
  const dispatch = useDispatch()

  const selectMetadataByRequestId = useMemo(makeSelectMetadataByRequestId, [])
  const metadata = useSelector((state) => selectMetadataByRequestId(state, requestId))
  const status = useSelector((state) => selectStatusByRequestId(state, requestId))

  const mutation = useCallback((employee) => {
    dispatch(busy({ requestId }))
    api.updateEmployee(employee).then(result => {
      dispatch(notBusy({ requestId, id: result.data.value?.id }))
    }).catch(handleUpdateErrors('employee', dispatch, requestId))
  }, [dispatch, requestId])

  const isUnitialized = status === undefined
  const isLoading = status === 'pending'
  const isSuccessful = status === 'resolved'
  const isError = status === 'rejected'

  return { metadata, mutation, isLoading, isError, isUnitialized, isSuccessful }
}

export function useDeleteEmployeeMutation () {
  const requestId = useRequestId()
  const dispatch = useDispatch()

  const selectMetadataByRequestId = useMemo(makeSelectMetadataByRequestId, [])
  const metadata = useSelector((state) => selectMetadataByRequestId(state, requestId))
  const status = useSelector((state) => selectStatusByRequestId(state, requestId))

  const mutation = useCallback((employee) => {
    dispatch(busy({ id: employee.id, requestId }))
    api.deleteEmployee(employee).then(() => {
      dispatch(notBusy({ requestId }))
      dispatch(getPageItems())
    }).catch(handleDeleteErrors('employee', dispatch, requestId))
  }, [dispatch, requestId])

  const isUnitialized = status === undefined
  const isLoading = status === 'pending'
  const isSuccessful = status === 'resolved'
  const isError = status === 'rejected'

  return { metadata, mutation, isLoading, isError, isUnitialized, isSuccessful }
}
