import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPageItems, receivedCategories } from './actions'
import { makeSelectorById, makeSelectorCategory } from './selectors'
import { makeSelectMetadataByRequestId, selectStatusByRequestId } from '../busy/selectors'
import { busy, notBusy } from '../busy/reducer'
import api from '../api'
import { handleAddErrors, handleDeleteErrors, handleGetErrors, handleUpdateErrors } from '../error/actions'
import { useRequestId } from '../../hooks/useRequestId'

export function useGetCategoryByIdQuery (id) {
  const reqId = useRequestId([id])
  const dispatch = useDispatch()
  const [refetching, setRefetching] = useState(false)
  const selectCategoryById = useMemo(makeSelectorById, [])

  const status = useSelector((state) => selectStatusByRequestId(state, reqId))
  const data = useSelector((state) => selectCategoryById(state, id))

  const effects = useCallback((requestId, params) => {
    dispatch(busy({ requestId }))
    api.getCategories(params).then(result => {
      dispatch(receivedCategories(result.data.value))
      dispatch(notBusy({ requestId }))
    }).catch(handleGetErrors('category', dispatch, requestId))
  }, [dispatch])

  useEffect(() => {
    if ((status === undefined || refetching) && id) {
      effects(reqId, { query: [{ key: 'id', op: '=', value: id }] })
      setRefetching(false)
    }
  }, [effects, id, reqId, status, refetching, setRefetching])

  const refetch = useCallback(() => {
    setRefetching(true)
  }, [setRefetching])

  const isLoading = status === 'pending' || status === undefined
  const isError = status === 'rejected'
  const isSuccessful = status === 'resolved'
  const isUnitialized = status === undefined
  return { isUnitialized, isError, isSuccessful, data, refetch, isLoading }
}

export function useGetCategoryQuery (params = { limit: 50 }) {
  const reqId = useRequestId()
  const dispatch = useDispatch()
  const [refetching, setRefetching] = useState(false)
  const selectCategory = useMemo(makeSelectorCategory, [])

  const status = useSelector((state) => selectStatusByRequestId(state, reqId))
  const data = useSelector(selectCategory)

  const effects = useCallback((requestId, params) => {
    dispatch(busy({ requestId }))
    api.getCategories(params).then(result => {
      dispatch(receivedCategories(result.data.value))
      dispatch(notBusy({ requestId }))
    }).catch(handleGetErrors('category', dispatch, requestId))
  }, [dispatch])

  useEffect(() => {
    if (status === undefined || refetching) {
      effects(reqId, params)
      setRefetching(false)
    }
  }, [effects, params, reqId, status, refetching, setRefetching])

  const refetch = useCallback(() => {
    setRefetching(true)
  }, [setRefetching])

  const isLoading = status === 'pending' || status === undefined
  const isError = status === 'rejected'
  const isSuccessful = status === 'fulfilled'
  const isUnitialized = status === undefined
  return { isUnitialized, isError, isSuccessful, data, refetch, isLoading }
}

export function useAddCategoryMutation () {
  const requestId = useRequestId()
  const dispatch = useDispatch()
  const selectMetadataByRequestId = useMemo(makeSelectMetadataByRequestId, [])

  const metadata = useSelector((state) => selectMetadataByRequestId(state, requestId))
  const status = useSelector((state) => selectStatusByRequestId(state, requestId))

  const mutation = useCallback((category) => {
    dispatch(busy({ requestId }))
    api.addCategory(category).then(result => {
      dispatch(notBusy({ requestId, id: result.data.value?.id }))
    }).catch(handleAddErrors('category', dispatch))
  }, [dispatch, requestId])

  const isUnitialized = status === 'pending' || status === undefined
  const isLoading = status === 'pending'
  const isSuccessful = status === 'resolved'
  const isError = status === 'rejected'

  return { metadata, mutation, isLoading, isError, isUnitialized, isSuccessful }
}

export function useUpdateCategoryMutation () {
  const requestId = useRequestId()
  const dispatch = useDispatch()

  const selectMetadataByRequestId = useMemo(makeSelectMetadataByRequestId, [])
  const metadata = useSelector((state) => selectMetadataByRequestId(state, requestId))
  const status = useSelector((state) => selectStatusByRequestId(state, requestId))

  const mutation = useCallback((category) => {
    dispatch(busy({ requestId }))
    api.updateCategory(category).then(result => {
      dispatch(notBusy({ requestId, id: result.data.value?.id }))
    }).catch(handleUpdateErrors('category', dispatch, requestId))
  }, [dispatch, requestId])

  const isUnitialized = status === undefined
  const isLoading = status === 'pending'
  const isSuccessful = status === 'resolved'
  const isError = status === 'rejected'

  return { metadata, mutation, isLoading, isError, isUnitialized, isSuccessful }
}

export function useDeleteCategoryMutation () {
  const requestId = useRequestId()
  const dispatch = useDispatch()

  const selectMetadataByRequestId = useMemo(makeSelectMetadataByRequestId, [])
  const metadata = useSelector((state) => selectMetadataByRequestId(state, requestId))
  const status = useSelector((state) => selectStatusByRequestId(state, requestId))

  const mutation = useCallback((category) => {
    dispatch(busy({ id: category.id, requestId }))
    api.deleteCategory(category).then(() => {
      dispatch(notBusy({ requestId }))
      dispatch(getPageItems())
    }).catch(handleDeleteErrors('category', dispatch, requestId))
  }, [dispatch, requestId])

  const isUnitialized = status === undefined
  const isLoading = status === 'pending'
  const isSuccessful = status === 'resolved'
  const isError = status === 'rejected'

  return { metadata, mutation, isLoading, isError, isUnitialized, isSuccessful }
}
