
// admin
import { account } from './account/reducer.js'
import { permission } from './permission/reducer.js'
import { tenant } from './tenant/reducer.js'

// user
import { window } from './window/reducer.js'
import busy from './busy/reducer.js'
import { category } from './category/reducer.js'
import { contact } from './contact/reducer.js'
import { contactPerson } from './contact-person/reducer.js'
import { contentType } from './content-type/reducer.js'
import { department } from './department/reducer.js'
import { employee } from './employee/reducer.js'
import { error } from './error/reducer.js'
import { files } from './files/reducer.js'
import { locale } from './locale/reducer.js'
import { login } from './login/reducer.js'
import { objectType } from './object-type/reducer.js'
import { project } from './project/reducer.js'
import { role } from './role/reducer.js'
import { tag } from './tag/reducer.js'
import { ticket } from './ticket/reducer.js'

export function createReducers (resources) {
  return {
    account,
    busy,
    category,
    contact,
    contactPerson,
    contentType,
    department,
    employee,
    error,
    files,
    locale: locale(resources),
    login,
    objectType,
    permission,
    project,
    role,
    tag,
    tenant,
    ticket,
    window
  }
}
