import { useMemo, useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRequestId } from '../../hooks/useRequestId'
import redux, { api } from '../'
import { busy, notBusy } from '../busy/reducer'
import { makeSelectMetadataByRequestId, selectStatusByRequestId } from '../busy/selectors'
import { handleGetErrors, handleAddErrors, handleDeleteErrors, handleUpdateErrors } from '../error/actions'
import { makeSelectorById } from './selectors'

const { actions } = redux

export function useGetProjectByIdQuery (id) {
  const reqId = useRequestId()
  const dispatch = useDispatch()
  const [refetching, setRefetching] = useState(false)
  const selectContactById = useMemo(makeSelectorById, [])

  const status = useSelector((state) => selectStatusByRequestId(state, reqId))
  const data = useSelector((state) => selectContactById(state, id))

  const effects = useCallback((requestId, params) => {
    dispatch(busy({ requestId }))
    api.getProjects(params).then(result => {
      dispatch(actions.project.receivedProjects(result.data.value))
      dispatch(notBusy({ requestId }))
    }).catch(handleGetErrors('project', dispatch, requestId))
  }, [dispatch])

  useEffect(() => {
    if (status === undefined || refetching) {
      effects(reqId, { query: [{ key: 'id', op: '=', value: id }] })
      setRefetching(false)
    }
  }, [effects, id, reqId, status, refetching, setRefetching])

  const refetch = useCallback(() => {
    setRefetching(true)
  }, [setRefetching])

  const isLoading = status === 'pending' || status === undefined
  const isError = status === 'rejected'
  const isSuccessful = status === 'fulfilled'
  const isUnitialized = status === undefined
  return { isUnitialized, isError, isSuccessful, data, refetch, isLoading }
}

export function useAddProjectMutation () {
  const requestId = useRequestId()
  const dispatch = useDispatch()
  const selectMetadataByRequestId = useMemo(makeSelectMetadataByRequestId, [])

  const metadata = useSelector((state) => selectMetadataByRequestId(state, requestId))
  const status = useSelector((state) => selectStatusByRequestId(state, requestId))

  const mutation = useCallback((project) => {
    dispatch(busy({ requestId }))
    api.addProject(project).then(result => {
      dispatch(notBusy({ requestId, id: result.data.value?.id }))
    }).catch(handleAddErrors('project', dispatch))
  }, [dispatch, requestId])

  const isUnitialized = status === 'pending' || status === undefined
  const isLoading = status === 'pending'
  const isSuccessful = status === 'resolved'
  const isError = status === 'rejected'

  return { metadata, mutation, isLoading, isError, isUnitialized, isSuccessful }
}

export function useUpdateProjectMutation () {
  const requestId = useRequestId()
  const dispatch = useDispatch()

  const selectMetadataByRequestId = useMemo(makeSelectMetadataByRequestId, [])
  const metadata = useSelector((state) => selectMetadataByRequestId(state, requestId))
  const status = useSelector((state) => selectStatusByRequestId(state, requestId))

  const mutation = useCallback((project) => {
    dispatch(busy({ requestId }))
    api.updateProject(project).then(result => {
      dispatch(notBusy({ requestId, id: result.data.value?.id }))
    }).catch(handleUpdateErrors('project', dispatch, requestId))
  }, [dispatch, requestId])

  const isUnitialized = status === undefined
  const isLoading = status === 'pending'
  const isSuccessful = status === 'resolved'
  const isError = status === 'rejected'

  return { metadata, mutation, isLoading, isError, isUnitialized, isSuccessful }
}

export function useDeleteProjectMutation () {
  const requestId = useRequestId()
  const dispatch = useDispatch()

  const selectMetadataByRequestId = useMemo(makeSelectMetadataByRequestId, [])
  const metadata = useSelector((state) => selectMetadataByRequestId(state, requestId))
  const status = useSelector((state) => selectStatusByRequestId(state, requestId))

  const mutation = useCallback((project) => {
    dispatch(busy({ requestId }))
    api.deleteProject(project).then(() => {
      dispatch(notBusy({ requestId }))
      dispatch(actions.project.getPageItems())
    }).catch(handleDeleteErrors('project', dispatch, requestId))
  }, [dispatch, requestId])

  const isUnitialized = status === undefined
  const isLoading = status === 'pending'
  const isSuccessful = status === 'resolved'
  const isError = status === 'rejected'

  return { metadata, mutation, isLoading, isError, isUnitialized, isSuccessful }
}

export function useFavoriteProjectMutation () {
  const requestId = useRequestId()
  const dispatch = useDispatch()

  const selectMetadataByRequestId = useMemo(makeSelectMetadataByRequestId, [])
  const metadata = useSelector((state) => selectMetadataByRequestId(state, requestId))
  const status = useSelector((state) => selectStatusByRequestId(state, requestId))

  const mutation = useCallback((project) => {
    const favorite = !project.is_favorite
    dispatch(busy({ requestId }))
    api.setProjectFavorite(project.id, favorite).then(() => {
      dispatch(notBusy({ requestId }))
    }).catch(handleDeleteErrors('project', dispatch, requestId))
  }, [dispatch, requestId])

  const isUnitialized = status === undefined
  const isLoading = status === 'pending'
  const isSuccessful = status === 'resolved'
  const isError = status === 'rejected'

  return { metadata, mutation, isLoading, isError, isUnitialized, isSuccessful }
}
