import { createSelector } from '@reduxjs/toolkit'

export const makeSelectorById = () => createSelector(
  (state) => state.department,
  (_, id) => id,
  (slice, id) => {
    return slice.items?.find(({ id: _id }) => _id === Number(id))
  }
)

export const makeSelectorDepartment = () => createSelector(
  (state) => state.department,
  (slice) => {
    return slice.items || []
  }
)

export const makeSelectorSelectedItemId = () => createSelector(
  (state) => state.department,
  (slice) => {
    return slice.selectedItemId
  }
)

export const makeSelectorByIsFavorite = () => createSelector(
  (state) => state.department,
  (_, id) => id,
  (slice, id) => {
    const department = slice.items.find(({ id: _id }) => _id === Number(id))
    return department?.is_favorite
  }
)

export const makeSelectorBySharepointStatus = () => createSelector(
  (state) => state.department,
  (_, id) => id,
  (slice, id) => {
    const item = slice.items.find((item) => item.id === Number(id))
    return item && item.sharepoint_status
  }
)

export const makeSelectorBySharepointUrl = () => createSelector(
  (state) => state.department,
  (_, id) => id,
  (slice, id) => {
    const item = slice.items.find((item) => item.id === Number(id))
    return item && item.url
  }
)
