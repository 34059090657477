import { busy, notBusy } from '../busy/actions.js'
import {
  handleAddErrors,
  handleGetErrors,
  handleUpdateErrors,
  handleDeleteErrors
} from '../error/actions.js'

import api from '../api.js'
import store from '../store.js'

export const ROLE_CREATE_ACTION = 'ROLE_CREATE_ACTION'
export const ROLE_SHOW_ADD_DIALOG_ACTION = 'ROLE_SHOW_ADD_DIALOG_ACTION'
export const ROLE_HIDE_ADD_DIALOG_ACTION = 'ROLE_HIDE_ADD_DIALOG_ACTION'
export const ROLE_GET_OK_ACTION = 'ROLE_GET_OK_ACTION'
export const ROLE_UPDATE_ACTION = 'ROLE_UPDATE_ACTION'
export const ROLE_SHOW_EDIT_DIALOG_ACTION = 'ROLE_SHOW_EDIT_DIALOG_ACTION'
export const ROLE_HIDE_EDIT_DIALOG_ACTION = 'ROLE_HIDE_EDIT_DIALOG_ACTION'
export const ROLE_DELETE_ACTION = 'ROLE_DELETE_ACTION'
export const ROLE_SHOW_DELETE_DIALOG_ACTION = 'ROLE_SHOW_DELETE_DIALOG_ACTION'
export const ROLE_HIDE_DELETE_DIALOG_ACTION = 'ROLE_HIDE_DELETE_DIALOG_ACTION'

export const ROLE_GET_PAGE_ITEMS_ACTION = 'ROLE_GET_PAGE_ITEMS_ACTION'
export const ROLE_SET_ORDER_ACTION = 'ROLE_SET_ORDER_ACTION'
export const ROLE_SET_LIMIT_ACTION = 'ROLE_SET_LIMIT_ACTION'
export const ROLE_SET_OFFSET_ACTION = 'ROLE_SET_OFFSET_ACTION'
export const ROLE_SET_SEARCH_ACTION = 'ROLE_SET_SEARCH_ACTION'
export const ROLE_RESET_SEARCH_ACTION = 'ROLE_RESET_SEARCH_ACTION'

export function roleCreateAction (role) {
  return {
    type: ROLE_CREATE_ACTION,
    role
  }
}

export function roleUpdateAction (role) {
  return {
    type: ROLE_UPDATE_ACTION,
    role
  }
}

export function roleDeleteAction (role) {
  return {
    type: ROLE_DELETE_ACTION,
    role
  }
}

/**
 * Add dialog
 */
export function showAddRoleDialog () {
  return {
    type: ROLE_SHOW_ADD_DIALOG_ACTION
  }
}

export function hideAddRoleDialog () {
  return {
    type: ROLE_HIDE_ADD_DIALOG_ACTION
  }
}

/**
 * Edit dialog
 */
export function showEditRoleDialog (role) {
  return {
    type: ROLE_SHOW_EDIT_DIALOG_ACTION,
    role
  }
}

export function hideEditRoleDialog () {
  return {
    type: ROLE_HIDE_EDIT_DIALOG_ACTION
  }
}

/**
 * Delete dialog
 */
export function showDeleteRoleDialog (role) {
  return {
    type: ROLE_SHOW_DELETE_DIALOG_ACTION,
    role
  }
}

export function hideDeleteRoleDialog () {
  return {
    type: ROLE_HIDE_DELETE_DIALOG_ACTION
  }
}

/**
 * CREATE
 */
export function addRole (role) {
  return function (dispatch) {
    dispatch(busy())
    api.addRole(role).then(() => {
      dispatch(notBusy())
    }).catch(handleAddErrors('role', dispatch))
  }
}

/**
 * READ
 */
export function getRoles () {
  return function (dispatch) {
    dispatch(busy())
    api.getRoles().then(result => {
      dispatch(notBusy())
      dispatch(receivedRoles(result.data.value))
    }).catch(handleGetErrors('role', dispatch))
  }
}

export function receivedRoles (items) {
  return {
    type: ROLE_GET_OK_ACTION,
    items
  }
}

/**
 * UPDATE
 */
export function updateRole (role) {
  return function (dispatch) {
    dispatch(busy())
    api.updateRole(role).then(() => {
      dispatch(notBusy())
    }).catch(handleUpdateErrors('role', dispatch))
  }
}

/**
 * DELETE
 */
export function deleteRole (role) {
  return function (dispatch) {
    dispatch(busy())
    api.deleteRole(role).then(() => {
      dispatch(notBusy())
    }).catch(handleDeleteErrors('role', dispatch))
  }
}

/**
 * Get items for a page
 */
export function getPageItems () {
  const state = store.getState()
  const { search, limit, offset, orderBy, order } = state.role

  const query = []
  if (typeof search === 'string' && search.length > 0) {
    query.push({ key: 'name', value: search, op: '~' })
  }

  const sort = orderBy ? `${orderBy}.${order}` : null

  return function (dispatch) {
    dispatch(busy())
    api.getRoles({
      query,
      limit,
      offset,
      sort
    }).then(result => {
      dispatch(notBusy())
      dispatch({
        type: ROLE_GET_PAGE_ITEMS_ACTION,
        pageItems: result.data.value
      })
    }).catch(handleGetErrors('role', dispatch))
  }
}

/**
 * Sort order
 */
export function setOrder (orderBy, order) {
  return {
    type: ROLE_SET_ORDER_ACTION,
    orderBy,
    order
  }
}

/**
 * Set limit for pagination
 */
export function setLimit (limit) {
  return {
    type: ROLE_SET_LIMIT_ACTION,
    limit
  }
}

/**
 * Set offset for pagination
 */
export function setOffset (offset) {
  return {
    type: ROLE_SET_OFFSET_ACTION,
    offset
  }
}

/**
 * Set search
 */
export function setSearch (search) {
  return {
    type: ROLE_SET_SEARCH_ACTION,
    search
  }
}

/**
 * Reset search
 */
export function resetSearch (search) {
  return {
    type: ROLE_RESET_SEARCH_ACTION,
    search
  }
}
