import React from 'react'
import { Link, Waves } from '@pergas-common/pergas-components'
import { Folder, Sharepoint, Teams, Warning } from '@pergas-common/pergas-icons'
import dayjs from 'dayjs'
import Tooltip from '../Tooltip'
import { selectLocale } from '../../redux/locale/selectors'
import { useSelector, connect } from 'react-redux'
import redux from '../../redux'

export const createDateAccessor = (prop, format = 'YYYY-MM-DD HH:mm') => {
  return (row) => {
    if (typeof row[prop] === 'string') {
      const d = dayjs(row[prop])
      return d.format(format)
    } else {
      return ''
    }
  }
}

export const isExpired = (date, format = 'YYYY-MM-DD') => {
  return dayjs().format(format) > date
}

// TODO remove this, not everything will show both folders and sharepoint page
// Only used in components/ProjectGrid.js once pages have been converted
export const sharePointAccessor = (row) => {
  const locale = useSelector(selectLocale)
  function renderSharePointLinks () {
    const url = row.url ? `${row.url}/files/Forms/AllItems.aspx` : null
    if (row.type === 'category') {
      return (<Tooltip content={locale.category}><Link href={`${row.url}`}><Folder width={18} height={18} /></Link></Tooltip>)
    }
    return (
      <>
        <Tooltip content={locale.documents}><Link href={`${url}`}><Folder width={18} height={18} /></Link></Tooltip>
        <Tooltip content={locale.startpage}><Link href={`${row.url}`}><Sharepoint width={18} height={18} /></Link></Tooltip>
      </>
    )
  }
  switch (row.sharepoint_status) {
    case 'finished':
      return renderSharePointLinks()
    case 'error':
      return <Warning width={18} height={18} color='#FF5656' />
    case 'creating':
    case 'updating':
      return <Waves width={18} height={18} color='#20a3a9' />
    default:
      return row.url ? renderSharePointLinks() : null
  }
}

export const SharePointFolder = (row) => {
  const locale = useSelector(selectLocale)
  function url () {
    if (row.type === 'category') {
      return row.url
    } else {
      return row.url ? `${row.url}/files/Forms/AllItems.aspx` : null
    }
  }
  function renderIcon () {
    return <Tooltip content={locale.documents}><Link href={`${url()}`}><Folder width={18} height={18} /></Link></Tooltip>
  }
  switch (row.sharepoint_status) {
    case 'finished':
      return renderIcon()
    case 'error':
      return <Warning width={18} height={18} color='#FF5656' />
    case 'creating':
    case 'updating':
      return <Waves width={18} height={18} color='#20a3a9' />
    default:
      return row.url ? renderIcon() : null
  }
}

export const SharePointSite = (row) => {
  const locale = useSelector(selectLocale)
  function renderIcon () {
    return <Tooltip content={locale.startpage}><Link href={`${row.url}`}><Sharepoint width={18} height={18} /></Link></Tooltip>
  }
  switch (row.sharepoint_status) {
    case 'finished':
      return renderIcon()
    case 'error':
      return <Warning width={18} height={18} color='#FF5656' />
    case 'creating':
    case 'updating':
      return <Waves width={18} height={18} color='#20a3a9' />
    default:
      return row.url ? renderIcon() : null
  }
}

const TeamsToolIcon_ = ({
  // Redux state params
  locale,
  // Redux dispatch params
  gotoTeamsPage,
  // Parent component params
  row
}) => {
  const onClickHandler = (e) => {
    e.stopPropagation()
    gotoTeamsPage(row.group_id)
  }
  function renderTeamsIcon () {
    return <Tooltip content='Teams'><Link onClickHandler={onClickHandler}><Teams width={18} height={18} style={{ marginTop: -2 }} /></Link></Tooltip>
  }
  switch (row.sharepoint_status) {
    case 'finished':
      return renderTeamsIcon()
    case 'error':
      return <Warning width={18} height={18} color='#FF5656' />
    case 'creating':
    case 'updating':
      return <Waves width={18} height={18} color='#20a3a9' />
    default:
      return row.group_id ? renderTeamsIcon() : null
  }
}

const mapStateToProps = (state) => {
  const { locale } = state
  return {
    locale: locale.strings
  }
}

const mapDispatchToProps = (dispatch) => {
  const { actions: { util } } = redux
  return {
    gotoTeamsPage: (groupId) => {
      dispatch(util.gotoTeamsPage(groupId))
    }
  }
}

export const TeamsToolIcon = connect(mapStateToProps, mapDispatchToProps)(TeamsToolIcon_)
