import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  styled,
  spacing
} from '@pergas-common/pergas-components'
import { selectLocale } from '../../redux/locale/selectors'
import { makeSelectorByEmployeeId } from '../../redux/login/selectors'
import { Radio } from '../Input'

const Label = styled.label`
  display: flex;
  align-items: stretch;
  margin-left: 24px;
  height: 100%;
`

const InputHolder = styled.div`
  display: inline-flex;
  align-items: center;
  > small {
    margin-left: ${spacing(1)};
  }
`

const FilterMine = ({ applyFilter, checked = false }) => {
  const selectMyEmployeeId = useMemo(makeSelectorByEmployeeId, [])
  const myEmployeeId = useSelector((state) => selectMyEmployeeId(state))
  const locale = useSelector(selectLocale)

  return (
    <>
      <Label>
        <InputHolder>
          <Radio
            value={checked}
            disabled={!myEmployeeId}
            label={locale.all} checked={checked} onChange={() => {
              applyFilter(myEmployeeId)
            }}
          />
          <small>{locale.mine}</small>
        </InputHolder>
      </Label>
    </>
  )
}

export default FilterMine
