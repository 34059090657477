import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik'
import { styled, Modal, Button, Group } from '@pergas-common/pergas-components'
import { Company, Add, Close } from '@pergas-common/pergas-icons'
import { selectLocale } from '../../redux/locale/selectors'
import { api } from '../../redux'
import useRequest from '../../hooks/useRequest'
import useDebounce from '../../hooks/useDebounce'
import { InputGroup, Select } from '../Input'

const Holder = styled.div`
  display: flex;
  height: 130px;
  flex-direction: column;
`

const ContentHolder = styled.div`
  max-width: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 32px;
  align-self: center;
`

const useAvailableRoles = (collection, roles) => {
  const { values } = useFormikContext()

  const selectableRoles = Array.isArray(roles) && roles.filter((r) => {
    if (collection && values?.collection_role?.length > 0) {
      const hasRole = values.collection_role.some((cr) => cr.collection_id === collection.collection_id && cr.role_id === r.id)
      return !hasRole && r.internal_name !== 'responsible'
    }
    return r.internal_name !== 'responsible'
  })

  return selectableRoles || []
}

export const EditContactRole = ({ isOpen, onCloseRequest, selected }) => {
  const { values, setFieldValue } = useFormikContext()
  const locale = useSelector(selectLocale)
  const [role, setRole] = useState(null)
  const [getRoles, roles, rolesStatus] = useRequest(api.getRoles, 'roles')

  const [debouncedRoleRequest] = useDebounce(getRoles, 300)
  const selectableRoles = useAvailableRoles(selected, roles)

  const disableSubmit = selected === null || role === null

  const onSelect = useCallback((c) => {
    const containing = Array.isArray(values.collection_role) ? [...values.collection_role] : []
    const index = containing.findIndex((cr) => cr.collection_id === c.collection_id)
    if (index > -1) {
      containing[index] = c
      setFieldValue('collection_role', containing)
    }
    onCloseRequest()
  }, [setFieldValue, onCloseRequest, values])

  useEffect(() => {
    getRoles({ sort: 'name.asc', limit: 50 })
  }, [getRoles])

  return (
    <Modal
      title={locale.contact_role} titleIcon={<Company width={20} height={20} />} isOpen={isOpen} size='lg' footer={() => (
        <Group.Button>
          <Button onClick={() => {
            onCloseRequest()
          }}
          ><span>{locale.cancel}</span><Close width={18} height={18} />
          </Button>
          <Button
            disabled={disableSubmit} onClick={() => {
              const contactRole = {
                ...selected,
                role_id: role.id,
                role_name: role.name,
                role_internal_name: role.internal_name
              }
              onSelect(contactRole)
            }}
          ><span>{locale.update}</span><Add width={20} height={20} />
          </Button>
        </Group.Button>
      )}
    >
      {() => (
        <Holder>
          <ContentHolder>
            <InputGroup fullWidth>
              <Select
                name='name'
                label={locale.name}
                defaultValue={{ id: selected.collection_id, name: selected.collection_name }}
                disabled
                requestStatus={false}
                items={[]}
                handleChange={() => {}}
              />
              <Select
                name='role'
                label={locale.role}
                disabled={selected === null}
                defaultValue={{ id: selected.role_id, name: selected.role_name }}
                request={(input) => {
                  debouncedRoleRequest({ query: [{ key: 'name', op: '~', value: input }], sort: 'name.asc', limit: 50 })
                }}
                requestStatus={rolesStatus === 'pending'}
                items={selectableRoles}
                handleChange={(_, id, role) => {
                  setRole({ ...role, id })
                }}
              />
            </InputGroup>
          </ContentHolder>
        </Holder>
      )}
    </Modal>
  )
}
