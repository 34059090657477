import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { styled, media, spacing, Avatar, Modal, Button, Link, Group } from '@pergas-common/pergas-components'
import { WarningOutlined } from '@mui/icons-material'
import redux from '../redux/index.js'
import { selectLocale } from '../redux/locale/selectors'
import { Info, Lock } from '@pergas-common/pergas-icons'
import PdsLogo from './pds-logo.jsx'
import { makeSelectorByAvatar, makeSelectorByName, makeSelectorByTenantName } from '../redux/login/selectors.js'
import Tooltip from './Tooltip.js'
import { TOOLBAR_BG_COLOR } from './style.js'
import { useNavigate } from 'react-router-dom'

const TopbarHolder = styled.div`
  height: 50px;
  width: 100%;
  background: ${TOOLBAR_BG_COLOR};
`
const ConfirmLogoutBody = styled.div`
  height: 50px;
  padding: 16px;
  > span {
    ${({ theme }) => theme.typography.body};
    color: ${({ theme }) => theme.palette.brand.secondary};
  }
`

const BrandText = styled.div`
  margin-left: 10px;
  font-family: "Seqoe UI Bold";
  font-size: 16px;
`

const RightSideHolder = styled.div`
  display: inline-flex;
  align-items: center;
  * {
    margin: 0 ${spacing(0.5)};
  }
  > p {
    margin: 0;
    display: flex;
  }
  > span {
    ${({ theme }) => theme.typography.body};
    color: ${({ theme }) => theme.palette.common.primary};
    display: none;
    ${media.medium`
      display: inherit;
    `}
  }
`

const SlotHolder = styled.div`
  display: flex;
  margin: auto;
  background-color: inherit;
  height: 50px;
  box-shadow: 0 3px 6px 0 rgb(0, 0, 0, 0.16);
  align-items: center;
  padding: 0 ${spacing(1)};
`

const LeftSlot = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const RightSlot = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  margin-right: ${spacing(2)};
`

const appVersionFull = process.env.REACT_APP_VERSION

const ConfirmLogout = ({ isOpen, onSubmit, onCancel }) => {
  const locale = useSelector(selectLocale)
  return (
    <Modal
      title={locale.logout} titleIcon={<WarningOutlined style={{ width: '20px', height: '20px' }} />} isOpen={isOpen} onCloseRequest={onCancel} size='md' footer={() => (
        <Group.Button>
          <Button onClick={onSubmit}>{locale.logout}</Button>
          <Button onClick={onCancel}>{locale.cancel}</Button>
        </Group.Button>
      )}
    >
      {() => (
        <ConfirmLogoutBody><span>{locale.logout_confirm}</span></ConfirmLogoutBody>
      )}
    </Modal>
  )
}

const AppVersion = () => {
  const selectTenantName = useMemo(makeSelectorByTenantName, [])
  const locale = useSelector(selectLocale)
  const tenantName = useSelector(selectTenantName)

  const appVersionSplit = appVersionFull.split('-')
  const appVersion = appVersionSplit.length > 0 ? appVersionSplit[0] : 'N/A'
  const build = appVersionSplit.length > 1 ? appVersionSplit[1] : null
  const hash = appVersionSplit.length > 2 ? appVersionSplit[2].substring(1) : null

  return (
    <>
      <Link href='https://pds.site/support'>Support</Link>
      <div>{locale.version}: {appVersion}</div>
      <div>{build ? `Build: ${build}` : null}</div>
      <div>{hash ? `Git: ${hash}` : null}</div>
      <div>{`${locale.tenant_name}: ${tenantName}`}</div>
    </>
  )
}

export default function TopBar () {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const selectAvatar = useMemo(makeSelectorByAvatar, [])
  const selectName = useMemo(makeSelectorByName, [])

  const locale = useSelector(selectLocale)
  const avatar = useSelector(selectAvatar)
  const name = useSelector(selectName)
  const [isConfirmLogoutOpen, setIsConfirmLogoutOpen] = useState(false)

  const left = (
    <>
      <Link
        onClickHandler={() => {
          navigate('/', { replace: true })
        }}
      >
        <PdsLogo />
      </Link>
      <BrandText>Contact Manager</BrandText>
    </>
  )

  const right = (
    <RightSideHolder>
      <Avatar src={avatar} />
      <span>{name}</span>
      <Tooltip content={<AppVersion />}>
        <span><Info width={24} height={24} color='lightgreen' /></span>
      </Tooltip>
      <Tooltip content={locale.logout}>
        <p>
          <Link onClickHandler={() => {
            setIsConfirmLogoutOpen(true)
          }}
          >
            <Lock width={24} height={24} color='#FFFFFF' />
          </Link>
        </p>
      </Tooltip>
    </RightSideHolder>
  )

  return (
    <TopbarHolder>
      {isConfirmLogoutOpen && (
        <ConfirmLogout
          isOpen={isConfirmLogoutOpen} onSubmit={() => {
            dispatch(redux.actions.login.doLogout())
            setIsConfirmLogoutOpen(false)
          }} onCancel={() => {
            setIsConfirmLogoutOpen(false)
          }}
        />
      )}
      <SlotHolder>
        <LeftSlot>{left && left}</LeftSlot>
        <RightSlot>{right && right}</RightSlot>
      </SlotHolder>
    </TopbarHolder>
  )
}
