import { busy, notBusy } from '../busy/actions.js'
import {
  handleAddErrors,
  handleGetErrors,
  handleUpdateErrors,
  handleDeleteErrors
} from '../error/actions.js'

import api from '../api.js'
import store from '../store.js'
import storage from '../storage.js'

export const CONTACT_PERSON_CREATE_ACTION = 'CONTACT_PERSON_CREATE_ACTION'
export const CONTACT_PERSON_SHOW_ADD_DIALOG_ACTION = 'CONTACT_PERSON_SHOW_ADD_DIALOG_ACTION'
export const CONTACT_PERSON_HIDE_ADD_DIALOG_ACTION = 'CONTACT_PERSON_HIDE_ADD_DIALOG_ACTION'
export const CONTACT_PERSON_GET_OK_ACTION = 'CONTACT_PERSON_GET_OK_ACTION'
export const CONTACT_PERSON_UPDATE_ACTION = 'CONTACT_PERSON_UPDATE_ACTION'
export const CONTACT_PERSON_SHOW_EDIT_DIALOG_ACTION = 'CONTACT_PERSON_SHOW_EDIT_DIALOG_ACTION'
export const CONTACT_PERSON_HIDE_EDIT_DIALOG_ACTION = 'CONTACT_PERSON_HIDE_EDIT_DIALOG_ACTION'
export const CONTACT_PERSON_DELETE_ACTION = 'CONTACT_PERSON_DELETE_ACTION'
export const CONTACT_PERSON_SHOW_DELETE_DIALOG_ACTION = 'CONTACT_PERSON_SHOW_DELETE_DIALOG_ACTION'
export const CONTACT_PERSON_HIDE_DELETE_DIALOG_ACTION = 'CONTACT_PERSON_HIDE_DELETE_DIALOG_ACTION'

export const CONTACT_PERSON_GET_PAGE_ITEMS_ACTION = 'CONTACT_PERSON_GET_PAGE_ITEMS_ACTION'
export const CONTACT_PERSON_SET_ORDER_ACTION = 'CONTACT_PERSON_SET_ORDER_ACTION'
export const CONTACT_PERSON_SET_LIMIT_ACTION = 'CONTACT_PERSON_SET_LIMIT_ACTION'
export const CONTACT_PERSON_SET_OFFSET_ACTION = 'CONTACT_PERSON_SET_OFFSET_ACTION'
export const CONTACT_PERSON_SET_SEARCH_ACTION = 'CONTACT_PERSON_SET_SEARCH_ACTION'
export const CONTACT_PERSON_RESET_SEARCH_ACTION = 'CONTACT_PERSON_RESET_SEARCH_ACTION'
export const CONTACT_PERSON_SET_FILTER_QUERIES_ACTION = 'CONTACT_PERSON_SET_FILTER_QUERIES_ACTION'

export function contactPersonCreateAction (contactPerson) {
  return {
    type: CONTACT_PERSON_CREATE_ACTION,
    contactPerson
  }
}

export function contactPersonUpdateAction (contactPerson) {
  return {
    type: CONTACT_PERSON_UPDATE_ACTION,
    contactPerson
  }
}

export function contactPersonDeleteAction (contactPerson) {
  return {
    type: CONTACT_PERSON_DELETE_ACTION,
    contactPerson
  }
}

/**
 * Add dialog
 */
export function showAddContactPersonDialog () {
  return {
    type: CONTACT_PERSON_SHOW_ADD_DIALOG_ACTION
  }
}

export function hideAddContactPersonDialog () {
  return {
    type: CONTACT_PERSON_HIDE_ADD_DIALOG_ACTION
  }
}

/**
 * Edit dialog
 */
export function showEditContactPersonDialog (contactPerson) {
  return {
    type: CONTACT_PERSON_SHOW_EDIT_DIALOG_ACTION,
    contactPerson
  }
}

export function hideEditContactPersonDialog () {
  return {
    type: CONTACT_PERSON_HIDE_EDIT_DIALOG_ACTION
  }
}

/**
 * Delete dialog
 */
export function showDeleteContactPersonDialog (contactPerson) {
  return {
    type: CONTACT_PERSON_SHOW_DELETE_DIALOG_ACTION,
    contactPerson
  }
}

export function hideDeleteContactPersonDialog () {
  return {
    type: CONTACT_PERSON_HIDE_DELETE_DIALOG_ACTION
  }
}

/**
 * CREATE
 */
export function addContactPerson (contactPerson) {
  return function (dispatch) {
    dispatch(busy())
    api.addContactPerson(contactPerson).then(() => {
      dispatch(notBusy())
    }).catch(handleAddErrors('contactPerson', dispatch))
  }
}

/**
 * READ
 */
// TODO should be replaced by search function later
export function getContactPersons (params = {}) {
  return function (dispatch) {
    dispatch(busy())
    api.getContactPersons(params).then(result => {
      dispatch(notBusy())
      dispatch(receivedContactPersons(result.data.value))
    }).catch(handleGetErrors('contactPerson', dispatch))
  }
}

function receivedContactPersons (items) {
  return {
    type: CONTACT_PERSON_GET_OK_ACTION,
    items
  }
}

/**
 * UPDATE
 */
export function updateContactPerson (contactPerson) {
  return function (dispatch) {
    dispatch(busy())
    api.updateContactPerson(contactPerson).then(() => {
      dispatch(notBusy())
    }).catch(handleUpdateErrors('contactPerson', dispatch))
  }
}

/**
 * DELETE
 */
export function deleteContactPerson (contactPerson) {
  return function (dispatch) {
    dispatch(busy())
    api.deleteContactPerson(contactPerson).then(() => {
      dispatch(notBusy())
      dispatch(getPageItems())
    }).catch(handleDeleteErrors('contactPerson', dispatch))
  }
}

/**
 * Get items for a page
 */
export function getPageItems () {
  const state = store.getState()
  const { search, filterQueries, limit, offset, orderBy, order } = state.contactPerson

  let query = []
  if (typeof search === 'string' && search.length > 0) {
    const split = search.split(',').map(s => s.trim())
    if (split.length === 1) {
      if (split[0].length > 0) query.push({ key: 'name', value: split[0], op: '~' })
    } else if (split.length === 2) {
      if (split[0].length > 0) query.push({ key: 'name', value: split[0], op: '~' })
      if (split[1].length > 0) query.push({ key: 'collection_name', value: split[1], op: '~' })
    }
  }
  if (Array.isArray(filterQueries) && filterQueries.length > 0) {
    query = [...query, ...filterQueries]
  }

  const sort = orderBy ? `${orderBy}.${order}` : null

  return function (dispatch) {
    dispatch(busy())
    api.getContactPersons({
      query,
      limit,
      offset,
      sort
    }).then(result => {
      dispatch(notBusy())
      dispatch({
        type: CONTACT_PERSON_GET_PAGE_ITEMS_ACTION,
        pageItems: result.data.value
      })
    }).catch(handleGetErrors('contactPerson', dispatch))
  }
}

/**
 * Sort order
 */
export function setOrder (orderBy, order) {
  return {
    type: CONTACT_PERSON_SET_ORDER_ACTION,
    orderBy,
    order
  }
}

/**
 * Set limit for pagination
 */
export function setLimit (limit) {
  return {
    type: CONTACT_PERSON_SET_LIMIT_ACTION,
    limit
  }
}

/**
 * Set offset for pagination
 */
export function setOffset (offset) {
  return {
    type: CONTACT_PERSON_SET_OFFSET_ACTION,
    offset
  }
}

/**
 * Set search
 */
export function setSearch (search) {
  return {
    type: CONTACT_PERSON_SET_SEARCH_ACTION,
    search
  }
}

/**
 * Set filter queries
 */
export function setFilterQueries (filterQueries) {
  storage.putPageSetting('contact-person', { filterQueries })
  return {
    type: CONTACT_PERSON_SET_FILTER_QUERIES_ACTION,
    filterQueries
  }
}

/**
 * Reset search
 */
export function resetSearch () {
  return {
    type: CONTACT_PERSON_RESET_SEARCH_ACTION
  }
}
