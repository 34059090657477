import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  styled,
  spacing
} from '@pergas-common/pergas-components'
import { api } from '../../redux'
import { selectLocale } from '../../redux/locale/selectors'
import { makeSelectorByEmployeeId } from '../../redux/login/selectors'
import useRequest from '../../hooks/useRequest'
import useDebounce from '../../hooks/useDebounce.js'
import { Radio, Select } from '../Input'

const Holder = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  align-self: center;
  max-width: 320px;
  width: 100%;
`

const FilterRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: center;
`

const InputHolder = styled.div`
  display: inline-flex;
  align-items: center;
  > small {
    margin-left: ${spacing(1)};
  }
`

const SelectRadioLabel = styled.label`
  display: inline-flex;
  align-items: flex-end;
  > div {
    height: 48px;
    margin-left: ${spacing(1)};
  }
`

const FilterContact = ({ label, radio = true, applyFilter, rules }) => {
  const selectMyEmployeeId = useMemo(makeSelectorByEmployeeId, [])
  const [contact, setContact] = useState(rules.find(({ key }) => key === 'collection_id') ?? { id: 0, name: '' })

  const myEmployeeId = useSelector((state) => selectMyEmployeeId(state))
  const locale = useSelector(selectLocale)

  const isSpecifiedEmployeeFilterApplied = rules.filter(({ key, value }) => key === 'collection_id' && value !== myEmployeeId).length > 0
  const [request, result = [], status] = useRequest(api.getContacts, 'contact')
  const [debouncedRequest] = useDebounce(request, 300)

  useEffect(() => {
    request({ query: [{ key: 'name', op: '=', value: '' }], sort: 'name.asc', limit: 50 })
  }, [request])

  return (
    <>
      <Holder>
        <FilterRow>
          <SelectRadioLabel>
            {radio && (
              <InputHolder>
                <Radio
                  value={isSpecifiedEmployeeFilterApplied}
                  readOnly label={locale.contact_object_type} checked={isSpecifiedEmployeeFilterApplied} onChange={() => {
                    if (contact) {
                      applyFilter(contact.id, contact)
                    }
                  }}
                />
              </InputHolder>
            )}
            <Select
              name='contact'
              label={label}
              defaultValue={contact}
              cb={({ selectedItem, inputValue }) => {
                if (inputValue === '') {
                  applyFilter(null)
                  setContact(null)
                }

                if (selectedItem && inputValue !== '') {
                  applyFilter(selectedItem.id, selectedItem)
                  setContact(selectedItem)
                }
              }}
              request={(input) => {
                debouncedRequest({ query: [{ key: 'name', op: '~', value: input }], sort: 'name.asc', limit: 50 })
              }}
              requestStatus={status === 'pending'}
              items={[{ id: 0, name: '' }, ...result ?? []]}
              handleChange={(_, id, contact) => {}}
            />
          </SelectRadioLabel>
        </FilterRow>
      </Holder>
    </>
  )
}

export default FilterContact
