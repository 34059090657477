import { busy, notBusy } from '../busy/actions.js'
import { handleAddErrors, handleGetErrors, handleUpdateErrors, handleDeleteErrors } from '../error/actions.js'
import { resetFileSearch } from '../files/actions.js'

import api from '../api.js'
import store from '../store.js'
import storage from '../storage.js'

export const CONTACT_CREATE_ACTION = 'CONTACT_CREATE_ACTION'
export const CONTACT_SHOW_ADD_DIALOG_ACTION = 'CONTACT_SHOW_ADD_DIALOG_ACTION'
export const CONTACT_HIDE_ADD_DIALOG_ACTION = 'CONTACT_HIDE_ADD_DIALOG_ACTION'
export const CONTACT_GET_OK_ACTION = 'CONTACT_GET_OK_ACTION'
export const CONTACT_UPDATE_ACTION = 'CONTACT_UPDATE_ACTION'
export const CONTACT_SHOW_EDIT_DIALOG_ACTION = 'CONTACT_SHOW_EDIT_DIALOG_ACTION'
export const CONTACT_HIDE_EDIT_DIALOG_ACTION = 'CONTACT_HIDE_EDIT_DIALOG_ACTION'
export const CONTACT_DELETE_ACTION = 'CONTACT_DELETE_ACTION'
export const CONTACT_SHOW_DELETE_DIALOG_ACTION = 'CONTACT_SHOW_DELETE_DIALOG_ACTION'
export const CONTACT_HIDE_DELETE_DIALOG_ACTION = 'CONTACT_HIDE_DELETE_DIALOG_ACTION'

export const CONTACT_GET_PAGE_ITEMS_ACTION = 'CONTACT_GET_PAGE_ITEMS_ACTION'
export const CONTACT_SET_ORDER_ACTION = 'CONTACT_SET_ORDER_ACTION'
export const CONTACT_SET_LIMIT_ACTION = 'CONTACT_SET_LIMIT_ACTION'
export const CONTACT_SET_OFFSET_ACTION = 'CONTACT_SET_OFFSET_ACTION'
export const CONTACT_SET_SEARCH_ACTION = 'CONTACT_SET_SEARCH_ACTION'
export const CONTACT_RESET_SEARCH_ACTION = 'CONTACT_RESET_SEARCH_ACTION'
export const CONTACT_SET_FILTER_QUERIES_ACTION = 'CONTACT_SET_FILTER_QUERIES_ACTION'
export const CONTACT_SET_SELECTED_ITEM_ID = 'CONTACT_SET_SELECTED_ITEM_ID'

export function contactCreateAction (contact) {
  return {
    type: CONTACT_CREATE_ACTION,
    contact
  }
}

export function contactUpdateAction (contact) {
  return {
    type: CONTACT_UPDATE_ACTION,
    contact
  }
}

export function contactDeleteAction (contact) {
  return {
    type: CONTACT_DELETE_ACTION,
    contact
  }
}

export function setSelectedItemId (selectedItemId) {
  return function (dispatch) {
    dispatch({
      type: CONTACT_SET_SELECTED_ITEM_ID,
      selectedItemId
    })
    dispatch(resetFileSearch('contact'))
  }
}

/**
 * Delete dialog
 */
export function showDeleteContactDialog (contact) {
  return {
    type: CONTACT_SHOW_DELETE_DIALOG_ACTION,
    contact
  }
}

export function hideDeleteContactDialog () {
  return {
    type: CONTACT_HIDE_DELETE_DIALOG_ACTION
  }
}

/**
 * CREATE
 */
export function addContact (contact) {
  return function (dispatch) {
    dispatch(busy())
    api.addContact(contact).then(() => {
      dispatch(notBusy())
    }).catch(handleAddErrors('contact', dispatch))
  }
}

/**
 * READ
 */
export function getContacts (params = {}) {
  return function (dispatch) {
    dispatch(busy())
    api.getContacts(params).then(result => {
      dispatch(notBusy())
      dispatch(receivedContacts(result.data.value))
    }).catch(handleGetErrors('contact', dispatch))
  }
}

export function receivedContacts (items) {
  return {
    type: CONTACT_GET_OK_ACTION,
    items
  }
}

/**
 * UPDATE
 */
export function updateContact (contact) {
  return function (dispatch) {
    dispatch(busy())
    api.updateContact(contact).then(() => {
      dispatch(notBusy())
    }).catch(handleUpdateErrors('contact', dispatch))
  }
}

/**
 * DELETE
 */
export function deleteContact (contact) {
  return function (dispatch) {
    dispatch(busy())
    api.deleteContact(contact).then(() => {
      dispatch(notBusy())
      dispatch(getPageItems())
    }).catch(handleDeleteErrors('contact', dispatch))
  }
}

export function toggleFavorite (contact) {
  return function (dispatch) {
    const favorite = !contact.is_favorite
    dispatch(busy())
    api.setContactFavorite(contact.id, favorite).then(result => {
      dispatch(notBusy())
    }).catch(handleUpdateErrors('contact', dispatch))
  }
}

/**
 * Get items for a page
 */
export function getPageItems () {
  const state = store.getState()
  const { search, filterQueries, limit, offset, orderBy, order } = state.contact

  let query = []
  if (typeof search === 'string' && search.length > 0) {
    const split = search.split(',').map(s => s.trim()).filter(Boolean)
    if (split.length) {
      query.push({ key: 'name', value: split[0], op: '~' })
    }
  }

  if (Array.isArray(filterQueries) && filterQueries.length > 0) {
    query = [...query, ...filterQueries]
  }

  const sort = orderBy ? `${orderBy}.${order}` : null
  return function (dispatch) {
    getContacts({
      query,
      limit,
      offset,
      sort
    })(dispatch)
  }
}

/**
 * Sort order
 */
export function setOrder (orderBy, order) {
  return {
    type: CONTACT_SET_ORDER_ACTION,
    orderBy,
    order
  }
}

/**
 * Set limit for pagination
 */
export function setLimit (limit) {
  return {
    type: CONTACT_SET_LIMIT_ACTION,
    limit
  }
}

/**
 * Set offset for pagination
 */
export function setOffset (offset) {
  return {
    type: CONTACT_SET_OFFSET_ACTION,
    offset
  }
}

/**
 * Set search
 */
export function setSearch (search) {
  return {
    type: CONTACT_SET_SEARCH_ACTION,
    search
  }
}

/**
 * Reset search
 */
export function resetSearch () {
  return {
    type: CONTACT_RESET_SEARCH_ACTION
  }
}

/**
 * Set filter queries
 */
export function setFilterQueries (filterQueries) {
  storage.putPageSetting('contact', { filterQueries })
  return {
    type: CONTACT_SET_FILTER_QUERIES_ACTION,
    filterQueries
  }
}
