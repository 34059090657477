import React, { useEffect } from 'react'
import useRequest from '../hooks/useRequest'
import { api } from '../redux'
import useDebounce from '../hooks/useDebounce'
import { Select } from './Input'

const DepartmentDropdown = ({ label, setDepartment, defaultValue, disabled = false, params = { sort: 'name.asc', limit: 50 } }) => {
  const [request, data, status] = useRequest(api.getDepartments, 'department')

  const [debouncedRequest] = useDebounce(request, 300)

  useEffect(() => {
    request(params)
  }, [request])

  return (
    <Select
      name='collection_id'
      label={label}
      disabled={disabled}
      defaultValue={defaultValue}
      request={(input) => {
        debouncedRequest({ query: [{ key: 'name', op: '~', value: input }], sort: 'name.asc', limit: 50 })
      }}
      requestStatus={status === 'pending'}
      items={data}
      handleChange={(_, id, item) => {
        setDepartment(item)
      }}
    />
  )
}

export default DepartmentDropdown
