import React, { Fragment } from 'react'
import { DropdownSelectSearchable, Input as PergasInput, InputStyle, Textarea as PergasTextarea, styled } from '@pergas-common/pergas-components'
import { css } from 'styled-components'

const Holder = styled.div`
  max-width: 320px;
  width: 100%;
  margin: 8px 0;
`

const SelectHolder = styled(Holder)`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  > span {
    margin-left: 8px;
    ${({ theme }) => theme.typography.breadcrumb}
    color: #3a4a54;
  }
`

const GroupHolder = styled.div`
  display: flex;
  justify-content: column;
  width: ${({ fullWidth }) => fullWidth ? '100%' : 'auto'};
  > ${SelectHolder} {
    flex: 1;
    max-width: 40%;
  }
  > ${SelectHolder}:nth-child(1) {
    max-width: 60%;
  }
  > div {
    flex: 1;
    max-width: 200px;
  }
  > div:nth-child(1) {
    max-width: 110px;
  }
  > div:nth-child(2) {
    margin-left: 8px;
  }
`

const StyledRadio = styled(InputStyle)`
  width: auto;
  max-height: 16px;
  appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: 0;

  &[type="radio"]:checked {
    border: 1px solid ${({ theme }) => theme.palette.link.primary};
    background: ${({ theme }) => theme.palette.link.primary}
  }
`

const StyledCheckbox = styled(InputStyle)`
  width: auto;
  max-height: 16px;
  appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: 0;

  &[type="checkbox"]:checked {
    border: 1px solid ${({ theme }) => theme.palette.link.primary};
    background: ${({ theme }) => theme.palette.link.primary}
  }
`

export const InputGroup = ({ children, fullWidth }) => {
  const inputs = children.map((child, i) => <Fragment key={i}>{child}</Fragment>)
  return (
    <GroupHolder fullWidth={fullWidth}>
      {inputs}
    </GroupHolder>
  )
}

export const Radio = (props) => {
  return (
    <StyledRadio {...props} type='radio' />
  )
}

export const Input = ({ name, type, defaultValue, disabled = false, value, label, handleChange = () => {}, children }) => {
  return (
    <Holder>
      <PergasInput
        type={type}
        name={name}
        defaultValue={defaultValue}
        label={label}
        disabled={disabled}
        value={value}
        onChangeValue={handleChange}
      >
        {children}
      </PergasInput>
    </Holder>
  )
}

export const Textarea = ({ name, defaultValue, disabled = false, value, label, handleChange = () => {}, children }) => {
  return (
    <Holder>
      <PergasTextarea
        name={name}
        defaultValue={defaultValue}
        label={label}
        disabled={disabled}
        value={value}
        onChangeValue={handleChange}
      />
    </Holder>
  )
}

export const Checkbox = (props) => {
  return <StyledCheckbox {...props} type='checkbox' />
}

export function itemsWithEmail (items) {
  if (!Array.isArray(items)) {
    return []
  }
  return items?.map(({ name, id, address }) => {
    return { name, id, email: address?.email }
  })
}

export const Select = ({ name, ref, disabled, items = [], defaultValue, label, request = () => {}, cb, requestStatus, handleChange = () => {}, children }) => {
  return (
    <SelectHolder>
      <div style={{ background: '#FFFFFF' }}>
        <DropdownSelectSearchable
          ref={ref}
          name={name}
          defaultSelectedItem={defaultValue}
          disabled={disabled}
          isFetching={requestStatus}
          icon={children}
          items={items == null ? [] : items}
          handleSelectedItemChange={({ selectedItem, inputValue, ...rest }) => {
            if (selectedItem?.name !== inputValue) {
              request(inputValue)
            }
            if (selectedItem) {
              handleChange(name, selectedItem.id, selectedItem)
            }
            if (cb) {
              const input = { selectedItem, inputValue, ...rest }
              cb(input)
            }
          }}
        />
      </div>
      {label && <span>{label}</span>}
    </SelectHolder>
  )
}

const SwitchHolder = styled.span`
  display: inline-flex;
  align-items: center;
`

const SwitchLabel = styled.label`
  cursor: pointer;
  display: block;
  width: 16px;
  height: 4px;
  border-radius: 99999999px;
  position: relative;
  border: 2px solid #98cfe3;
  box-shadow: 0 1px 2px 0 rgb(0, 0, 0, 0.4);
  background: none;
  ::after {
    content: "";
    position: absolute;
    top: -4px;
    left: 0;
    width: 12px;
    height: 12px;
    background: #447fb1;
    border-radius: 9999999px;
    transition: 0.2s;
    box-shadow: 0 3px 6px 0 rgb(0, 0, 0, 0.4);
  }
  ${({ checked }) =>
    checked &&
    css`
      background: #00ff00;
      transition: 0.2s;
      transition-timing-function: ease;
      ::after {
        left: calc(100% + 2px);
        transform: translateX(-100%);
      }
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
    ::active:after {
    width: 130px;
  }
`

export const SwitchInputStyle = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
  margin: 0;
`

export const Switch = ({ value, onChangeValue, disabled }) => {
  return (
    <SwitchHolder onClick={onChangeValue}>
      <SwitchInputStyle
        type='checkbox'
        disabled={disabled}
        checked={value}
        readOnly
        onSubmit={(e) => {
          e.preventDefault()
        }}
      />
      <SwitchLabel checked={value} disabled={disabled} />

    </SwitchHolder>
  )
}
export default Input
