import React, { useEffect } from 'react'
import useRequest from '../hooks/useRequest'
import { api } from '../redux'
import useDebounce from '../hooks/useDebounce'
import { Select } from './Input'

const ResponsibleDropdown = ({ label, setResponsible, defaultValue, disabled = false, params = { sort: 'name.asc', limit: 50 } }) => {
  const [request, data, status] = useRequest(api.getEmployees, 'employee')
  const [getRoles, roles] = useRequest(api.getRoles, 'roles')

  const [debouncedRequest] = useDebounce(request, 300)
  useEffect(() => {
    request(params)
  }, [request])

  useEffect(() => {
    getRoles({ query: [{ key: 'name', op: '=', value: 'Ansvarig' }] })
  }, [])

  return (
    <Select
      name='responsible_person_role'
      label={label}
      disabled={disabled}
      defaultValue={defaultValue}
      request={(input) => {
        debouncedRequest({ query: [{ key: 'name', op: '~', value: input }], sort: 'name.asc', limit: 50 })
      }}
      requestStatus={status === 'pending'}
      items={data}
      handleChange={(_, id, item) => {
        if (Array.isArray(roles)) {
          setResponsible({
            person_id: item.id,
            person_name: item.name,
            role_id: roles[0].id,
            role_internal_name: roles[0].role_internal_name
          })
        }
      }}
    />
  )
}

export default ResponsibleDropdown
