const baseUrl = 'https://graph.microsoft.com/v1.0'

const HEADERS = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json'
}

export async function createTeamGroup (groupId, token) {
  const defaultTeam = {
    memberSettings: {
      allowCreatePrivateChannels: true,
      allowCreateUpdateChannels: true
    },
    messagingSettings: {
      allowUserEditMessages: true,
      allowUserDeleteMessages: true
    },
    funSettings: {
      allowGiphy: true,
      giphyContentRating: 'strict'
    }
  }
  try {
    const response = await fetch(`${baseUrl}/groups/${groupId}/team`, {
      method: 'PUT',
      headers: {
        ...HEADERS,
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(defaultTeam)
    })
    if (!response.ok) {
      const { status } = response
      throw new Error(`Request parsing failed: ${status}`)
    }
    return await response.json()
  } catch (e) {
    throw new Error(e.message)
  }
}

export async function getTeamById (groupId, token) {
  try {
    const response = await fetch(`${baseUrl}/teams/${groupId}`, {
      headers: {
        ...HEADERS,
        Authorization: `Bearer ${token}`
      }
    })

    if (!response.ok) {
      const { status } = response
      throw new Error(`Request failed: ${status}`)
    }
    return await response.json()
  } catch (e) {
    throw new Error(e.message)
  }
}
