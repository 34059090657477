import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectLocale } from '../redux/locale/selectors'
import Tooltip from './Tooltip'
import useUrlQuery from '../hooks/useUrlQuery'
import { useNavigate } from 'react-router-dom'
import { Card, Waves, styled, List } from '@pergas-common/pergas-components'
import { DeleteItemV2 } from './dialogs/DeleteItem'
import SearchInput from './SearchField'
import useDebounce from '../hooks/useDebounce'
import { makeSelectorRoleFiltered } from '../redux/role/selectors'
import { useGetRoleQuery, useDeleteRoleMutation } from '../redux/role/hooks'

const Holder = styled.div`
  max-width: 300px;
`
const SearchHolder = styled.div`
  padding: 0 8px;
`
const Overflow = styled.div`
  overflow-y: hidden;
  height: 500px;
`

const ListItem = styled(List.Item)`
    padding: 4px;
    justify-content: space-between;

`

const RolesList = () => {
  const query = useUrlQuery()
  const id = Number(query.get('id'))
  const navigate = useNavigate()
  const selectByRole = useMemo(makeSelectorRoleFiltered)
  const locale = useSelector(selectLocale)
  const [searchQuery, setSearchQuery] = useState('')
  const [remove, setRemove] = useState(null)
  const data = useSelector((state) => selectByRole(state, searchQuery))
  const { isLoading, refetch } = useGetRoleQuery({ sort: 'name.asc' })

  const { mutation: deleteMutation } = useDeleteRoleMutation()
  const [debouncedRequest] = useDebounce(refetch, 300)

  return (
    <>
      <Holder>
        <SearchHolder>
          <SearchInput
            value={searchQuery}
            onChange={(value) => {
              debouncedRequest({ query: [{ key: 'name', op: '~', value }], sort: 'name.asc' })
              setSearchQuery(value)
            }}
            resetSearch={(v) => {
              if (v === null) {
                refetch({ sort: 'name.asc' })
                setSearchQuery('')
              }
            }}
          />
        </SearchHolder>
        <Card.Content>
          <Card.Header title={locale.roles} columns='1fr' />
          <Overflow>
            <List.Content>
              <List.Items>
                {isLoading && <ListItem><Waves width={18} height={18} /></ListItem>}
                {data?.map((role) => (
                  <ListItem selected={role.id === id} key={role.name} onClick={() => navigate(`/roles/edit?id=${role.id}`)}>
                    <Tooltip side='bottom' content={role.description}><span>{role.name}</span></Tooltip>
                  </ListItem>
                ))}
              </List.Items>
            </List.Content>
          </Overflow>
        </Card.Content>
      </Holder>
      {remove && <DeleteItemV2
        text={remove.name}
        isOpen={!!remove}
        onCloseRequest={() => {
          setRemove(false)
        }}
        onSubmit={() => {
          setRemove(null)
          deleteMutation({ id: remove.id })
        }}
                 />}

    </>
  )
}

export default RolesList
