import React from 'react'
import Tooltip from './Tooltip'
import { Tag as PergasTag, styled } from '@pergas-common/pergas-components'
import { css } from 'styled-components'

const Elipsis = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.palette.common.primary};
`

const Holder = styled.div`
  ${({ isSelected }) => isSelected && css`
    background: #dee4ea;
  `}
`

const TypeItems = ({ types, disabled, selected, onSelect }) => {
  const typeList = types.map(({ id, color, name, ...rest }) => {
    const isSelected = Number(selected) === id
    return (
      <Holder
        key={id} isSelected={isSelected} onClick={(e) => {
          e.stopPropagation()
          onSelect && onSelect({ id, color, name, ...rest })
        }}
      >
        {rest.description
          ? (
            <Tooltip side='right' content={rest.description}><PergasTag color={color}><Elipsis>{name}</Elipsis></PergasTag></Tooltip>
            )
          : (
            <PergasTag color={color}><Elipsis>{name}</Elipsis></PergasTag>
            )}
      </Holder>
    )
  })

  return <>{typeList}</>
}

export default TypeItems
