import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getPageItems, receivedObjectTypes } from './actions'
import { makeSelectorById, makeSelectorObjectType } from './selectors'
import { makeSelectMetadataByRequestId, selectStatusByRequestId } from '../busy/selectors'
import { busy, notBusy } from '../busy/reducer'
import api from '../api'
import { handleAddErrors, handleDeleteErrors, handleGetErrors, handleUpdateErrors } from '../error/actions'
import { useRequestId } from '../../hooks/useRequestId'

export function useGetObjectTypesByIdQuery (id) {
  const reqId = useRequestId([id])
  const dispatch = useDispatch()
  const [refetching, setRefetching] = useState(false)
  const selectEmployeeById = useMemo(makeSelectorById, [])

  const status = useSelector((state) => selectStatusByRequestId(state, reqId))
  const data = useSelector((state) => selectEmployeeById(state, id))

  const effects = useCallback((requestId, params) => {
    dispatch(busy({ requestId }))
    api.getObjectTypes(params).then(result => {
      dispatch(receivedObjectTypes(result.data.value))
      dispatch(notBusy({ requestId }))
    }).catch(handleGetErrors('objectType', dispatch, requestId))
  }, [dispatch])

  useEffect(() => {
    if ((status === undefined || refetching) && id) {
      effects(reqId, { query: [{ key: 'id', op: '=', value: id }] })
      setRefetching(false)
    }
  }, [effects, id, reqId, status, refetching, setRefetching])

  const refetch = useCallback(() => {
    setRefetching(true)
  }, [setRefetching])

  const isLoading = status === 'pending' || status === undefined
  const isError = status === 'rejected'
  const isSuccessful = status === 'resolved'
  const isUnitialized = status === undefined
  return { isUnitialized, isError, isSuccessful, data, refetch, isLoading }
}

export function useGetObjectTypesQuery (params = { limit: 50 }) {
  const reqId = useRequestId()
  const dispatch = useDispatch()
  const selectEmployees = useMemo(makeSelectorObjectType, [])

  const status = useSelector((state) => selectStatusByRequestId(state, reqId))
  const data = useSelector(selectEmployees)

  const effects = useCallback((requestId, params) => {
    dispatch(busy({ requestId }))
    api.getObjectTypes(params).then(result => {
      dispatch(receivedObjectTypes(result.data.value))
      dispatch(notBusy({ requestId }))
    }).catch(handleGetErrors('objectType', dispatch, requestId))
  }, [dispatch])

  useEffect(() => {
    if (status === undefined) {
      effects(reqId, params)
    }
  }, [effects, params, reqId, status])

  const refetch = useCallback((params) => {
    effects(reqId, params)
  }, [reqId])

  const isLoading = status === 'pending' || status === undefined
  const isError = status === 'rejected'
  const isSuccessful = status === 'resolved'
  const isUnitialized = status === undefined
  return { isUnitialized, isError, isSuccessful, data, refetch, isLoading }
}

export function useAddObjectTypeMutation () {
  const requestId = useRequestId()
  const dispatch = useDispatch()
  const selectMetadataByRequestId = useMemo(makeSelectMetadataByRequestId, [])

  const metadata = useSelector((state) => selectMetadataByRequestId(state, requestId))
  const status = useSelector((state) => selectStatusByRequestId(state, requestId))

  const mutation = useCallback((objectType) => {
    dispatch(busy({ requestId }))
    api.addObjectType(objectType).then(result => {
      dispatch(notBusy({ requestId, id: result.data.value?.id }))
    }).catch(handleAddErrors('objectType', dispatch))
  }, [dispatch, requestId])

  const isUnitialized = status === 'pending' || status === undefined
  const isLoading = status === 'pending'
  const isSuccessful = status === 'resolved'
  const isError = status === 'rejected'

  return { metadata, mutation, isLoading, isError, isUnitialized, isSuccessful }
}

export function useUpdateObjectTypeMutation () {
  const requestId = useRequestId()
  const dispatch = useDispatch()

  const selectMetadataByRequestId = useMemo(makeSelectMetadataByRequestId, [])
  const metadata = useSelector((state) => selectMetadataByRequestId(state, requestId))
  const status = useSelector((state) => selectStatusByRequestId(state, requestId))

  const mutation = useCallback((objectType) => {
    dispatch(busy({ requestId }))
    api.updateObjectType(objectType).then(result => {
      dispatch(notBusy({ requestId, id: result.data.value?.id }))
    }).catch(handleUpdateErrors('objectType', dispatch, requestId))
  }, [dispatch, requestId])

  const isUnitialized = status === undefined
  const isLoading = status === 'pending'
  const isSuccessful = status === 'resolved'
  const isError = status === 'rejected'

  return { metadata, mutation, isLoading, isError, isUnitialized, isSuccessful }
}

export function useDeleteObjectTypeMutation () {
  const requestId = useRequestId()
  const dispatch = useDispatch()

  const selectMetadataByRequestId = useMemo(makeSelectMetadataByRequestId, [])
  const metadata = useSelector((state) => selectMetadataByRequestId(state, requestId))
  const status = useSelector((state) => selectStatusByRequestId(state, requestId))

  const mutation = useCallback((objectType) => {
    dispatch(busy({ id: objectType.id, requestId }))
    api.deleteObjectType(objectType).then(() => {
      dispatch(notBusy({ requestId }))
      dispatch(getPageItems())
    }).catch(handleDeleteErrors('objectType', dispatch, requestId))
  }, [dispatch, requestId])

  const isUnitialized = status === undefined
  const isLoading = status === 'pending'
  const isSuccessful = status === 'resolved'
  const isError = status === 'rejected'

  return { metadata, mutation, isLoading, isError, isUnitialized, isSuccessful }
}
