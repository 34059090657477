import React, { useMemo } from 'react'
import { styled, media, spacing, List } from '@pergas-common/pergas-components'

import { Link, useLocation } from 'react-router-dom'
import {
  AccountIcon,
  CategoryIcon,
  ContactIcon,
  ContactPersonIcon,
  ContentTypeIcon,
  DepartmentIcon,
  EmployeeIcon,
  ObjectTypeIcon,
  ProjectIcon,
  RoleIcon,
  SettingsIcon,
  TagIcon,
  TicketIcon
} from './icons'
import { useSelector } from 'react-redux'
import { selectLocale } from '../redux/locale/selectors'
import { makeSelectorPermissions } from '../redux/permission/selectors.js'
import { makeSelectorBySharepointSaveEnabled } from '../redux/login/selectors.js'

const Section = styled.div`
  display: flex;
  width: 100%;
  height: 16px;
  background: ${({ theme }) => theme.palette.brand.secondary};
  padding: ${spacing(1)} ${spacing(1)};

  > h1 {
    display: none;
    color: ${({ theme }) => theme.palette.common.primary};
    ${({ theme }) => theme.typography.breadcrumb};
    font-weight: bold;
    margin: 0;
    line-height: 16px;
    ${media.medium`
      display: inline-block;
    `}
  }
`

const Route = styled(Link)`
  padding: ${spacing(1)};
  width: 100%;
  height: 100%;
  ${({ theme }) => theme.typography.body};
  text-decoration: none;
  > span {
    display: none;
    ${media.medium`
      display: initial;
    `}
  }
  > svg {
    vertical-align: middle;
    width: 20px;
    height: 20px;
  }
`

const Holder = styled.div`
  ${List.Content} {
    overflow-x: hidden;
  }
`

const ListItemWrapper = styled.div`
  display: none;
  ${media.medium`
    display: block;
  `}
`

const routes = {
  default: '/',
  contact: '/contacts',
  contactPersons: '/contact-persons',
  employees: '/employees',
  projects: '/projects',
  tickets: '/tickets',
  departments: '/departments',
  categories: '/categories',
  objectTypes: '/object-types',
  contentTypes: '/content-types',
  tags: '/tags',
  roles: '/roles',
  accounts: '/accounts',
  settings: '/settings'
}

const SideBarMenu = () => {
  const { pathname } = useLocation()
  const selectPermissions = useMemo(makeSelectorPermissions, [])
  const selectSharepointSaveEnabled = useMemo(makeSelectorBySharepointSaveEnabled, [])
  const locale = useSelector(selectLocale)
  const { account, isAdmin, contact, contactPerson, project, ticket, objectType, employee, role, tag, category, department } = useSelector(selectPermissions)
  const sharePointSaveEnabled = useSelector(selectSharepointSaveEnabled)
  const readContentTypes = isAdmin && sharePointSaveEnabled

  return (
    <Holder>
      <List.Content>
        <List.Items>
          <Section><h1>{locale.contacts}</h1></Section>
          {contact.canRead && (
            <List.Item selected={pathname.includes(routes.contact) || pathname === '/'}>
              <Route to={routes.contact}><ContactIcon /> <span>{locale.contacts}</span></Route>
            </List.Item>)}
          {contactPerson.canRead && (
            <List.Item selected={pathname.includes(routes.contactPersons)}>
              <Route to={routes.contactPersons}><ContactPersonIcon /> <span>{locale.contact_persons}</span></Route>
            </List.Item>
          )}
          {employee.canRead && (
            <List.Item selected={pathname.includes(routes.employees)}>
              <Route to={routes.employees}><EmployeeIcon /> <span>{locale.employees}</span></Route>
            </List.Item>
          )}
          <Section><h1>{locale.activities}</h1></Section>
          {project.canRead && (
            <List.Item selected={pathname.includes(routes.projects)}>
              <Route to={routes.projects}><ProjectIcon /> <span>{locale.projects}</span></Route>
            </List.Item>
          )}
          {ticket.canRead && (
            <List.Item selected={pathname.includes(routes.tickets)}>
              <Route to={routes.tickets}><TicketIcon /> <span>{locale.tasks}</span></Route>
            </List.Item>
          )}
          {sharePointSaveEnabled && (
            <>
              <Section><h1>{locale.intranet}</h1></Section>
              {department.canRead && (
                <List.Item selected={pathname.includes(routes.departments)}>
                  <Route to={routes.departments}><DepartmentIcon /> <span>{locale.departments}</span></Route>
                </List.Item>
              )}
              {category.canRead && (
                <List.Item selected={pathname.includes(routes.categories)}>
                  <Route to={routes.categories}><CategoryIcon /> <span>{locale.categories}</span></Route>
                </List.Item>
              )}
            </>
          )}
          <ListItemWrapper>
            <Section><h1>{locale.settings}</h1></Section>
            {objectType.canRead && (
              <List.Item selected={pathname.includes(routes.objectTypes)}>
                <Route to={routes.objectTypes}><ObjectTypeIcon /> <span>{locale.object_types}</span></Route>
              </List.Item>
            )}
            {readContentTypes && (
              <List.Item selected={pathname.includes(routes.contentTypes)}>
                <Route to={routes.contentTypes}><ContentTypeIcon /> <span>{locale.content_types}</span></Route>
              </List.Item>)}
            {tag.canRead && (
              <List.Item selected={pathname.includes(routes.tags)}>
                <Route to={routes.tags}><TagIcon /> <span>{locale.tags}</span></Route>
              </List.Item>
            )}
            {role.canRead && (
              <List.Item selected={pathname.includes(routes.roles)}>
                <Route to={routes.roles}><RoleIcon /> <span>{locale.roles}</span></Route>
              </List.Item>
            )}
            {account && (
              <List.Item selected={pathname.includes(routes.accounts)}>
                <Route to={routes.accounts}><AccountIcon />  <span>{locale.accounts}</span></Route>
              </List.Item>
            )}
            <List.Item selected={pathname.includes(routes.settings)}>
              <Route to={routes.settings}> <SettingsIcon /> <span>{locale.settings}</span></Route>
            </List.Item>
          </ListItemWrapper>
        </List.Items>
      </List.Content>
    </Holder>
  )
}

export default SideBarMenu
