import React from 'react'
import { Topbar, spacing, styled } from '@pergas-common/pergas-components'
import BackArrow from './BackArrow'

const Holder = styled.div`
  height: 50px;
`

const Margin = styled.div`
  margin: 0 ${spacing(0.5)};
`
export default ({ children, icon, link, title }) => {
  return (
    <Holder>
      <Topbar
        left={
          <>
            <BackArrow />
            <Margin>{icon && icon}</Margin>
            <Margin><b>{title && title}</b></Margin>
            <Margin>{link && link}</Margin>
          </>
      }
      />
      {children}
    </Holder>
  )
}
