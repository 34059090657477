import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Save } from '@mui/icons-material'
import { Button, Card, Group, Layout, Link, Waves, media, styled } from '@pergas-common/pergas-components'
import { Add, Favorite, FavoriteFilled, Folder, Remove, Sharepoint } from '@pergas-common/pergas-icons'
import Input from '../../Input.js'
import Page from '../Page.js'
import { useAddDepartmentMutation, useDeleteDepartmentMutation, useFavoriteDepartmentMutation, useGetDepartmentByIdQuery, useUpdateDepartmentMutation } from '../../../redux/department/hooks'
import { selectLocale } from '../../../redux/locale/selectors.js'
import useUrlQuery from '../../../hooks/useUrlQuery.js'
import { DeleteItemV2 } from '../../dialogs/DeleteItem'
import { DepartmentIcon } from '../../icons.js'
import ObjectTypeDropdown from '../../ObjectTypeDropdown.js'
import CategoriesGrid from '../../CategoriesGrid.js'
import { PersonRoleGrid } from '../../PersonRoleGrid.js'
import { AddPersonRoleV2 } from '../../dialogs/AddPersonRole.js'
import ResponsibleDropdown from '../../ResponsibleDropdown.js'
import { dedupeArrays, responsiblePerson, withoutResponsiblePerson } from '../../../util.js'
import TagListCard from '../TagListCard.js'
import { makeSelectorByIsFavorite, makeSelectorBySharepointStatus, makeSelectorBySharepointUrl } from '../../../redux/department/selectors.js'
import AsyncLoader from '../../AsyncLoader.js'
import TeamsButton from '../../TeamsButton.js'

const Overflow = styled.div`
  overflow-y: hidden;
  height: 218px;
`

const ButtonHolder = styled.div`
  display: flex;
  max-width: 850px;
  align-items: stretch;
  flex-direction: column;
  > div:nth-child(1) {
    width: 100%;
    max-width: 320px;
    flex: 0 0 32px;  
    ${media.medium`
      flex: 1;
      max-width: none;
    `}
  }
  > div:nth-child(2) {
    flex: 0 0 32px;
    width: 100%;
    max-width: 320px;
    justify-content: flex-end;
  }
  ${media.medium`
    flex-direction: row;
    padding: 8px 16px;

  `} 
`

const Form = ({ id, onSubmit, data, personRoles }) => {
  const locale = useSelector(selectLocale)
  const ref = useRef(null)
  const { state } = useLocation()
  const defaultObjectType = {
    id: data?.object_type_id || state?.object_type_id || 0,
    name: data?.object_type_name || state?.object_type_name || ''
  }

  let defaultTags = []
  if (Array.isArray(data?.tags)) {
    defaultTags = [...data.tags]
  }
  if (Array.isArray(state?.tags)) {
    defaultTags = [...defaultTags, ...state.tags]
  }

  const responsibleRole = responsiblePerson(data?.person_role)
  const defaultResponsible = responsibleRole && { ...responsibleRole, name: responsibleRole.person_name, id: responsibleRole.person_id }

  const [responsible, setResponsible] = useState(defaultResponsible)
  const [objectType, setObjectType] = useState(defaultObjectType)
  const [tags, setTags] = useState(defaultTags)

  const submit = (e) => {
    e.preventDefault()
    const payload = {}
    const formData = new FormData(ref.current)
    for (const [key, value] of formData.entries()) {
      if (id) {
        payload.id = Number(id)
      }
      switch (key) {
        case 'object_type_id':
          payload[key] = objectType?.id || null
          break
        case 'responsible_person_role': {
          const withoutResponsible = withoutResponsiblePerson(personRoles)
          if (responsible?.person_id || responsible?.id) {
            payload.person_role = [...withoutResponsible, responsible]
          } else if (Object.values(withoutResponsible).length > 0) {
            payload.person_role = withoutResponsible
          }
          break
        }
        default:
          payload[key] = value
          break
      }
      payload.tags = [...tags.map((tag) => {
        return {
          color: tag.color,
          name: tag.name,
          tag_id: tag.tag_id ?? tag.id,
          tag_type_name: tag.tag_type_name
        }
      })]
    }
    onSubmit(payload)
  }

  return (
    <form onSubmit={submit} id='department' ref={ref}>
      <Input name='name' label={locale.name} defaultValue={data?.name} />
      <ObjectTypeDropdown type='department' setObjectType={setObjectType} label={locale.department_object_type} defaultValue={defaultObjectType} />
      <ResponsibleDropdown label={locale.responsible} setResponsible={setResponsible} defaultValue={defaultResponsible} />
      <TagListCard tags={tags} tagType='department' onChangeHandler={setTags} />
    </form>
  )
}

const DepartmentDetails = () => {
  const [remove, setRemove] = useState(false)
  const [personRoles, setPersonRoles] = useState([])
  const [addPersonRole, setAddPersonRole] = useState(false)
  const navigate = useNavigate()
  const query = useUrlQuery()
  const id = query.get('id')
  const locale = useSelector(selectLocale)

  const selectorBySharepointStatus = useMemo(makeSelectorBySharepointStatus, [])
  const selectorBySharepointUrl = useMemo(makeSelectorBySharepointUrl, [])
  const selectByFavorite = useMemo(makeSelectorByIsFavorite, [])

  const sharepointStatus = useSelector((state) => selectorBySharepointStatus(state, id))
  const sharepointUrl = useSelector((state) => selectorBySharepointUrl(state, id))
  const isFavorite = useSelector((state) => selectByFavorite(state, id))

  const disableSharepoint = sharepointStatus !== 'finished'
  const updatingSharepoint = sharepointStatus === 'creating' || sharepointStatus === 'updating'

  const { data, isUnitialized, isLoading, isSuccessful } = useGetDepartmentByIdQuery(id)
  const { mutation: updateMutation, isLoading: isUpdating } = useUpdateDepartmentMutation()
  const { metadata, mutation: createMutation, isLoading: isCreating, isSuccessful: isCreated } = useAddDepartmentMutation()
  const { mutation: deleteMutation, isLoading: isDeleting, isSuccessful: isDeleted } = useDeleteDepartmentMutation()
  const { mutation: favoriteMutation, isLoading: isFavoriteLoading } = useFavoriteDepartmentMutation()

  const pageMetadata = {
    title: locale.department,
    icon: <DepartmentIcon width={20} height={20} />,
    link: (
      <Link onClickHandler={() => {
        navigate('/departments/new', {
          replace: true,
          state: {
            object_type_id: data?.object_type_id,
            object_type_name: data?.object_type_name,
            tags: data?.tags
          }
        })
      }}
      ><Add width={20} height={20} color='#28afe0' />
      </Link>
    )
  }

  const submit = (payload) => {
    if (id) {
      updateMutation(payload)
      return
    }
    createMutation(payload)
  }

  useEffect(() => {
    if (Array.isArray(data?.person_role)) {
      setPersonRoles((prev) => dedupeArrays(prev, data?.person_role))
    }
  }, [data?.person_role])

  useEffect(() => {
    if (metadata?.id) {
      navigate(`/departments/edit?id=${metadata?.id}`, { replace: true })
    }
  }, [metadata, isCreated])

  useEffect(() => {
    if (isDeleted) {
      navigate('/departments', { replace: true })
    }
  }, [isDeleted])

  return (
    <Page metadata={pageMetadata}>
      <Layout.Aside>
        {(isSuccessful || (isUnitialized && !id)) && (
          <Form personRoles={personRoles} id={id} data={data} onSubmit={submit} key={id} />
        )}
        {(isLoading && !isUnitialized) && (
          <Waves width={48} height={48} />
        )}
      </Layout.Aside>
      <Layout.Section>
        <ButtonHolder>
          <div>
            <Group.Button>
              <Button
                type='button' disabled={!id} onClick={() => {
                  setRemove(true)
                }}
              >
                {isDeleting ? <Waves width={20} height={20} /> : <Remove width={20} height={20} color='#FF5656' />}<span>{locale.delete}</span>
              </Button>
              <Button type='submit' form='department'>{(isUpdating || isCreating) ? <Waves width={20} height={20} /> : <Save style={{ color: '#34b3e1', width: 20, height: 20 }} />}<span>{locale.save}</span></Button>
            </Group.Button>
            <Group.Button>
              <Button
                type='button' disabled={disableSharepoint} onClick={() => {
                  window.open(sharepointUrl)
                }}
              >
                <span>{locale.startpage}</span>
                <AsyncLoader loading={updatingSharepoint}><Sharepoint width={18} height={18} /></AsyncLoader>
              </Button>
              <Button type='button' disabled={disableSharepoint} onClick={() => { window.open(`${sharepointUrl}/files/Forms/AllItems.aspx`) }}>
                <span>{locale.documents}</span>
                <AsyncLoader loading={updatingSharepoint}><Folder width={18} height={18} /></AsyncLoader>
              </Button>
              {!disableSharepoint && (
                <TeamsButton groupId={data?.group_id} />
              )}
            </Group.Button>
          </div>
          <div>
            <Group.Button>
              <Button type='button' disabled={isFavoriteLoading || !id} onClick={(e) => { favoriteMutation({ id, is_favorite: isFavorite }) }}>
                <span>{locale.favorite}</span>
                <AsyncLoader loading={isFavoriteLoading}>{isFavorite ? <FavoriteFilled width={18} height={18} color='#34b3e1' /> : <Favorite width={18} height={18} color='#34b3e1' />}</AsyncLoader>
              </Button>
            </Group.Button>
          </div>
        </ButtonHolder>
        <Card.Content>
          <Card.Header>{locale.categories}</Card.Header>
          <Overflow>
            <CategoriesGrid departmentId={id} />
          </Overflow>
        </Card.Content>
        <Card.Content>
          <Card.Header title={locale.contact_persons} columns='2fr 2fr 1fr 2fr' extra={['']}>
            <Link onClickHandler={() => {
              setAddPersonRole(true)
            }}
            >
              <Add color='#28afe0' width={20} height={20} />
            </Link>
          </Card.Header>
          <Overflow>
            <PersonRoleGrid values={personRoles} onChangeHandler={setPersonRoles}>
              {addPersonRole && (
                <AddPersonRoleV2
                  values={personRoles}
                  onChangeHandler={setPersonRoles}
                  isOpen={addPersonRole}
                  onCloseRequest={() => {
                    setAddPersonRole(false)
                  }}
                />
              )}
            </PersonRoleGrid>
          </Overflow>
        </Card.Content>
      </Layout.Section>
      {remove && <DeleteItemV2
        text={`${data?.first_name} ${data?.last_name}`}
        isOpen={remove}
        onCloseRequest={() => {
          setRemove(false)
        }}
        onSubmit={() => {
          deleteMutation({ id })
          setRemove(false)
        }}
                 />}

    </Page>
  )
}

export default DepartmentDetails
