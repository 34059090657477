import React from 'react'
import { Tag, Link, styled } from '@pergas-common/pergas-components'
import { Remove } from '@pergas-common/pergas-icons'

const Holder = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
    background: none;
  }
  &::-webkit-scrollbar-thumb {
    width: 15px;
    background: ${({ theme }) => theme.palette.brand.secondary};
  }

  > div {
    > button {
      vertical-align: bottom;
      margin-left: 4px;
    }
  }
  > div > span {
    margin-top: 8px;
    margin-right: 8px;
  }
`

const Ellipsis = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
`

const Tags = ({ tags, disabled, handleChange }) => {
  const renderedTags = tags.map((tag) => {
    const { id, color, name } = tag
    return (
      <div key={id}>
        <Tag style={{ width: 175 }} color={color}><Ellipsis>{name}</Ellipsis></Tag>
        <Link
          onClickHandler={() => {
            handleChange(id, tag)
          }}
        >
          {!disabled && <Remove color='#b13a22' width={20} height={20} />}
        </Link>
      </div>
    )
  })

  return renderedTags
}

export const TagList = ({ name, disabled, tags, handleChange }) => {
  if (tags == null) {
    return <Holder />
  }
  return (
    <Holder>
      <Tags
        disabled={disabled}
        tags={tags} handleChange={(id) => {
          const newTags = tags.filter((t) => t.id !== id)
          handleChange(name, newTags)
        }}
      />
    </Holder>
  )
}
