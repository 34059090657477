import React from 'react'
import { TOOLBAR_BG_COLOR } from '../style.js'
import { styled } from '../../pergas-components/index.js'

const Holder = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  height: 50px;
  width: 100%;
  background: ${TOOLBAR_BG_COLOR};
`

const PageFooter = ({ children }) => {
  return (<Holder>{children}</Holder>)
}

export default PageFooter
