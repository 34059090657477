import React, { useEffect, useCallback, useMemo, useState, useRef } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Link as RLink, useNavigate } from 'react-router-dom'
import PageToolbar from './PageToolbar.js'
import redux from '../../redux/index.js'
import storage from '../../redux/storage.js'
import SearchField from '../SearchField.js'
import DeleteItem from '../dialogs/DeleteItem.js'
import TablePagination from './TablePagination.jsx'
import { Link, LinkStyle, theme, styled, Tag } from '@pergas-common/pergas-components'
import { Table, Td } from '../../pergas-components/index.js'
import { css } from 'styled-components'
import { Add, Contact, Email, Teams, Phone } from '@pergas-common/pergas-icons'
import { DeleteItemIcon } from '../icons.js'
import { sortArray, trimNewLineFromString } from '../../util.js'
import { DEFAULT_OBJECT_TYPE_COLOR } from '../style.js'
import { createDateAccessor } from './columns.js'
import PageFooter from './PageFooter.jsx'
import Filterbar from '../filter/Filterbar'
import FilterObjectType from '../filter/FilterObjectType'
import { makeSelectorFilterQueries } from '../../redux/common.js'
import FilterContact from '../filter/FilterContact.js'
import All from '../filter/FilterAll.js'
import { selectLocale } from '../../redux/locale/selectors.js'
import CsvButton from '../CsvButton.js'
import Tooltip from '../Tooltip.js'

const PageHolder = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 100%;
`

const TableHolder = styled.div`
  display: flex;
  overflow-y: scroll;
  flex-grow: 1;
  flex-basis: 50px;

  ${({ $isExpanded }) => $isExpanded && css`
    top: 220px;
  `};
`

const TagHolder = styled.span`
  margin-right: 8px;
`

const Spacer = styled.div`
  display: inline-block;
  margin-right: 3px;
`

const NameHolder = styled.div`
  display: inline-block;
  overflow: hidden;
  margin: 0 8px;
  > a {
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }
`

const InternalLink = styled(RLink)`
  ${LinkStyle}
  margin-left: 8px;
  vertical-align: bottom;
  > svg {
    vertical-align: inherit;
  }
`

const FilterList = () => {
  const dispatch = useDispatch()
  const selectFilterQueries = useMemo(makeSelectorFilterQueries, [])

  const locale = useSelector(selectLocale)
  const filterQueries = useSelector((state) => selectFilterQueries(state, 'contactPerson'))
  const isAllFilterApplied = filterQueries.filter(({ key }) => key === 'collection_id').length === 0

  const objectTypeFilter = useCallback((id, metadata = {}) => {
    const included = filterQueries.filter(({ key }) => {
      return key !== 'object_type_id'
    })
    if (!id) {
      dispatch(redux.actions.contactPerson.setFilterQueries(included))
    } else {
      dispatch(redux.actions.contactPerson.setFilterQueries([...included, { key: 'object_type_id', value: id, op: '=', ...metadata }]))
    }
  }, [dispatch, filterQueries])

  const contactFilter = useCallback((id, metadata = {}) => {
    const included = filterQueries.filter(({ key }) => key !== 'collection_id')
    if (!id) {
      dispatch(redux.actions.contactPerson.setFilterQueries(included))
    } else {
      dispatch(redux.actions.contactPerson.setFilterQueries([...included, { key: 'collection_id', value: id, op: '=', ...metadata }]))
    }
  }, [dispatch, filterQueries])

  return (
    <Filterbar.Content>
      <Filterbar.Grouped border>
        <legend>{locale.contact}</legend>
        <Filterbar.Column offset={-1}>
          <All applyFilter={contactFilter} checked={isAllFilterApplied} />
        </Filterbar.Column>
        <Filterbar.Row offset={0}><FilterContact applyFilter={contactFilter} rules={filterQueries} /></Filterbar.Row>
      </Filterbar.Grouped>
      <Filterbar.Grouped>
        <Filterbar.Row offset={-1}><FilterObjectType type='contact_person' applyFilter={objectTypeFilter} rules={filterQueries} /></Filterbar.Row>
      </Filterbar.Grouped>
    </Filterbar.Content>
  )
}

const ContactPersonPage = ({
  locale,
  windowWidth,
  rows,
  limit,
  offset,
  orderBy,
  order,
  search,
  resetSearch,
  getPageItems,
  setOrder,
  setLimit,
  setOffset,
  setSearch,
  canUpdate,
  canDelete,
  deleteItem,
  onDeleteItemClick,
  onDeleteOk,
  onDeleteCancel,
  canCreate
}) => {
  const selectFilterQueries = useMemo(makeSelectorFilterQueries, [])
  const filterQueries = useSelector((state) => selectFilterQueries(state, 'contactPerson'))
  const [isMobileScreen, setIsMobileScreen] = useState(windowWidth <= theme.breakpoints.medium)

  useEffect(getPageItems, [limit, offset, orderBy, order, search, filterQueries])
  const navigate = useNavigate()
  const settings = storage.getPageSettings('contact-person')
  const [expanded, setExpanded] = useState(settings.toolbarExpanded || false)

  const setPageSizeRef = useRef(() => {})

  function onSetExpanded (set) {
    storage.putPageSetting('contact-person', { toolbarExpanded: !expanded })
    setExpanded(set)
  }

  const manualSort = useCallback(({ id, isSorted, isSortedDesc }) => {
    if (isSorted && !isSortedDesc) {
      setOrder(id, 'desc')
    } else if (isSorted && isSortedDesc) {
      setOrder('', '')
    } else {
      setOrder(id, 'asc')
    }
  }, [setOrder])

  const columns = useMemo(() => {
    return [
      {
        Header: locale.name,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return (
            <Td
              {...cell.getCellProps()} left={
                <>
                  <Contact width={16} height={16} />
                  <NameHolder>
                    {!!original.name && (canUpdate ? <InternalLink to={`/contact-persons/edit?id=${original.id}`}>{original.name}</InternalLink> : <span>{original.name}</span>)}
                  </NameHolder>
                </>
            }
            />
          )
        },
        canSort: true,
        id: 'name',
        size: isMobileScreen ? undefined : 'md',
        manualSort,
        sortType: () => {}
      },
      {
        Header: locale.contact,
        manualSort,
        accessor: 'collection_name',
        size: 'md',
        sortType: () => {}
      },
      {
        id: 'object_type_name',
        Header: locale.contact_person_object_type,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return <Td {...cell.getCellProps()} left={original.object_type_name && <Tag color={original.object_type_color || DEFAULT_OBJECT_TYPE_COLOR} textColor={original.object_type_color ? '#FFFFFF' : '#3a4a54'} border='#969696'>{original.object_type_name}</Tag>} />
        },
        canSort: true,
        sortType: () => {},
        manualSort
      },
      {
        id: 'phone_numbers',
        Header: locale.phone_number,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          const { address: { phone } } = original
          let primaryPhone = phone
          if (typeof primaryPhone === 'string' && primaryPhone.includes(',')) {
            // TODO: Sanitize this on the backend since free text introduces a lot of edge cases.
            primaryPhone = primaryPhone.split(',')[0].split(' ')
          }
          return <Td {...cell.getCellProps()} left={phone && <Link href={`tel:${primaryPhone}`}>{primaryPhone}</Link>} />
        },
        isSortable: false
      },
      {
        id: 'tags_string',
        Header: locale.tags,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          const tags = original.tags && original.tags.length > 0 && sortArray(original.tags, 'name').slice(0, 2).map(({ color, id, name }) => {
            return <TagHolder key={id}><Tag textColor='#FFFFFF' color={color}>{name}</Tag></TagHolder>
          })
          return (<Td {...cell.getCellProps()} left={tags && tags} />)
        },
        canSort: true,
        sortType: () => {},
        manualSort
      },
      {
        id: 'updated_at',
        Header: locale.updated_at,
        manualSort,
        accessor: createDateAccessor('updated_at'),
        sortType: () => {}
      },
      {
        id: 'toolbar',
        Header: locale.tool_belt,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          const { address } = original
          const { phone, email } = address
          return (
            <Td
              {...cell.getCellProps()}
              left={
                <>
                  {!!email && <Tooltip content={email}><Spacer><Link href={`mailto:${email}`}><Email width={18} height={18} color='#447fb1' /></Link></Spacer></Tooltip>}
                  {phone && <Tooltip content={phone}><Spacer><Link href={`tel:${phone}`}><Phone with={18} height={18} color='#447fb1' /></Link></Spacer></Tooltip>}
                  {!!email && <Tooltip content='Teams'><Spacer><Link href={`https://teams.microsoft.com/l/call/0/0?users=${email}`}><Teams width={18} height={18} /></Link></Spacer></Tooltip>}
                </>
              }
              right={canDelete && <Tooltip side='left' content={locale.delete}><Link onClickHandler={() => { onDeleteItemClick(original) }}><DeleteItemIcon /></Link></Tooltip>}
            />
          )
        },
        isSortable: false
      }
    ]
  }, [locale, canUpdate, canDelete, manualSort, onDeleteItemClick, navigate, isMobileScreen])
  // TODO using old pagination, update with <Next> arrows instead
  const transformedRows = useMemo(() => rows.map((r) => {
    const row = {
      [locale.id]: r.id,
      [locale.name]: r.name,
      [locale.description]: trimNewLineFromString(r.description),
      [locale.email]: r.address?.email ?? '',
      [locale.contact]: r.collection_name ?? '',
      [`${locale.contact}_${locale.id}`]: r.collection_id ?? '',
      [locale.contact_person_object_type]: r.object_type_name ?? '',
      [locale.address]: r.address?.address ?? '',
      [locale.phone]: r.address?.phone ?? ''
    }

    return row
  }), [rows, locale])

  let initialSortBy = []
  if (orderBy) {
    initialSortBy = [{
      id: orderBy,
      desc: order === 'desc'
    }]
  }

  const [renderedColumns, setRenderedColumns] = useState(columns)

  useEffect(() => {
    setIsMobileScreen(windowWidth <= theme.breakpoints.medium)
    if (isMobileScreen) {
      setRenderedColumns([columns[0], columns[columns.length - 1]])
    } else {
      setRenderedColumns(columns)
    }
  }, [windowWidth, isMobileScreen])

  const pagination = (
    <TablePagination
      limit={limit}
      offset={offset}
      onRowsPerPageChange={(e) => {
        setLimit(e.target.value)
        setPageSizeRef.current(e.target.value)
      }}
      onPageChange={(e, number) => setOffset(number * limit)}
    />
  )

  return (
    <PageHolder>
      {deleteItem && <DeleteItem text={deleteItem.name || deleteItem.first_name} onOk={() => { onDeleteOk(deleteItem) }} onCancel={onDeleteCancel} />}
      <PageToolbar
        expanded={expanded} setExpanded={onSetExpanded} center={<SearchField resetSearch={resetSearch} value={search} onChange={setSearch} />} left={
          <>
            <Contact width={20} height={20} />
            <span>{locale.contact_persons}</span>
            {canCreate && <InternalLink to='/contact-persons/new'><Add width={20} height={20} color='#447fb1' /></InternalLink>}
            {!isMobileScreen && (
              <CsvButton
                download={locale.contact_persons}
                keys={[
                  locale.id,
                  locale.name,
                  locale.description,
                  locale.email,
                  locale.contact,
                  `${locale.contact}_${locale.id}`,
                  locale.contact_person_object_type,
                  locale.address,
                  locale.phone
                ]}
                data={transformedRows}
              />
            )}
          </>
      }
      >
        <FilterList />
      </PageToolbar>

      <TableHolder $isExpanded={expanded}>
        <Table columns={renderedColumns} data={rows} initialPageSize={limit} initialSortBy={initialSortBy}>
          {({ setPageSize }) => {
            // Store table callback in a ref so we can call it from elsewhere
            setPageSizeRef.current = setPageSize
          }}
        </Table>
      </TableHolder>
      <PageFooter>{pagination}</PageFooter>
    </PageHolder>
  )
}

const mapStateToProps = (state) => {
  const { locale, contactPerson, window } = state
  const permissions = state.login.permissions
  const {
    pageItems,
    limit,
    offset,
    orderBy,
    order,
    search
  } = contactPerson

  return {
    locale: locale.strings,
    windowWidth: window.windowWidth,
    rows: pageItems,
    limit,
    offset,
    orderBy,
    order,
    search,
    canUpdate: permissions.contactPerson.canUpdate,
    canDelete: permissions.contactPerson.canDelete,
    canCreate: permissions.contactPerson.canCreate,
    deleteItem: state.contactPerson.deleteItem
  }
}

const mapDispatchToProps = (dispatch) => {
  const { actions: { contactPerson } } = redux
  return {
    onShowAddDialog: () => {
      dispatch(contactPerson.showAddContactPersonDialog())
    },

    onEditOk: (c) => {
      dispatch(contactPerson.hideEditContactPersonDialog())
      dispatch(contactPerson.updateContactPerson(c))
    },
    onDeleteItemClick: (c) => {
      dispatch(contactPerson.showDeleteContactPersonDialog(c))
    },
    onDeleteOk: (c) => {
      dispatch(contactPerson.hideDeleteContactPersonDialog())
      dispatch(contactPerson.deleteContactPerson(c))
    },
    onDeleteCancel: () => {
      dispatch(contactPerson.hideDeleteContactPersonDialog())
    },

    getPageItems: () => dispatch(contactPerson.getPageItems()),
    setOrder: (orderBy, order) => dispatch(contactPerson.setOrder(orderBy, order)),
    setLimit: (limit) => dispatch(contactPerson.setLimit(limit)),
    setOffset: (offset) => dispatch(contactPerson.setOffset(offset)),
    setSearch: (search) => dispatch(contactPerson.setSearch(search)),
    resetSearch: () => dispatch(contactPerson.resetSearch())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactPersonPage)
