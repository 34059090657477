import { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  handleAddErrors,
  handleGetErrors,
  handleUpdateErrors,
  handleDeleteErrors
} from '../redux/error/actions'

const REQUEST_STATUS = {
  idle: 'idle',
  pending: 'pending',
  successful: 'successful',
  failed: 'failed'
}

/*
    Example usage:
        import useRequest from '../../hooks/useRequest';
        const [query, data, status] = useRequest(api.getTags, 'tags')
*/

const useRequest = (request, scope) => {
  const dispatch = useDispatch()

  const [status, setStatus] = useState(REQUEST_STATUS.idle)
  const [data, setData] = useState(null)
  const [totalCount, setTotalCount] = useState(0)

  const query = useCallback(async (params = {}) => {
    setStatus(REQUEST_STATUS.pending)
    try {
      const res = await request(params)
      if (res.status === 200) {
        const { value, total_count: count } = res.data
        setData(value)
        setTotalCount(count)
      }
      setStatus(REQUEST_STATUS.successful)
    } catch (e) {
      switch (e.config.method) {
        case 'post':
          handleAddErrors(scope, dispatch)(e)
          break
        case 'get':
          handleGetErrors(scope, dispatch)(e)
          break
        case 'put':
          handleUpdateErrors(scope, dispatch)(e)
          break
        case 'delete':
          handleDeleteErrors(scope, dispatch)(e)
          break
        default:
      }
      setStatus(REQUEST_STATUS.failed)
    } finally {
      setStatus(REQUEST_STATUS.idle)
    }
  }, [dispatch, request, scope, setData, setTotalCount, setStatus])

  useEffect(() => {
    return () => {
      setData(null)
    }
  }, [setData])

  return [query, data, status, totalCount]
}

export default useRequest
