import { busy, notBusy } from '../busy/actions.js'
import {
  handleAddErrors,
  handleGetErrors,
  handleUpdateErrors,
  handleDeleteErrors
} from '../error/actions.js'

import api from '../api.js'
import store from '../store.js'

export const TAG_CREATE_ACTION = 'TAG_CREATE_ACTION'
export const TAG_SHOW_ADD_DIALOG_ACTION = 'TAG_SHOW_ADD_DIALOG_ACTION'
export const TAG_HIDE_ADD_DIALOG_ACTION = 'TAG_HIDE_ADD_DIALOG_ACTION'
export const TAG_GET_OK_ACTION = 'TAG_GET_OK_ACTION'
export const TAG_UPDATE_ACTION = 'TAG_UPDATE_ACTION'
export const TAG_SHOW_EDIT_DIALOG_ACTION = 'TAG_SHOW_EDIT_DIALOG_ACTION'
export const TAG_HIDE_EDIT_DIALOG_ACTION = 'TAG_HIDE_EDIT_DIALOG_ACTION'
export const TAG_DELETE_ACTION = 'TAG_DELETE_ACTION'
export const TAG_SHOW_DELETE_DIALOG_ACTION = 'TAG_SHOW_DELETE_DIALOG_ACTION'
export const TAG_HIDE_DELETE_DIALOG_ACTION = 'TAG_HIDE_DELETE_DIALOG_ACTION'

export const TAG_GET_PAGE_ITEMS_ACTION = 'TAG_GET_PAGE_ITEMS_ACTION'
export const TAG_SET_ORDER_ACTION = 'TAG_SET_ORDER_ACTION'
export const TAG_SET_LIMIT_ACTION = 'TAG_SET_LIMIT_ACTION'
export const TAG_SET_OFFSET_ACTION = 'TAG_SET_OFFSET_ACTION'
export const TAG_SET_SEARCH_ACTION = 'TAG_SET_SEARCH_ACTION'
export const TAG_RESET_SEARCH_ACTION = 'TAG_RESET_SEARCH_ACTION'

export function tagCreateAction (tag) {
  return {
    type: TAG_CREATE_ACTION,
    tag
  }
}

export function tagUpdateAction (tag) {
  return {
    type: TAG_UPDATE_ACTION,
    tag
  }
}

export function tagDeleteAction (tag) {
  return {
    type: TAG_DELETE_ACTION,
    tag
  }
}

/**
 * Add dialog
 */
export function showAddTagDialog () {
  return {
    type: TAG_SHOW_ADD_DIALOG_ACTION
  }
}

export function hideAddTagDialog () {
  return {
    type: TAG_HIDE_ADD_DIALOG_ACTION
  }
}

/**
 * Edit dialog
 */
export function showEditTagDialog (tag) {
  return {
    type: TAG_SHOW_EDIT_DIALOG_ACTION,
    tag
  }
}

export function hideEditTagDialog () {
  return {
    type: TAG_HIDE_EDIT_DIALOG_ACTION
  }
}

/**
 * Delete dialog
 */
export function showDeleteTagDialog (tag) {
  return {
    type: TAG_SHOW_DELETE_DIALOG_ACTION,
    tag
  }
}

export function hideDeleteTagDialog () {
  return {
    type: TAG_HIDE_DELETE_DIALOG_ACTION
  }
}

/**
 * CREATE
 */
export function addTag (tag) {
  return function (dispatch) {
    dispatch(busy())
    api.addTag(tag).then(result => {
      dispatch(notBusy())
    }).catch(handleAddErrors('tag', dispatch))
  }
}

/**
 * READ
 */
export function getTags () {
  return function (dispatch) {
    dispatch(busy())
    api.getTags().then(result => {
      dispatch(notBusy())
      dispatch(receivedTags(result.data.value))
    }).catch(handleGetErrors('tag', dispatch))
  }
}

export function receivedTags (items) {
  return {
    type: TAG_GET_OK_ACTION,
    items
  }
}

/**
 * UPDATE
 */
export function updateTag (tag) {
  return function (dispatch) {
    dispatch(busy())
    api.updateTag(tag).then(() => {
      dispatch(notBusy())
    }).catch(handleUpdateErrors('tag', dispatch))
  }
}

/**
 * DELETE
 */
export function deleteTag (tag) {
  return function (dispatch) {
    dispatch(busy())
    api.deleteTag(tag).then(() => {
      dispatch(notBusy())
    }).catch(handleDeleteErrors('tag', dispatch))
  }
}

/**
 * Get items for a page
 */
export function getPageItems () {
  const state = store.getState()
  const { search, limit, offset, orderBy, order } = state.tag

  const query = []
  if (typeof search === 'string' && search.length > 0) {
    query.push({ key: 'name', value: search, op: '~' })
  }

  const sort = orderBy ? `${orderBy}.${order}` : null

  return function (dispatch) {
    dispatch(busy())
    api.getTags({
      query,
      limit,
      offset,
      sort
    }).then(result => {
      dispatch(notBusy())
      dispatch({
        type: TAG_GET_PAGE_ITEMS_ACTION,
        pageItems: result.data.value
      })
    }).catch(handleGetErrors('tag', dispatch))
  }
}

/**
 * Sort order
 */
export function setOrder (orderBy, order) {
  return {
    type: TAG_SET_ORDER_ACTION,
    orderBy,
    order
  }
}

/**
 * Set limit for pagination
 */
export function setLimit (limit) {
  return {
    type: TAG_SET_LIMIT_ACTION,
    limit
  }
}

/**
 * Set offset for pagination
 */
export function setOffset (offset) {
  return {
    type: TAG_SET_OFFSET_ACTION,
    offset
  }
}

/**
 * Set search
 */
export function setSearch (search) {
  return {
    type: TAG_SET_SEARCH_ACTION,
    search
  }
}

/**
 * Reset search
 */
export function resetSearch () {
  return {
    type: TAG_RESET_SEARCH_ACTION
  }
}
