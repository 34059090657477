import React from 'react'
import { styled } from '../pergas-components/index.js'

const LogoHolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const LogoGraphics = (props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='46.918' height='46.918' viewBox='0 0 46.918 46.918'>
      <defs>
        <filter id='tbfj2hl8qa' x='0' y='9.639' width='37.279' height='37.279' filterUnits='userSpaceOnUse'>
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' result='blur' />
          <feFlood floodOpacity='.161' />
          <feComposite operator='in' in2='blur' />
          <feComposite in='SourceGraphic' />
        </filter>
        <filter id='vvvtu09qjb' x='9.639' y='0' width='37.279' height='37.279' filterUnits='userSpaceOnUse'>
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' result='blur-2' />
          <feFlood floodOpacity='.161' />
          <feComposite operator='in' in2='blur-2' />
          <feComposite in='SourceGraphic' />
        </filter>
        <linearGradient id='8dp6t66tkc' x1='.5' x2='.5' y2='1' gradientUnits='objectBoundingBox'>
          <stop offset='0' stopColor='#28a8ea' />
          <stop offset='1' stopColor='#1667ba' />
        </linearGradient>
      </defs>
      <g data-name='Group 13498'>
        <g style={{ filter: 'url(#tbfj2hl8qa)' }} transform='translate(-.003 -.002)'>
          <path data-name='Rectangle 14788' transform='translate(9 15.64)' style={{ fill: '#073a53' }} d='M0 0h19.279v19.279H0z' />
        </g>
        <g style={{ filter: 'url(#vvvtu09qjb)' }} transform='translate(-.003 -.002)'>
          <path data-name='Path 8730' d='M0 0h19.279v19.279H0z' transform='translate(18.64 6)' style={{ fill: 'url(#8dp6t66tkc)' }} />
        </g>
      </g>
    </svg>
  )
}

const LogoText = (props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='30' height='19' viewBox='0 0 30 19'>
      <text
        transform='translate(15 15)'
        style={{ fill: '#fff', fontSize: '16px', fontFamily: 'Montserrat-Bold,Montserrat', fontWeight: 700, letterSpacing: '-.02em' }}
      >
        <tspan x='-14.984' y='0'>p</tspan>
        <tspan y='0' style={{ fill: '#073a53' }}>ds</tspan>
      </text>
    </svg>
  )
}

const PdsLogo = () => {
  return (
    <LogoHolder>
      <LogoGraphics />
      <LogoText />
    </LogoHolder>
  )
}

export default PdsLogo
