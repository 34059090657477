import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { connect, useSelector } from 'react-redux'
import { Link as RLink, useNavigate } from 'react-router-dom'
import TablePagination from './TablePagination.jsx'
import { Link, styled, Tag, LinkStyle, Waves, spacing } from '@pergas-common/pergas-components'
import { Table, Td } from '../../pergas-components/index.js'
import { Add, Favorite, FavoriteFilled, ArrowDown, ArrowRight } from '@pergas-common/pergas-icons'
import PageToolbar from './PageToolbar.js'
import storage from '../../redux/storage.js'
import redux from '../../redux/index.js'
import SearchField from '../SearchField.js'
import FilterFavoriteButton from '../filter/FilterFavoriteButton'
import DeleteItem from '../dialogs/DeleteItem.js'
import EditDepartment from '../dialogs/EditDepartment'
import { DeleteItemIcon, TemplateManagerIcon, CategoryIcon, DepartmentIcon } from '../icons.js'
import { createDateAccessor, SharePointSite, TeamsToolIcon } from './columns.js'
import { responsiblePerson, sortArray } from '../../util.js'
import { DEFAULT_OBJECT_TYPE_COLOR } from '../style.js'
import Tooltip from '../Tooltip.js'
import { SubRow, SubRowContent } from '../SubRow.js'
import { selectLocale } from '../../redux/locale/selectors.js'
import useRequest from '../../hooks/useRequest.js'
import api from '../../redux/api.js'
import FileView from '../FileView.jsx'
import { makeSelectorByTemplatesEnabled } from '../../redux/login/selectors'
import { makeSelectorDepartment, makeSelectorSelectedItemId } from '../../redux/department/selectors'

const PageHolder = styled.div`
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 100%;
`

const TableHolder = styled.div`
  display: flex;
  overflow-y: scroll;
  flex-grow: 1;
  flex-basis: 50px;
`

const NameHolder = styled.div`
  display: inline-block;
  margin: 0 ${spacing(1)};
`

const TagHolder = styled.span`
  margin-right: ${spacing(1)};
`

const Spacer = styled.div`
  display: inline-block;
  margin-right: 2px;
`

const ExpandedArrows = styled.div`
  display: inline-block;
  padding: ${spacing(1)};
`

const InternalLink = styled(RLink)`
  ${LinkStyle}
`
const Label = styled.div`
  ${({ theme }) => theme.typography.body};
  padding: ${spacing(0.5)} ${spacing(1)};
`

const FavoriteHolder = styled.div`
  margin-top: -2px;
`

const AsyncCategoryDropdown = ({
  row,
  selectedCategory,
  setSelectedCategory,
  setSelectedDepartmentId,
  toggleAllPageRowsSelected,
  getRootFolder,
  resetFileSearch
}) => {
  const { original } = row
  const { id } = original
  const locale = useSelector(selectLocale)
  const [request, data, status] = useRequest(api.getCategories, 'category')
  useEffect(() => {
    request({ query: [{ key: 'collection_id', op: '=', value: original.id }], sort: 'name.asc' })
  }, [])
  const isLoading = status === 'pending'

  if (data === null || data?.length === 0) {
    return (
      <SubRow
        key={id} subRowChildren={[{
          element: (
            <SubRowContent flex={2}>
              <Label>{locale.missing_content_page}</Label>
            </SubRowContent>
          ),
          key: 'empty'
        }]} colSpan={6} isFirst isLast
      />
    )
  }

  if (isLoading) {
    return (
      <SubRow
        key={id} subRowChildren={[{
          element: (
            <SubRowContent flex={2}>
              <Spacer>
                <Spacer>
                  <Spacer>
                    <Waves width={24} height={24} />
                  </Spacer>
                </Spacer>
              </Spacer>
            </SubRowContent>
          ),
          key: 'loading'
        }]} colSpan={6} isFirst isLast
      />
    )
  }

  const onRowClick = (category) => {
    getRootFolder(category)
    setSelectedDepartmentId(0)
    setSelectedCategory(category)
    resetFileSearch('category')
    toggleAllPageRowsSelected(false)
  }

  const renderRows = data?.map((category, index) => {
    const { id, name, person_role: personRole } = category
    const isFirst = index === 0
    const isLast = index === data.length - 1

    const subRowChildren = [{
      element: (
        <SubRowContent flex={1}>
          <CategoryIcon width={18} height={18} />
          <Label><InternalLink to={`/categories/edit?id=${id}`}>{name}</InternalLink></Label>
        </SubRowContent>
      ),
      key: `${id}_name`
    },
    {
      element: (
        <SubRowContent flex={2}>
          {responsiblePerson(personRole)?.person_name}
        </SubRowContent>
      ),
      key: `${id}_responsible`
    }
    ]

    const isSelected = selectedCategory?.id === category.id
    return (
      <SubRow key={id} isSelected={isSelected} onClick={() => onRowClick(category)} subRowChildren={subRowChildren} colSpan={5} isFirst={isFirst} isLast={isLast} />
    )
  })
  return renderRows
}

const DepartmentPage = ({
  locale,
  limit,
  offset,
  orderBy,
  order,
  search,
  filterQueries,
  resetSearch,
  setFilterQueries,
  getPageItems,
  setSelectedDepartmentId,
  setOrder,
  setLimit,
  setOffset,
  setSearch,
  fileSearchState,
  canUpdate,
  canDelete,
  deleteItem,
  onDeleteItemClick,
  onDeleteOk,
  onDeleteCancel,
  editItem,
  onEditItemClick,
  onEditOk,
  onEditCancel,
  canCreate,
  onShowAddDialog,
  onFavoriteItemClick,
  sharePointSaveEnabled,
  getRootFolder,
  resetFileSearch
}) => {
  const navigate = useNavigate()
  const settings = storage.getPageSettings('department')

  const [showFileView, setShowFileView] = useState(settings.showFileView || false)
  function onToggleFileView () {
    storage.putPageSetting('department', { showFileView: !showFileView })
    setShowFileView(!showFileView)
  }

  function onSetFileViewHeight (height) {
    storage.putPageSetting('department', { fileViewHeight: height })
  }

  const selectItems = useMemo(makeSelectorDepartment, [])
  const items = useSelector((state) => selectItems(state))

  const selectSelectedDepartmentId = useMemo(makeSelectorSelectedItemId, [])
  const selectedDepartmentId = useSelector((state) => selectSelectedDepartmentId(state))

  const selectorByTemplatesEnabled = useMemo(makeSelectorByTemplatesEnabled, [])
  const templatesEnabled = useSelector((state) => selectorByTemplatesEnabled(state))

  useEffect(() => {
    getPageItems()
  }, [getPageItems, limit, offset, orderBy, order, search, filterQueries])

  const [selectedCategory, setSelectedCategory] = useState(null)
  const renderRowSubComponent = useCallback(
    ({ row, toggleAllPageRowsSelected }) => (
      <AsyncCategoryDropdown
        row={row}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        setSelectedDepartmentId={setSelectedDepartmentId}
        toggleAllPageRowsSelected={toggleAllPageRowsSelected}
        getRootFolder={getRootFolder}
        resetFileSearch={resetFileSearch}
      />
    ),
    [selectedCategory, setSelectedCategory, setSelectedDepartmentId, getRootFolder, resetFileSearch]
  )

  const setPageSizeRef = useRef(() => {})

  const manualSort = useCallback(({ id, isSorted, isSortedDesc }) => {
    if (isSorted && !isSortedDesc) {
      setOrder(id, 'desc')
    } else if (isSorted && isSortedDesc) {
      setOrder('', '')
    } else {
      setOrder(id, 'asc')
    }
  }, [setOrder])

  const selectedRowIds = useMemo(() => {
    const index = items.findIndex(item => item.id === selectedDepartmentId)
    if (index !== -1) {
      return { [String(index)]: true }
    } else {
      return {}
    }
  }, [items, selectedDepartmentId])

  const onExpandArrow = (row) => {
    return (e) => {
      e.stopPropagation()
      row.toggleRowExpanded()
    }
  }

  const columns = useMemo(() => {
    return [
      {
        Header: locale.name,
        Cell: ({ cell }) => {
          const { row } = cell
          const { isExpanded, original } = row
          const Arrow = isExpanded ? ArrowDown : ArrowRight
          return (
            <Td
              {...cell.getCellProps()} left={
                <>
                  <ExpandedArrows>
                    <Arrow width={12} height={12} style={{ cursor: 'pointer' }} onClick={onExpandArrow(row)} />
                  </ExpandedArrows>
                  <DepartmentIcon style={{ width: 16, height: 16 }} />
                  <NameHolder>
                    {!!original.name && (canUpdate ? <InternalLink to={`/departments/edit?id=${original.id}`}>{original.name}</InternalLink> : <span>{original.name}</span>)}
                  </NameHolder>

                </>
            }
            />
          )
        },
        canSort: true,
        id: 'name',
        size: 'md',
        manualSort,
        sortType: () => {}
      },
      {
        id: 'object_type_name',
        Header: locale.department_object_type,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return <Td {...cell.getCellProps()} center={original.object_type_name && <Tag color={original.object_type_color || DEFAULT_OBJECT_TYPE_COLOR} textColor={original.object_type_color ? '#FFFFFF' : '#3a4a54'} border='#969696'>{original.object_type_name}</Tag>} />
        },
        size: 'sm',
        canSort: true,
        sortType: () => {},
        manualSort
      },
      {
        id: 'tags_string',
        Header: locale.tags,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          const tags = original.tags && original.tags.length > 0 && sortArray(original.tags, 'name').slice(0, 2).map(({ color, id, name }) => {
            return <TagHolder key={id}><Tag textColor='#FFFFFF' color={color}>{name}</Tag></TagHolder>
          })
          return (<Td {...cell.getCellProps()} left={tags && tags} />)
        },
        canSort: true,
        sortType: () => {},
        manualSort
      },
      {
        id: 'updated_at',
        Header: locale.updated_at,
        manualSort,
        accessor: createDateAccessor('updated_at'),
        sortType: () => {}
      },
      {
        id: 'toolbar',
        Header: locale.tool_belt,
        Cell: ({ cell }) => {
          const { row: { original } } = cell
          return (
            <Td
              {...cell.getCellProps()}
              left={
                <>
                  {sharePointSaveEnabled && <Spacer>{SharePointSite(original)}</Spacer>}
                  {sharePointSaveEnabled && <TeamsToolIcon row={original} />}
                  {templatesEnabled && <Tooltip content={locale.template_manager}><Link href={`${process.env.REACT_APP_TM_URL}?pds_id=${original.id}&pds_type=department`}> <TemplateManagerIcon width={18} height={18} /></Link></Tooltip>}
                  <Tooltip content={locale.favorite}><Spacer><Link onClickHandler={() => { onFavoriteItemClick(original) }}><FavoriteHolder>{original.is_favorite ? <FavoriteFilled color='#ca6eb6' width={18} height={18} /> : <Favorite color='#ca6eb6' width={18} height={18} />}</FavoriteHolder></Link></Spacer></Tooltip>
                </>
              }
              right={canDelete && <Tooltip side='left' content={locale.delete}><Link onClickHandler={() => { onDeleteItemClick(original) }}><DeleteItemIcon /></Link></Tooltip>}
            />
          )
        },
        size: 'sm',
        isSortable: false
      }
    ]
  }, [locale, canUpdate, canDelete, sharePointSaveEnabled, manualSort, onDeleteItemClick, onEditItemClick, onFavoriteItemClick])

  let initialSortBy = []
  if (orderBy) {
    initialSortBy = [{
      id: orderBy,
      desc: order === 'desc'
    }]
  }

  let selectedSiteItem = items.find(i => i.id === selectedDepartmentId)
  let searchState

  if (selectedSiteItem) {
    searchState = fileSearchState.department
  } else {
    selectedSiteItem = selectedCategory
    searchState = fileSearchState.category
  }

  const pagination = (
    <TablePagination
      limit={limit}
      offset={offset}
      onRowsPerPageChange={(e) => {
        setLimit(e.target.value)
        setPageSizeRef.current(e.target.value)
      }}
      onPageChange={(e, number) => setOffset(number * limit)}
    />
  )

  return (
    <PageHolder>
      {editItem && <EditDepartment isEditing item={editItem} onOk={onEditOk} onCancel={onEditCancel} />}
      {deleteItem && <DeleteItem text={deleteItem.name} onOk={() => { onDeleteOk(deleteItem) }} onCancel={onDeleteCancel} />}
      <PageToolbar
        left={
          <>
            <DepartmentIcon style={{ width: 20, height: 20 }} />
            <span>{locale.departments}</span>
            {canCreate && (
              <Link onClickHandler={() => {
                navigate('/departments/new')
              }}
              >
                <Add width={20} height={20} color='#447fb1' />
              </Link>
            )}
            <FilterFavoriteButton filterQueries={filterQueries} onFilterChange={setFilterQueries} />
          </>
        }
        center={<SearchField resetSearch={resetSearch} onChange={setSearch} value={search} />}
      />
      <TableHolder>
        <Table
          columns={columns}
          data={items}
          initialPageSize={limit}
          initialSortBy={initialSortBy}
          renderRowSubComponent={renderRowSubComponent}
          selectedRowIds={selectedRowIds}
          onRowClick={(e, row, toggleAllPageRowsSelected) => {
            getRootFolder(row.original)
            setSelectedCategory(null)
            setSelectedDepartmentId(row.original.id)
            toggleAllPageRowsSelected(false)
            row.toggleRowSelected(true)
          }}
        >
          {({ setPageSize }) => {
            // Store table callback in a ref so we can call it from elsewhere
            setPageSizeRef.current = setPageSize
          }}
        </Table>
      </TableHolder>
      <FileView siteItem={selectedSiteItem} right={pagination} onShowToggle={onToggleFileView} isOpen={showFileView} initialHeight={settings.fileViewHeight} onSetHeight={onSetFileViewHeight} searchState={searchState} />
    </PageHolder>
  )
}

const mapStateToProps = (state) => {
  const { locale, department, files } = state
  const permissions = state.login.permissions
  const {
    limit,
    offset,
    orderBy,
    order,
    search,
    filterQueries
  } = department
  return {
    locale: locale.strings,
    limit,
    offset,
    orderBy,
    order,
    search,
    filterQueries,
    canUpdate: permissions.department.canUpdate,
    canDelete: permissions.department.canDelete,
    canCreate: permissions.department.canCreate,
    deleteItem: state.department.deleteItem,
    editItem: state.department.editItem,
    sharePointSaveEnabled: state.login.userData.sharePointSaveEnabled,
    fileSearchState: files.search
  }
}

const mapDispatchToProps = (dispatch) => {
  const { actions: { department, file } } = redux
  return {
    onShowAddDialog: () => {
      dispatch(department.showAddDepartmentDialog())
    },
    onEditOk: (d) => {
      dispatch(department.hideEditDepartmentDialog())
      dispatch(department.updateDepartment(d))
    },
    onEditItemClick: (d) => {
      dispatch(department.showEditDepartmentDialog(d))
    },
    onEditCancel: () => {
      dispatch(department.hideEditDepartmentDialog())
    },
    onFavoriteItemClick: (d) => {
      dispatch(department.toggleFavorite(d))
    },
    onDeleteItemClick: (d) => {
      dispatch(department.showDeleteDepartmentDialog(d))
    },
    onDeleteOk: (d) => {
      dispatch(department.hideDeleteDepartmentDialog())
      dispatch(department.deleteDepartment(d))
    },
    onDeleteCancel: () => {
      dispatch(department.hideDeleteDepartmentDialog())
    },
    getPageItems: () => {
      dispatch(department.getPageItems())
    },
    setSelectedDepartmentId: (id) => {
      dispatch(department.setSelectedItemId(id))
    },
    setOrder: (orderBy, order) => {
      dispatch(department.setOrder(orderBy, order))
    },
    setLimit: (limit) => {
      dispatch(department.setLimit(limit))
    },
    setOffset: (offset) => {
      dispatch(department.setOffset(offset))
    },
    setSearch: (search) => {
      dispatch(department.setSearch(search))
    },
    resetSearch: () => {
      dispatch(department.resetSearch())
    },
    setFilterQueries: (filterQueries) => {
      dispatch(department.setFilterQueries(filterQueries))
    },
    getRootFolder: (item) => {
      dispatch(file.getRootFolder(item))
    },
    resetFileSearch: (type) => {
      dispatch(file.resetFileSearch(type))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentPage)
