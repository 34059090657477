import React from 'react'
import { Link, spacing, styled, Topbar } from '@pergas-common/pergas-components'
import { Filter } from '@pergas-common/pergas-icons'

const LeftHolder = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  > span {
    padding: ${spacing(0.2)};
    text-transform: capitalize;
    line-height: 20px;
    ${({ theme }) => theme.typography.subTitle};
    overflow: hidden;  
    vertical-align: middle;
  }
  > a {
    vertical-align: middle;
  }
  > button {
    padding: ${spacing(0.2)};
  }
    
  > svg {
    padding: ${spacing(0.2)};
  }
`

const FilterIcon = styled(Filter)`
  transform: rotate(${({ $isExpanded }) => $isExpanded ? '0deg' : '270deg'});
`
const PageToolbar = ({ expanded, setExpanded, left, right, center, children }) => {
  return (
    <div>
      <Topbar
        left={
          <LeftHolder>{left && left}
            {setExpanded && (
              <Link onClickHandler={() => {
                setExpanded((prev) => !prev)
              }}
              >
                <FilterIcon $isExpanded={expanded} width={20} height={20} color='#447fb1' />
              </Link>)}
          </LeftHolder>
            } center={center || children} right={right && right}
      />
      {expanded && children && children}
    </div>
  )
}

export default PageToolbar
