import store from './store.js'
import API from './api.js'

// admin
import * as account from './account/actions.js'
import * as permission from './permission/actions.js'
import * as tenant from './tenant/actions.js'

// user
import * as login from './login/actions.js'
import * as category from './category/actions.js'
import * as contact from './contact/actions.js'
import * as contactPerson from './contact-person/actions.js'
import * as contentType from './content-type/actions.js'
import * as department from './department/actions.js'
import * as employee from './employee/actions.js'
import * as error from './error/actions.js'
import * as file from './files/actions.js'
import * as locale from './locale/actions.js'
import * as objectType from './object-type/actions.js'
import * as project from './project/actions.js'
import * as role from './role/actions.js'
import * as tag from './tag/actions.js'
import * as util from './util/actions.js'
import * as ticket from './ticket/actions.js'
import * as window from './window/actions.js'

const redux = {
  store,
  actions: {
    account,
    category,
    contact,
    contactPerson,
    contentType,
    department,
    employee,
    error,
    file,
    locale,
    login,
    objectType,
    project,
    permission,
    role,
    tag,
    tenant,
    ticket,
    util,
    window
  }
}

export const api = API
export default redux
