import { busy, notBusy } from '../busy/actions.js'
import { handleAddErrors, handleGetErrors, handleUpdateErrors, handleDeleteErrors } from '../error/actions.js'
import { resetFileSearch } from '../files/actions.js'

import api from '../api.js'
import store from '../store.js'
import storage from '../storage.js'

export const CATEGORY_CREATE_ACTION = 'CATEGORY_CREATE_ACTION'
export const CATEGORY_SHOW_ADD_DIALOG_ACTION = 'CATEGORY_SHOW_ADD_DIALOG_ACTION'
export const CATEGORY_HIDE_ADD_DIALOG_ACTION = 'CATEGORY_HIDE_ADD_DIALOG_ACTION'
export const CATEGORY_GET_OK_ACTION = 'CATEGORY_GET_OK_ACTION'
export const CATEGORY_UPDATE_ACTION = 'CATEGORY_UPDATE_ACTION'
export const CATEGORY_SHOW_EDIT_DIALOG_ACTION = 'CATEGORY_SHOW_EDIT_DIALOG_ACTION'
export const CATEGORY_HIDE_EDIT_DIALOG_ACTION = 'CATEGORY_HIDE_EDIT_DIALOG_ACTION'
export const CATEGORY_DELETE_ACTION = 'CATEGORY_DELETE_ACTION'
export const CATEGORY_SHOW_DELETE_DIALOG_ACTION = 'CATEGORY_SHOW_DELETE_DIALOG_ACTION'
export const CATEGORY_HIDE_DELETE_DIALOG_ACTION = 'CATEGORY_HIDE_DELETE_DIALOG_ACTION'

export const CATEGORY_GET_PAGE_ITEMS_ACTION = 'CATEGORY_GET_PAGE_ITEMS_ACTION'
export const CATEGORY_SET_ORDER_ACTION = 'CATEGORY_SET_ORDER_ACTION'
export const CATEGORY_SET_LIMIT_ACTION = 'CATEGORY_SET_LIMIT_ACTION'
export const CATEGORY_SET_OFFSET_ACTION = 'CATEGORY_SET_OFFSET_ACTION'
export const CATEGORY_SET_SEARCH_ACTION = 'CATEGORY_SET_SEARCH_ACTION'
export const CATEGORY_RESET_SEARCH_ACTION = 'CATEGORY_RESET_SEARCH_ACTION'
export const CATEGORY_SET_FILTER_QUERIES_ACTION = 'CATEGORY_SET_FILTER_QUERIES_ACTION'
export const CATEGORY_SET_SELECTED_ITEM_ID = 'CATEGORY_SET_SELECTED_ITEM_ID'

export function categoryCreateAction (category) {
  return {
    type: CATEGORY_CREATE_ACTION,
    category
  }
}

export function categoryUpdateAction (category) {
  return {
    type: CATEGORY_UPDATE_ACTION,
    category
  }
}

export function categoryDeleteAction (category) {
  return {
    type: CATEGORY_DELETE_ACTION,
    category
  }
}

export function setSelectedItemId (selectedItemId) {
  return function (dispatch) {
    dispatch({
      type: CATEGORY_SET_SELECTED_ITEM_ID,
      selectedItemId
    })
    dispatch(resetFileSearch('category'))
  }
}

/**
 * Add dialog
 */
export function showAddCategoryDialog () {
  return {
    type: CATEGORY_SHOW_ADD_DIALOG_ACTION
  }
}

export function hideAddCategoryDialog () {
  return {
    type: CATEGORY_HIDE_ADD_DIALOG_ACTION
  }
}

/**
 * Edit dialog
 */
export function showEditCategoryDialog (category) {
  return {
    type: CATEGORY_SHOW_EDIT_DIALOG_ACTION,
    category
  }
}

export function hideEditCategoryDialog () {
  return {
    type: CATEGORY_HIDE_EDIT_DIALOG_ACTION
  }
}

/**
 * Delete dialog
 */
export function showDeleteCategoryDialog (category) {
  return {
    type: CATEGORY_SHOW_DELETE_DIALOG_ACTION,
    category
  }
}

export function hideDeleteCategoryDialog () {
  return {
    type: CATEGORY_HIDE_DELETE_DIALOG_ACTION
  }
}

/**
 * CREATE
 */
export function addCategory (category) {
  return function (dispatch) {
    dispatch(busy())
    api.addCategory(category).then(() => {
      dispatch(notBusy())
    }).catch(handleAddErrors('category', dispatch))
  }
}

/**
 * READ
 */
export function getCategories (params = {}) {
  return function (dispatch) {
    dispatch(busy())
    api.getCategories(params).then(result => {
      dispatch(notBusy())
      dispatch(receivedCategories(result.data.value))
    }).catch(handleGetErrors('category', dispatch))
  }
}

export function receivedCategories (items) {
  return {
    type: CATEGORY_GET_OK_ACTION,
    items
  }
}

/**
 * UPDATE
 */
export function updateCategory (category) {
  return function (dispatch) {
    dispatch(busy())
    api.updateCategory(category).then(() => {
      dispatch(notBusy())
    }).catch(handleUpdateErrors('category', dispatch))
  }
}

/**
 * DELETE
 */
export function deleteCategory (category) {
  return function (dispatch) {
    dispatch(busy())
    api.deleteCategory(category).then(() => {
      dispatch(notBusy())
    }).catch(handleDeleteErrors('category', dispatch))
  }
}

export function toggleFavorite (category) {
  return function (dispatch) {
    const favorite = !category.is_favorite
    dispatch(busy())
    api.setCategoryFavorite(category.id, favorite).then(() => {
      dispatch(notBusy())
    }).catch(handleUpdateErrors('category', dispatch))
  }
}

/**
 * Set filter queries
 */
export function setFilterQueries (filterQueries) {
  storage.putPageSetting('category', { filterQueries })
  return {
    type: CATEGORY_SET_FILTER_QUERIES_ACTION,
    filterQueries
  }
}
/**
 * Get items for a page
 */
export function getPageItems () {
  const state = store.getState()
  const { search, limit, offset, orderBy, order, filterQueries } = state.category

  let query = []
  if (typeof search === 'string' && search.length > 0) {
    const split = search.split(',').map(s => s.trim())
    if (split.length === 1) {
      if (split[0].length > 0) query.push({ key: 'name', value: split[0], op: '~' })
    } else if (split.length === 2) {
      if (split[0].length > 0) query.push({ key: 'name', value: split[0], op: '~' })
      if (split[1].length > 0) query.push({ key: 'collection_name', value: split[1], op: '~' })
    }
  }

  const sort = orderBy ? `${orderBy}.${order}` : null
  if (Array.isArray(filterQueries) && filterQueries.length > 0) {
    query = [...query, ...filterQueries]
  }

  return function (dispatch) {
    getCategories({
      query,
      limit,
      offset,
      sort
    })(dispatch)
  }
}

/**
 * Sort order
 */
export function setOrder (orderBy, order) {
  return {
    type: CATEGORY_SET_ORDER_ACTION,
    orderBy,
    order
  }
}

/**
 * Set limit for pagination
 */
export function setLimit (limit) {
  return {
    type: CATEGORY_SET_LIMIT_ACTION,
    limit
  }
}

/**
 * Set offset for pagination
 */
export function setOffset (offset) {
  return {
    type: CATEGORY_SET_OFFSET_ACTION,
    offset
  }
}

/**
 * Set search
 */
export function setSearch (search) {
  return {
    type: CATEGORY_SET_SEARCH_ACTION,
    search
  }
}

/**
 * Reset search
 */
export function resetSearch () {
  return {
    type: CATEGORY_RESET_SEARCH_ACTION
  }
}
